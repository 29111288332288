
const PageTitle = ({ title }) => {
    return (
        <div className="page-title-header">
            <h1 className="page-title">{title}</h1>
            <span className="page-title-border"></span>
        </div>
    )
}

export default PageTitle
import React, { Component } from "react";
import "./Home.scss";

import * as moment from "moment";
import Header from "../../Header/Header";
import instrutorHome from "../../../assets/images/instrutor/home-image.png";
import { ReactComponent as IconTurmas } from "../../../assets/images/instrutor/home-bt-turmas.svg";
import { ReactComponent as IconAgendamentos } from "../../../assets/images/instrutor/icon-calendar.svg";
import { ReactComponent as IconTreinamentos } from "../../../assets/images/instrutor/icon-training.svg";
import { ReactComponent as IconMaterial } from "../../../assets/images/instrutor/icon-material.svg";

import Loading from "../../Loading/Loading";

import { toast } from "react-toastify";

import ClassService from "../../../services/class.service";
import InstructorService from "../../../services/instructor.service";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthConsumer, ContextAuth } from "../../../context/authContext";
import { navigate } from "../../../services/navigation.service";
import LicenseWarn from "./licenseWarn/LicenseWarn";
const MySwal = withReactContent(Swal);

export default class Home extends Component {
  static contextType = ContextAuth;

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      nextClass: { name: "", date: "" },
      isLoading: true,
      notification: true,
      country: "Brasil",
      classes: "",
    };
  }

  mailNotification() {
    const instrutor = this.context.state.userData;
    const email = instrutor.email;
    const notificar = InstructorService.notify(email);
  }

  componentWillMount = async () => {
    const instrutor = this.context.state.userData;

    if (instrutor._id) {
      try {
        const inst = await InstructorService.getById(instrutor._id);
        if (inst && !inst.active) {
          MySwal.fire({
            allowOutsideClick: false,
            confirmButtonColor: "#87BF40",
            confirmButtonText: `OK`,
            icon: "error",
            title: `<p>${this.props.t("instructorHome.blockedAccess")}</p>`,
          }).then(function () {
            const navigator = navigate.bind(this);

            navigator({ link: "/" });
          });
        }
      } catch (error) {
        if (
          error.response.statusCode === 403 ||
          error.response.statusCode === 401
        ) {
          const navigator = navigate.bind(this);

          navigator({ link: "/" });
        }
      }

      const classes = await ClassService.classesByIntructor(instrutor._id);
      const finishedClasses = await ClassService.classCompletedByInstructor(
        instrutor._id
      );

      let finishedIds = [];
      let classesStarted = [];

      // Excluir turmas que já foram concluídas
      finishedIds = finishedClasses.map((f) => {
        return f._id;
      });

      classes.forEach((c) => {
        if (!finishedIds.includes(c._id)) {
          classesStarted.push(c);
        }
      });

      let dates = [];
      if (classesStarted.length > 0) {
        for (let c of classesStarted) {
          let classes = await ClassService.getById(c._id);
          let workshops = classes.workshops;
          for (let w of workshops) {
            if (moment(w.dateTime) > moment(new Date())) {
              dates.push({
                name: c.name,
                date: moment(w.dateTime),
              });
            }
          }
        }
      }

      let nextClass;
      if (dates.length > 0) {
        nextClass = dates[0];
        for (let i = 0; i < dates.length; i++) {
          if (dates[i].date < nextClass.date) {
            nextClass = dates[i];
          }
        }
      }

      await this.setState({
        name: instrutor.name.split(" ")[0],
        nextClass: nextClass,
        isLoading: false,
      });

      this.alertClassesWithoutPowersOrMissions(instrutor._id);
      this.mailNotification();
    }
  };

  alertClassesWithoutPowersOrMissions = async (instructorId) => {
    const existClasses = await ClassService.getClassesWithoutPowersOrMissions(
      instructorId
    );
    if (existClasses) {
      const notify = () =>
        toast.warn(
          this.state.country === "Brasil"
            ? this.props.t("instructorHome.notificationSideBar")
            : this.props.t("instructorHome.notificationSideBar"),
          {
            position: "top-right",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      notify();
    }
  };

  mailNotification = async () => {
    const instrutor = this.context.state.userData;
    const email = instrutor.email;
  };

  render() {
    return (
      <Loading fade={this.state.isLoading}>
        <div className="home-instrutor">
          <Header />
          <main className="home-instrutor-main">
            <LicenseWarn />
            <div className="home-instrutor-header">
              <h1 className="home-instrutor-welcome">
                {this.props.t("instructorHome.hello")},
                <span
                  className="instrutor-name"
                  onClick={this.mailNotification}
                >
                  <AuthConsumer>
                    {(props) => props.state.userData.name.split(" ")[0]}
                  </AuthConsumer>
                </span>
              </h1>

              <div className="home-instrutor-avisos">
                <h3 className="instrutor-conquista-title">
                  {this.props.t("instructorHome.warnings")}
                </h3>
                <div className="instrutor-quadro">
                  <h5 className="instrutor-titles">
                    {this.props.t("instructorHome.nextClass")}:
                  </h5>
                  <span>
                    {this.state.nextClass ? this.state.nextClass.name : ""}
                  </span>
                  <span>
                    {this.state.nextClass
                      ? moment(this.state.nextClass.date)
                          .locale(
                            `${this.props.t("instructorHome.dateFormat")}`
                          )
                          .format("L")
                      : ""}
                  </span>
                </div>
              </div>
            </div>

            <div className="home-instrutor-content">
              <img src={instrutorHome} alt="" />

              <a
                className="home-instrutor-button turmas"
                onClick={() => {
                  const navigator = navigate.bind(this);

                  navigator({ link: "/turmas-list" });
                }}
              >
                <IconTurmas />
                <span></span>
                {this.props.t("instructorHome.btnClass")}
              </a>

              <a
                onClick={() => {
                  const navigator = navigate.bind(this);
                  navigator({ link: "/agendamentos-instrutor" });
                }}
                className="home-instrutor-button agendamentos"
              >
                <IconAgendamentos />
                <span></span>
                {this.props.t("instructorHome.btnCalendar")}
              </a>

              <a
                onClick={() => {
                  const navigator = navigate.bind(this);
                  navigator({ link: "/treinamento-instrutor" });
                }}
                className="home-instrutor-button treinamento"
              >
                <IconTreinamentos />
                <span></span>
                {this.props.t("instructorHome.btnTraining")}
              </a>

              <a
                href="http://www.fazendoacontecer.org.br/fapoderes/"
                className="home-instrutor-button material"
                target="_blank"
                rel="noreferrer"
              >
                <IconMaterial />
                <span></span>
                {this.props.t("instructorHome.btnSupplies")}
              </a>
            </div>
          </main>
        </div>
      </Loading>
    );
  }
}

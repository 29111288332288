import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import SecurityService from "../services/security.service";

import { translate } from "react-multi-lang";

import Login from "./Login/Login";

import {
  HomeParticipante,
  LoginParticipante,
  OficinaParticipante,
  ListEstrelas,
  GameTypes,
  GameList,
  Game,
  Ranking,
  Poderes,
  Avatar,
  Hair,
  Skin,
  About,
  Confirm,
  MissionsMenuPage,
  PendingMissions,
  CompletedMissions
} from './Participante'

import {
  LoginInstrutor,
  ForgotPassword,
  RecoverPassword,
  Dashboard,
  CadastroInstrutor,
  ListTreinamento,
  QuizInstrutor,
  ConteudoTreinamento,
  CertificadoTreinamento,
  OficinaInstrutor,
  EnvioMissoes,
  ListTurma,
  FinishedClasses,
  CreateTurma,
  EditTurma,
  HomeInstrutor,
  Agendamentos,
  PresencaTurma,
  AtribuirPoderes,
  AtribuirMissoes,
  AtribuirEstrelas,
  ConsultTurma,
  CertificadoParticipante,
  InstructorRanking
} from "./Instrutor";


// Common
import Welcome from "./Welcome/Welcome";

import { store } from "../store";
import { push } from "react-router-redux";
import { ContextAuth } from "../context/authContext";
import MissionUploadPage from "./Participante/MissionUploadPage/MissionUploadPage";
import UploadPresenceList from "./Instrutor/Turma/List/UploadPresenceList/UploadPresenceList";

class App extends Component {
  static contextType = ContextAuth

  componentDidUpdate(nextProps) {

    if (nextProps.redirectTo) {
      store.dispatch(push(nextProps.redirectTo));
      this.props.onRedirect();
    }
  }

  guestRoutes() {
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <Login {...props} t={this.props.t} />}
        />

        <Route
          path="/login-participante"
          render={(props) => <LoginParticipante {...props} t={this.props.t} />}
        />

        <Route
          path="/login-instrutor"
          render={(props) => <LoginInstrutor {...props} t={this.props.t} />}
        />

        <Route
          path="/cadastro-instrutor"
          component={(props) => (
            <CadastroInstrutor {...props} t={this.props.t} />
          )}
        />

        <Route
          path="/forgot-password"
          render={(props) => <ForgotPassword {...props} t={this.props.t} />}
        />

        <Redirect to="/" />
      </Switch>
    );
  }

  firstAccessRoutes() {
    return (
      <Switch>
        <Route
          path="/welcome"
          component={(props) => <Welcome {...props} t={this.props.t} />}
        />

        {SecurityService.isInstructor(this.context)
          ? [
              <Route
                path="/recover-password"
                component={(props) => (
                  <RecoverPassword {...props} t={this.props.t} />
                )}
              />,
            ]
          : []}

        <Redirect to="/welcome" />
      </Switch>
    );
  }

  participantRoutes() {
    return (
      <Switch>
        <Route
          path="/home-participante"
          component={(props) => (
            <HomeParticipante {...props} t={this.props.t} />
          )}
        />

        <Route
          path="/participant-avatar"
          component={(props) => <Avatar {...props} t={this.props.t} />}
        />

        <Route
          path="/participant-hair"
          component={(props) => <Hair {...props} t={this.props.t} />}
        />

        <Route
          path="/participant-skin"
          component={(props) => <Skin {...props} t={this.props.t} />}
        />

        <Route
          path="/participant-about"
          component={(props) => <About {...props} t={this.props.t} />}
        />

        <Route
          path="/participant-confirm"
          component={(props) => <Confirm {...props} t={this.props.t} />}
        />

        <Route
          path="/oficina-participante"
          component={(props) => (
            <OficinaParticipante {...props} t={this.props.t} />
          )}
        />

        <Route
          path="/missoes-menu"
          component={(props) => <MissionsMenuPage {...props} />}
        />

        <Route
          path="/missoes-concluidas"
          component={(props) => <CompletedMissions {...props} />}
        />
        
        <Route
          path="/missoes-pendentes"
          component={(props) => <PendingMissions {...props} />}
        />

        <Route
          path="/participant/:id/mission/:missionId"
          component={(props) => <MissionUploadPage {...props} />}
        />

        <Route
          path="/list-estrelas"
          component={(props) => <ListEstrelas {...props} t={this.props.t} />}
        />

        <Route
          path="/game-types"
          component={(props) => <GameTypes {...props} t={this.props.t} />}
        />

        <Route
          path="/game-list"
          component={(props) => <GameList {...props} t={this.props.t} />}
        />

        <Route
          path="/game/:id"
          component={(props) => <Game {...props} t={this.props.t} />}
        />

        <Route
          path="/ranking"
          component={(props) => <Ranking {...props} t={this.props.t} />}
        />

        <Route
          path="/poderes"
          component={(props) => <Poderes {...props} t={this.props.t} />}
        />

        <Redirect to="/home-participante" />
      </Switch>
    );
  }

  instructorTrainingRoutes() {
    return (
      <Switch>
        <Route
          path="/treinamento-instrutor"
          component={(props) => <ListTreinamento {...props} t={this.props.t} />}
        />

        <Route
          path="/conteudo-instrutor/:id"
          component={(props) => (
            <ConteudoTreinamento {...props} t={this.props.t} />
          )}
        />

        <Route
          path="/quiz-instrutor/:id"
          component={(props) => <QuizInstrutor {...props} t={this.props.t} />}
        />

        <Route
          path="/certificado-instrutor"
          component={(props) => (
            <CertificadoTreinamento {...props} t={this.props.t} />
          )}
        />

        <Route
          path="/recover-password"
          component={(props) => <RecoverPassword {...props} t={this.props.t} />}
        />

        <Redirect to="/treinamento-instrutor" />
      </Switch>
    );
  }

  instructorRoutes() {

    return (
      <Switch>
        <Route
          path="/treinamento-instrutor"
          component={(props) => <ListTreinamento {...props} t={this.props.t} />}
        />

        <Route
          path="/conteudo-instrutor/:id"
          component={(props) => (
            <ConteudoTreinamento {...props} t={this.props.t} />
          )}
        />

        <Route
          path="/quiz-instrutor/:id"
          component={(props) => <QuizInstrutor {...props} t={this.props.t} />}
        />

        <Route
          path="/certificado-instrutor"
          component={(props) => (
            <CertificadoTreinamento {...props} t={this.props.t} />
          )}
        />

        <Route
          path="/recover-password"
          component={(props) => <RecoverPassword {...props} t={this.props.t} />}
        />

        <Route
          path="/home-instrutor"
          component={(props) => <HomeInstrutor {...props} t={this.props.t} />}
        />

        <Route
          path="/dashboard"
          component={(props) => <Dashboard {...props} t={this.props.t} />}
        />

        <Route
          path="/oficina-instrutor/:code"
          component={(props) => (
            <OficinaInstrutor {...props} t={this.props.t} />
          )}
        />

        <Route
          path="/envio-missoes/:code"
          component={(props) => <EnvioMissoes {...props} t={this.props.t} />}
        />

        <Route
          path="/participant/:id/mission/:missionId"
          component={(props) => <MissionUploadPage {...props} />}
        />

        <Route
          path="/atribuicao-poderes/:code/:workshop"
          component={(props) => <AtribuirPoderes {...props} t={this.props.t} />}
        />

        <Route
          path="/atribuicao-missoes/:code/:workshop"
          component={(props) => <AtribuirMissoes {...props} t={this.props.t} />}
        />

        <Route
          path="/atribuicao-estrelas/:code/:workshop"
          component={(props) => (
            <AtribuirEstrelas {...props} t={this.props.t} />
          )}
        />

        <Route
          path="/agendamentos-instrutor"
          component={(props) => <Agendamentos {...props} t={this.props.t} />}
        />

        <Route
          path="/turmas-list"
          component={(props) => <ListTurma {...props} t={this.props.t} />}
        />

        <Route
          path="/ranking/class/:code"
          component={(props) => <InstructorRanking {...props} t={this.props.t} />}
        />

        <Route
          path="/turmas-finished"
          component={(props) => <FinishedClasses {...props} t={this.props.t} />}
        />

        <Route
          path="/turmas-certified"
          component={(props) => (
            <CertificadoParticipante {...props} t={this.props.t} />
          )}
        />

        <Route
          path="/turmas-create"
          component={(props) => <CreateTurma {...props} t={this.props.t} />}
        />

        <Route
          path="/submit-presence-list/:code"
          component={(props) => <UploadPresenceList {...props}/>}
        />

        <Route
          path="/edit-turma/:code"
          component={(props) => <EditTurma {...props} t={this.props.t} />}
        />

        <Route
          path="/turmas-presenca/:code/:workshop"
          component={(props) => <PresencaTurma {...props} t={this.props.t} />}
        />

        <Route
          path="/turmas-consult/:code"
          component={(props) => <ConsultTurma {...props} t={this.props.t} />}
        />

        <Redirect to="/home-instrutor" />
      </Switch>
    );
  }

  getRoutes() {

    const state = this.context.state;

    if (!state.token || !state.userData) return this.guestRoutes();

    const user = state.userData

    if (user.firstAcess) return this.firstAccessRoutes();

    // TODO: Modificar security service pra buscar a role do context
    if (state.role == 'participant') return this.participantRoutes();

    if (state.role == 'instructor') {
      return user.trainingCompletedAt
        ? this.instructorRoutes()
        : this.instructorTrainingRoutes();
    }

    SecurityService.clear();
    return this.guestRoutes();
  }

  render() {
    return <BrowserRouter>{this.getRoutes()}</BrowserRouter>;
  }
}

export default translate(App);

import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthConsumer } from "../../context/authContext";

const RootProvider = ({ children }) => {
  return (
    <BrowserRouter forceRefresh={false}>
      <AuthConsumer>
        {(props) => props.state.token && <ToastContainer limit={1} />}
      </AuthConsumer>
      <Switch>{children}</Switch>
    </BrowserRouter>
  );
};

export default RootProvider;

import React, { Component } from 'react';
import './DescriptionModal.scss';

export default class DescriptionModal extends Component {
  closeModal(){
    let modal = document.querySelector('.oficina-instrutor-modal')
    modal.style.display = 'none';
  }

  componentDidMount(){
    let content = document.querySelector('.oficina-instrutor-modal-text')
    content.innerHTML = this.props.conteudo.description
  }

  renderModal = () => {
    return (
      <div className="modal-content">
        <span className="oficina-instrutor-modal-title">{ this.props.conteudo.oficinaName }</span>

        <div className="oficina-instrutor-modal-text">
          { this.props.conteudo.description }
        </div>

        <span
          className="modal-close-button"
          onClick={ () => this.closeModal() }
        >
          {this.props.t('i_Class.btnBack')}
        </span>
      </div>
    )
  }

  render() {
    return (
      <div className="modal oficina-instrutor-modal">
        { this.renderModal() }
      </div>
    );
  }
}
import { add, isAfter } from "date-fns";
import LocalStorage from "./localStorage.service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { UserDataService } from "./userData.service";

const SecurityService = {
  
  isInstructor(authContext) {
    return (
      authContext.state.userData.hasOwnProperty("institution")
    );
  },

  async isInstructorLicenseExpired(authContext) {
    const MySwal = withReactContent(Swal);
    const userDataService = new UserDataService(authContext);
    const instructorData = await userDataService.fetch({ 
        role: authContext.state.role,
        id: authContext.state.userData._id 
    });

    const actualDate = new Date();
    const expiredDate = new Date(
      instructorData.institution.licenseExpireIn
    );

    const brasilianExpiredTime = add(expiredDate, { hours: 3 });

    const isDateAfter = isAfter(actualDate, brasilianExpiredTime);

    if (isDateAfter || !instructorData.institution.licenseActive) {
      // deletar dados do contexto
      authContext.clearContext();

      MySwal.fire({
        allowOutsideClick: false,
        confirmButtonColor: "#87BF40",
        confirmButtonText: `OK`,
        title: `<p>Sua licença está expirada!</p>`,
      }).then(function () {
        window.location = "/";
      });
    }
  },

  getToken() {
    return LocalStorage.retrieve("token");
  },
  setToken(token) {
    LocalStorage.persist("token", token);
  },
  clear() {
    LocalStorage.clear();
  },
};

export default SecurityService;

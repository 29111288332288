import { ReactComponent as IfaIcon } from "../../.././../../assets/images/instrutor/icone-ifa.svg";

const TrainingItem = (props) => {
    const { training, onClick, t } = props;

    return (
        <a
            key={training._id}
            onClick={onClick}
        >
            <li
                className={`list-treinamento-trilha ${training.status? 'concluido' : 'nao-concluido'}`}
            >
                <div className="list-treinamento-trilha-image concluido">
                    <IfaIcon />
                </div>

                <span className="list-treinamento-trilha-divider"></span>

                <div className="list-treinamento-trilha-content">
                <h4 className="list-treinamento-trilha-content-title">
                    {training.name}
                </h4>
                <span className="list-treinamento-trilha-content-status">
                    {t("i_training.status")}:
                    {training.status? ` ${t("i_training.finished")}`:` ${t("i_training.notFinished")}`}
                </span>
                </div>
                <span className="list-treinamento-trilha-arrow"></span>
            </li>
        </a>
    )
}

export default TrainingItem;
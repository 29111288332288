import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Missoes.scss';

import Header from '../../../Header/Header';
import Modal from './ModalMissoes/ModalMissoes';

import ClassService from '../../../../services/class.service';
import MissionService from '../../../../services/mission.service';
import ParticipantService from '../../../../services/participant.service';
import PowerService from '../../../../services/power.service';
import WorkshopService from '../../../../services/workshop.service';
import LocalStorage from '../../../../services/localStorage.service';
import Loading from '../../../Loading/Loading'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ContextAuth } from '../../../../context/authContext';
const MySwal = withReactContent(Swal)

export default class Missoes extends Component {
  static contextType = ContextAuth;

  constructor() {
    super()
    this.state = {
      name: '',
      code: '',
      missions: [],
      workshop: '',
      lastWorkshop: false,
      participants: [],
      openModal: false,
      participantIndex: '',
      participantCurrent: '',
      missionsCurrent: '',
      Loading: true,
      lang: ''
    }
  }

  async componentWillMount() {
    
    const lang = LocalStorage.retrieve('language');

    let { code, workshop } = this.props.match.params;
    const classCode = await ClassService.classExist(code);
    let participants = await ClassService.participantsByClass(code);

    let currentWorkshop = classCode.workshops.filter(c => c.workshop === workshop)
    let presence = currentWorkshop[0].presenceList

    const { last: lastWorkshop } = await WorkshopService.getById(workshop);

    let participantsPresence = []
    
    presence = presence.map(p => {
      participants.forEach(participant => {
        if (participant._id == p._id){
          p.powers = participant.powers
          p.missions = participant.missions
        }
      });
      return p;
    })

    let missions = await MissionService.getAll(this.context);
    let powers = await PowerService.getAll();
    
    missions = missions.map(mission => {
      return {
        _id: mission._id,
        power: mission.power,
        ...mission[lang]
      }
    })
    
    powers = powers.map(power => {
      return {
        _id: power._id,
        ...power[lang]
      }
    })
    
    // Filtrar alunos que não estão presentes
    for(let participant of presence){
      if (participant.presence) {
        participant.workshop = workshop;
        participant.missaoId = "";
        participant.missao = "";
        //const poderId = participant.powers.filter(p => p.workshop == workshop)[0].powerId
        const poderId = participant.powers.find(p => p.workshop == workshop)
        if(poderId){
          participant.poderId = poderId.powerId;
          participant.poder = powers.filter(p => p._id == poderId.powerId)[0].name
        }
        
        let mId = participant.missions.find(m => m.workshop == workshop)
        //let participantAux = await ParticipantService.getById(participant._id); //CAJU
        //let mId = participantAux.missions.find(m => m.workshop == workshop);
        if(mId){
          let missionIndex = missions.findIndex(mission => mission._id == mId.missionId);
          if(missionIndex > -1){
            participant.missaoId = missions[missionIndex]._id;
            participant.missao = missions[missionIndex].name;
          }
        }        
        participantsPresence.push(participant)
      }
    }

    await this.setState({
      _id: classCode._id,
      code: code,
      name: classCode.name,
      participants: participantsPresence,
      missions: missions,
      workshop: workshop,
      lastWorkshop: lastWorkshop,
      Loading: false,
      lang: lang,
    })
  }

  // renderWorkshopParticipantsAQuemEuAdmiro = () => {
  //   let listParticipant = []
    
  //   listParticipant = this.state.participants.map((item, index) =>
  //     <li className="participants-list-item" key={index}>
  //       <div className="participants-info">
  //         <span className="name">
  //           {item.name.includes(' ') ? 
  //           `${item.name.split(' ')[0]} ${item.name.split(' ')[item.name.split(' ').length - 1]}` :
  //           `${item.name}`}
  //         </span>
  //         <span className="power">{item.poder}</span>
  //         {/* VERIFICAR LINHA ACIMA - caju */}
  //       </div>

  //       <div className="participants-mission">
  //         <span className="mission">{item.missao}</span>
  //       </div>
  //     </li>
  //   )
  //   return listParticipant;
  // }

  renderParticipants = () => {
    let listParticipant = []

    
    
    listParticipant = this.state.participants.map((item, index) => {
      
      let coletiveMissionTranslation

      if (item.missao === "Missão Coletiva" && this.state.lang === "en") {
        coletiveMissionTranslation = "Collective Mission"
      } else if (item.missao === "Missão Coletiva" && this.state.lang === "es") {
        coletiveMissionTranslation = "Misión Colectiva"
      }

      return (
      <li className="participants-list-item" key={index}>
        <div className="participants-info">
          <span className="name">
            {item.name.includes(' ') ? 
            `${item.name.split(' ')[0]} ${item.name.split(' ')[item.name.split(' ').length - 1]}` :
            `${item.name}`}
          </span>
          <span className="power">{item.poder}</span>
          {/* VERIFICAR LINHA ACIMA - caju */}
        </div>

        <div className="participants-mission">
          <span className="mission">{coletiveMissionTranslation ?? item.missao}</span>
          {this.state.lastWorkshop !== true && 
            <span
              className="button"
              onClick={() => this.openModal(index)}
            >
              {this.props.t('i_missions.btnSelect')}
            </span>
          }
        </div>
      </li>
      )
    }
    )
    return listParticipant;
  }

  openModal = async (index) => {
    
    // Inicio do fluxo para atribuir uma missão para apenas um participante 
    if (typeof index === 'number') {
      let missionsCurrent = this.state.missions.filter(m => m.power == this.state.participants[index].poderId);
      const participant = await ParticipantService.getById(this.state.participants[index]._id);

      // Sinalizando missões que já foram atribuidas para o participante
      let participantMissionsId = participant.missions.map(m => {
        return m.missionId
      })
  
      missionsCurrent.map(mission => {
        mission.given = false
  
        if (participantMissionsId.includes(mission._id)) {
          mission.given = true
        }
      })
  
      await this.setState({
        participantIndex: index,
        participantCurrent: this.state.participants[index],
        missionsCurrent: missionsCurrent,
      });

      let modalSelect = document.querySelector('.modal-select');
      modalSelect.value = '';
    } 
    // Será atribuido a Missão Coletiva para todos os participantes
    else {
      let missionsCurrent = [];
      const missionCurrent = this.state.missions.find((mission) => mission.name === 'Missão Coletiva')

      if (missionCurrent) missionsCurrent.push(missionCurrent);

      await this.setState({
        missionsCurrent: missionsCurrent,
      });

      // // Captura todas as missões referente aos poderes dos participantes
      // this.state.participants.forEach( participant => {
      //   this.state.missions.forEach(mission => {
      //     if (mission.power == participant.poderId)
      //       missionsCurrent = [...missionsCurrent, mission];
      //    });
      // })

      // // Verifica se algum participante já teve alguma missão atribuida anteriormente
      // missionsCurrent.map(mission => {
      //   mission.given = false
      //   this.state.participants.map(participant => {
      //     participant.missions.map(participantMission => 
      //       participantMission.missionId === mission._id 
      //       ? mission.given = true 
      //       : mission.given = false )
      //   })
      // })
    }

    let modal = document.querySelector('.missoes-instrutor-modal');
    modal.style.display = 'block'
  }

  storeMission = async (index, missionId) => {
    
    // Index indica que a missão é para apenas um participante
    if (missionId != '' && typeof index === 'number') {
      const mission = this.state.missions.filter(m => m._id == missionId)[0];
      let participants = this.state.participants
      participants[index] = {
        ...participants[index],
        missaoId: mission._id,
        missao: mission.name,
        workshop: this.state.workshop
      }

      await this.setState({ participants: participants })
    } 
    // Sem index, indica que a missão é para todos os participantes na oficina A Quem Eu Admiro
    else if (missionId != '' && !index) {
      const mission = this.state.missions.filter(mission => mission._id == missionId)[0];

      const participants = this.state.participants.map(participant => {
        participant = {
          ...participant,
          missaoId: mission._id,
          missao: mission.name,
          workshop: this.state.workshop
        }
        return participant;
      })

      await this.setState({ participants: participants });
    }

    // await this.saveMission();
  }

  async saveMission(i) {
    // let participantsMission = this.state.participants.filter(participant => participant.missaoId != "");
    if(this.state.participants[i].missaoId != ''){
      let res = await MissionService.assignMission([this.state.participants[i]]);
      // if (res.success) {
      //   // MySwal.fire({
      //   //   confirmButtonColor: '#87BF40',
      //   //   confirmButtonText: <span>OK</span>,
      //   //   icon: 'success',
      //   //   title: <p>{this.props.t('i_missions.successSave')}</p>
      //   // })
      // }
    }
  }

  async finishMission() {
    let withoutMission = this.state.participants.filter(participant => participant.missaoId === '');
    if (withoutMission.length > 0) {
      MySwal.fire({
        confirmButtonColor: '#87BF40',
        confirmButtonText: <span>OK</span>,
        icon: 'error',
        title: <p>{this.props.t('i_missions.errorMessage')}</p>
      })
    } else {
      let res = await MissionService.assignMission(this.state.participants);
      if (res.success) {
        const steps = await ClassService.completeStep(this.state.code, this.state.workshop, 'missionsCompleted')
        if (steps._id) {
          MySwal.fire({
            confirmButtonColor: '#87BF40',
            confirmButtonText: <span>OK</span>,
            icon: 'success',
            title: <p>{this.props.t('i_missions.successMesage')}</p>
          })
        }
        this.props.history.push(`/oficina-instrutor/${this.state.code}`);
      }
    }
  }

  render() {
    return (
      <Loading fade = { this.state.Loading } >
        <div>
          <Header />

          <div className="page-title-header">
            <h1 className="page-title">{this.props.t('i_missions.title')}</h1>
            <span className="page-title-border"></span>
          </div>

          <main className="missoes-instrutor-main">
            <h3 className="missoes-instrutor-class-name">
              {this.state.name}
              <span>{this.props.t('i_missions.code')}: {this.state.code}</span>
            </h3>

            <p 
              className="missoes-instrutor-text">
              {!this.state.lastWorkshop ? 
                this.props.t('i_missions.intro') : 
                this.props.t('i_missions.introCollectiveMission')}
            </p>

            {this.state.lastWorkshop === true &&
              <button
                className="poderes-info-turma button-full"
                onClick={() => this.openModal()}
              >
                {this.props.t('i_missions.btnCollectiveMission')}
              </button>
            }

            <div className="card missoes-instrutor-participants">
              <div className="list-header">
                <span>{this.props.t('i_missions.listHeader')}</span>
                <span>{this.props.t('i_missions.listMission')}</span>
              </div>

              <ul className="participants-list">
                {this.renderParticipants()}
              </ul>

              <Modal
                index={this.state.participantIndex}
                participant={this.state.participantCurrent}
                missions={this.state.missionsCurrent}
                lastWorkshop={this.state.lastWorkshop}
                storeMission={this.storeMission}
                lang={this.state.lang}
                t={this.props.t}
              />

            </div>

            <a href={`/turmas-consult/${this.state.code}`} className="poderes-info-turma button-full">
              {this.props.t('i_FinishedClasses.btnInfo')}
            </a>
            
            <button
              className="missoes-instrutor-finish button-full"
              onClick={() => this.finishMission()}
            >
              {this.props.t('i_missions.btnFinish')}
            </button>
          </main>
        </div>
      </Loading>
    );
  }
}
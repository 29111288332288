import React, { Component } from "react";
import ParticipantService from "../../../services/participant.service";
import LocalStorage from "../../../services/localStorage.service";

import { connect } from "react-redux";
import {
  UPDATE_FIELD_AUTH,
  LOGIN,
  LOGIN_PAGE_UNLOADED,
} from "../../../actionTypes";

import "./Login.scss";
import logoHorizontal from "../../../assets/images/logo/logo-horizontal.png";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ContextAuth } from "../../../context/authContext";
import { navigate } from "../../../services/navigation.service";
const MySwal = withReactContent(Swal);

const localize = (pt, en, es) => {
  return LocalStorage.retrieve("language") &&
    LocalStorage.retrieve("language") === "pt"
    ? pt
    : LocalStorage.retrieve("language") === "es"
    ? es
    : en;
};

const showError = () => {
  MySwal.fire({
    confirmButtonColor: "#87BF40",
    confirmButtonText: <span>{localize("TENTAR NOVAMENTE", "TRY AGAIN", "INTÉNTALO DE NUEVO")}</span>,
    icon: "error",
    title: (
      <p>
        {" "}
        {localize(
          "Nenhum participante foi encontrado com estes dados.",
          "No participant was found with this data.",
          "No se encontraron participantes con estos datos."
        )}{" "}
      </p>
    ),
  });
};

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  changeCode: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "code", value }),
  changeDate: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "date", value }),
  changeNames: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "names", value }),
  auth: (value) => {
    dispatch({ type: LOGIN, payload: value });
    dispatch({ type: UPDATE_FIELD_AUTH, key: "loginSuccess", value });
  },
  onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
});

class LoginParticipante extends Component {
  static contextType = ContextAuth;

  changeCode = (ev) => this.props.changeCode(ev.target.value);
  changeDate = (ev) => this.props.changeDate(ev.target.value);
  reset = () => this.props.changeNames(undefined);

  authenticate = async (name, classCode, born) => {
    let res;
    try {
      res = await ParticipantService.login({ name, classCode, born });
    } catch (err) {
      showError();
      return;
    }

    const { participant, token, expirationDate, success } = res;

    // Loop?
    // this.props.auth(res);

    if (success) {

      
      this.context.setContext(
        {
        token,
        tokenExp: expirationDate,
        userData: participant,
        role: "participant",
      });
    }
  };

  findParticipants = async (code, date) => {
    try {
      let res = await ParticipantService.find(code, date);

      if (res.length < 1) showError();
      else if (res.length == 1) this.authenticate(res[0].name, code, date);
      else this.props.changeNames(res.map((x) => x.name));
    } catch (err) {
      showError();
    }
  };

  find = (code, date) => (ev) => {
    ev.preventDefault();
    this.findParticipants(code, date);
  };

  auth = (name, code, date) => (ev) => {
    ev.preventDefault();
    this.authenticate(name, code, date);
  };

  componentDidUpdate(prevProps, prevState) {
    if(!this.context.state.token) return

    const navigator = navigate.bind(this);
    navigator({ link: "/home-participant" })

  }

  render() {
    const code = this.props.code;
    const date = this.props.date;
    const names = this.props.names;

    const form = (
      <>
        <h3 className="login-participante-title">
          {this.props.t("participantLogin.instructions")}
        </h3>

        <form
          onSubmit={this.find(code, date)}
          className="login-participante-form"
        >
          <div className="form-group">
            <label className="login-participante-label">
              {this.props.t("login.label")}
            </label>
            <input
              className="login-participante-input"
              id="code"
              type="text"
              maxLength={6}
              placeholder={this.props.t("login.codePlaceholder")}
              value={code}
              onChange={this.changeCode}
            />
          </div>

          <div className="form-group">
            <label className="login-participante-label">
              {this.props.t("participantLogin.dateLabel")}
            </label>
            <input
              className="login-participante-input"
              type="date"
              value={date}
              onChange={this.changeDate}
            />
          </div>

          <button
            className="login-participante-submit button-full"
            type="submit"
            value="entrar"
          >
            {this.props.t("participantLogin.loginButton")}
          </button>
        </form>
      </>
    );

    const buttons = !names ? (
      <></>
    ) : (
      names.map((name) => (
        <button
          className="login-participante-submit button-full"
          onClick={this.auth(name, code, date)}
          key={name}
        >
          {name}
        </button>
      ))
    );

    const options = (
      <div className="login-participante-form">
        <h3 className="login-participante-title">
          {this.props.t("participantLogin.chooseParticipantLabel")}
        </h3>
        {buttons}

        <button
          className="login-participante-cancel button-full"
          onClick={this.reset}
        >
          {this.props.t("participantLogin.noneOfThem")}
        </button>
      </div>
    );

    return (
      <div className="login-participante">
        <header className="login-participante-header">
          <img
            src={logoHorizontal}
            className="login-participante-header-image"
            alt="logo IFA"
          />
          <h1 className="login-participante-header-title">
            {this.props.t("instructorLogin.title")}
          </h1>
        </header>

        <main className="login-participante-main">
          {names ? options : form}

          <a href="/" className="login-participante-back">
            {this.props.t("participantLogin.backLink")}
          </a>
        </main>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginParticipante);

import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

const url = 'https://viacep.com.br/ws';

const CepService = {
    getLocalization: async (cep) => {
        const endPoint = `${url}/${cep}/json/`;
        return superagent.get(endPoint).then(res => res.body);
    }
};

export default CepService;
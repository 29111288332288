import { Component } from "react";
import "./AdicionarImagem.scss";

import LocalStorage from "../../../../../services/localStorage.service";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default class AdicionarImagem extends Component {
  constructor(props) {
    super(props);
    this.lang = LocalStorage.retrieve("language");

    this.swalAtribuirParticipante = withReactContent(Swal);

    this.state = {
      participant: [],
      participants: this.props.workshopsParticipants,
      previewImg: "",
      file: "",
      reader: null,
    };
  }

  assignMissionToImageHandler() {
    const participantWithoutMissionAssigned = this.state.participants.find(p => p.participant.mission == null);
    if(!participantWithoutMissionAssigned) {
      document
      .getElementById("cardImagem-enviarImagemInput")
      .click();
    } else {
      let MySwal = withReactContent(Swal);

      MySwal.fire({
        html: <p class="adicionarimagem-modalerrortext">{this.props.t("i_AddImage.errorMissionWithoutAssignedTextOne") + (this.props.wkIndex + 1) + this.props.t("i_AddImage.errorMissionWithoutAssignedTextTwo")}</p>,
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        focusConfirm: false,
      });
    }
  }

  imageHandler = (e) => {
    this.reader = new FileReader();
    this.reader.onload = () => {
      if (this.reader.readyState === 2) {
        this.setState({ previewImg: this.reader.result });
      }
    };
    this.reader.readAsDataURL(e.target.files[0]);

    this.setState({ file: e.target.files[0] })
    this.onChange();
  };

  onChange() {
    this.swalAtribuirParticipante.fire({
      title: <p>{this.props.t("i_AddImage.formTitle")}</p>,
      allowOutsideClick: false,
      confirmButtonText: this.props.t("i_AddImage.formSaveButton"),
      customClass: {
        confirmButton: 'adicionarImagem-modalSaveButton',
      },
      html: (
        <form className="cardImagem-form">
          <label>{this.props.t("i_AddImage.formParticipantName")}</label>
          <select
            className="cardImagem_selectParticipant"
            onChange={this._setMission.bind(this)}
            required
          >
            <option selected disabled>
              {this.props.t("i_AddImage.formPlaceHolderParticipant")}
            </option>
            {this.state.participants.map((participant, indice) => {
              return (
                <option key={indice} value={indice}>
                  {participant.participant.name}
                </option>
              );
            })}
          </select>
          <label>{this.props.t("i_AddImage.formMissionNameLabel")}</label>
          <input
            className="cardImagem_inputParticpant"
            id="cardImagem_inputParticpant"
            type="text"
            required
            disabled
            placeholder={this.props.t("i_AddImage.formPlaceHolderParticipant")}
          ></input>
        </form>
      ),
      didOpen: () => {
        this.swalAtribuirParticipante.clickConfirm();
      },
    }).then(() => {
      this.adicionarNovaImagem();
    });

    this.swalAtribuirParticipante.disableButtons()
  }

  adicionarNovaImagem() {
    const workshop = this.props.workshops.find(workshop => workshop._id === this.props.workshopId)
    this.props.adicionarImagem(this.state.participant, this.state.file, this.state.previewImg, this.props.instructor, this.props.code, workshop, this.props.dateTime);

    this.props.sendButton(true);

    this.removerParticipanteSelecionado(this.state.participant._id)

    this.setState({
      participant: [],
      previewImg: "",
      file: "",
      reader: null,
    })
  }

  _setMission(e) {
    let indice = e.target.value;
    let participant = this.state.participants[indice];
    document.getElementById("cardImagem_inputParticpant").value =
    participant.participant.mission[this.lang].name;

    this.setState({ participant: participant.participant });
    this._enableButtons();
  }

  _enableButtons() {
    this.swalAtribuirParticipante.enableButtons();
  }

  removerParticipanteSelecionado(participantSelecionado) {
    const participants = this.props.removerParticipanteSelecionado(participantSelecionado);
    this.setState({ participants: participants });
  }

  componentDidUpdate(prevProps) {
    if (this.props.workshopsParticipants !== prevProps.workshopsParticipants) {
      const participants = this.props.workshopsParticipants;
      this.setState({ participants: participants })
    }
  }

  render() {
    return (
      <section id={"cardImagem-section"}>
        <div id={"cardImagem-item"} className="cardImagem-item">
          <button
            className="cardImagem-enviarImagemButton"
            onClick={this.assignMissionToImageHandler.bind(this)}
          >
            +
          </button>
          <input
            id={"cardImagem-enviarImagemInput"}
            type="file"
            accept="image/png, image/gif, image/jpeg"
            hidden
            onInput={this.imageHandler}
          />
        </div>
      </section>
    );
  }
}

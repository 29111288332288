import { useEffect, useState } from 'react';

import './EstrelasModal.scss';
import { ReactComponent as StarIcon } from '../../../../../assets/images/instrutor/icon-star.svg';

export default function EstrelasModal({ t, to, star, onChooseStar }) {

  const [ checkedStar, setCheckedStar ] = useState("0");

  useEffect(() => {
    setCheckedStar(!star || !star.length ? "0" : star)
  }, [star]);

  function close() {
    onChooseStar(checkedStar);

    let modal = document.querySelector('.estrelas-instrutor-modal')
    modal.style.display = 'none';
    
    const $noStarInput = document.querySelectorAll('.modal-input#later')
    $noStarInput.checked = false;
    setCheckedStar("0");
  }

  function changeStar(e) {
    setCheckedStar(e.target.value);
  }

  function renderOptions() {
    const textKeys = ['notAssigned', 'late', 'assigned'];
    const values = ["0", "2", "1"];

    return ['incomplete', 'later', 'complete'].map((id, index) => {
      return (
        <>
          <input
            className="modal-input"
            id={id}
            name="star"
            type="radio"
            value={values[index]}
            checked={checkedStar === values[index]}
            onClick={changeStar.bind(this)}
          />
          <label htmlFor={id} className="modal-label">
            {t(`i_stars.modal.${textKeys[index]}`)}
            <StarIcon className={`modal-star-icon ${id}`}/>
          </label>
        </>
      )
    })
  }

  return (
    <div className="modal estrelas-instrutor-modal">
      <div className="modal-content">

        <h2 className="modal-title">{t('i_stars.modal.title')}</h2>
        <p className="modal-participant-name">
          {t('i_stars.modal.to')}: <span>{to}</span>
        </p>

        <h4 className="modal-subtitle">{t('i_stars.modal.stars')}</h4>

        <form className="modal-form">
          {renderOptions()}
        </form>

        <span className="modal-close-button" onClick={close}>
          {t('i_stars.modal.btnBack')}
        </span>
      </div>
    </div>
  );
}
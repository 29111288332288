import React ,{ Component } from "react";
import './Modal.scss';

import ParticipantService from '../../../../services/participant.service';
import LocalStorage from '../../../../services/localStorage.service';
import { ContextAuth } from "../../../../context/authContext";

export default class ModalPoll extends Component {
  static contextType = ContextAuth;

  async changePollAnswered() {
    let participant = this.context.state.userData;
    ParticipantService.changePollAnswered(participant._id)
      .then(res => this.context.setContext({ userData: res }));
  }

  async closeModal() {
    try {
      await this.changePollAnswered();
      window.location.assign("https://forms.gle/A2Mt3SmEhJSqLH4i9");
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    return (

      <div className="modal poll-modal">
      <div className="modal-content">
        <h2 className="modal-title">{this.props.t('participantHome.hello')}, {this.props.name}</h2>
        <p className="modal-context">
          {this.props.t('participantHome.modal.context')}
        </p>

        <span
          className="answer-button"
          onClick={() => this.closeModal()}
        >
          {this.props.t('participantHome.modal.btnAnswer')}
        </span>
      </div>
    </div>
    );
  }
}
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { setLanguage } from 'react-multi-lang'
import { connect } from 'react-redux';

import './Login.scss'
import loginImage from '../../assets/images/login/login.png';
import logoHorizontal from '../../assets/images/logo/logo-horizontal.png';
import imagePT from '../../assets/images/login/portugues.png';
import imageEN from '../../assets/images/login/ingles.png';
import imageES from '../../assets/images/login/espanhol.png';

import LocalStorage from '../../services/localStorage.service';
import ParticipantService from '../../services/participant.service'
import { AuthConsumer, ContextAuth } from '../../context/authContext';

const mapStateToProps = state => ({
  ...state.auth,
});

class Login extends Component {
  static contextType = ContextAuth;

  changeFirstAccess = ParticipantService.changeFirstAccess;

  changeLang = (lang) => {
    LocalStorage.persist('language', lang)
    setLanguage(lang);
    // window.location.reload();
  }

  render() {
    
    if (this.props.loginSuccess && this.props.loginSuccess._id) {

      this.context.setContext({ userData: this.props.loginSuccess })

      let participant = this.props.loginSuccess;
      let id = participant._id

      if (participant.firstAcess) {
        this.changeFirstAccess(id);
        this.props.history.push("/welcome");
      }
      else {
        this.props.history.push("/home-participante");
      }
    }

    return (
      <div className="login">
        <header className="login-header">
          <img src={logoHorizontal} className="login-header-image" alt="logo IFA" />
          <h1 className="login-header-title">{this.props.t('login.title')}</h1>
        </header>

        <button onClick={() => { this.changeLang('pt') }}>
          <img src={imagePT} className="image-language" alt="pt" />
        </button>

        <button onClick={() => this.changeLang('en')}>
          <img src={imageEN} className="image-language" alt="en" />
        </button>

        <button onClick={() => this.changeLang('es')}>
          <img src={imageES} className="image-language" alt="es" />
        </button>

        <main className="login-main">
          <label className="login-label">{this.props.t('login.joinAs')}</label>

          <Link to="/login-participante" className="login-submit button-full">
            {this.props.t('login.btnParticipant')}
          </Link>

          <Link to="/login-instrutor" className="login-instrutor-link button-full">
            {this.props.t('login.btnInstructor')}
          </Link>

          <div className="login-box-image">
            <img src={loginImage} className="login-image" alt="Ilustração IFA" />
          </div>
        </main>
      </div>
    );
  }
}
export default connect(mapStateToProps)(Login);
import React, { Component } from 'react';

import PowerService from '../../../../services/power.service';
import QuizService from '../../../../services/quiz.service';
import ParticipantService from '../../../../services/participant.service'
import LocalStorage from '../../../../services/localStorage.service';

import { Link } from 'react-router-dom';


import './List.scss';
import Header from '../../../Header/Header';
import { ReactComponent as IfaIcon } from '../../../../assets/images/instrutor/icone-ifa.svg';
import { ContextAuth } from '../../../../context/authContext';

export default class List extends Component {
  static contextType = ContextAuth;

  constructor(props){
    super(props)
    this.state = {
      games: [],
      quizzes: [{}],
      participant: {},
      gameCompleted: [],
      typeGame: this.props.location.search.split('=')[1]
    }
  }

  async componentWillMount(){
    const lang = LocalStorage.retrieve('language');
    // Pega localstorage do participante
    let localParticipante = this.context.state.userData;

    //Pega participante do banco com base no ID do localstorage
    let participante = await ParticipantService.getById(localParticipante._id)
    let quizzes = await QuizService.getTypeGame(this.state.typeGame)
    let games = await PowerService.getAll();
    games = games.map(game => {
      return {
        _id: game._id,
        ...game[lang]
      }
    })
    
    await this.setState({
      participant: participante,
      gameCompleted: participante.gameCompleted,
      games: games,
      quizzes: quizzes
    })

     let trainings = quizzes.map(quiz => quiz.training)

   let gamesAux = [];
   games.forEach(game =>{
     if(trainings.includes(game._id)){
       if(this.state.gameCompleted.includes(game._id))
          game.status = true;
        else 
          game.status = false;
      gamesAux.push(game);
    }
   });

    await this.setState({games : gamesAux})

  }

  gameList(){
    let gameList = []

    let gameLegth = this.state.games.length ? this.state.games.length : "";

    gameList = gameLegth  && this.state.games.map(game => {
        return(
          <Link
            to={`/game/${game._id}`}
            className={game.status ? "game-list concluido" : "game-list"}>
            <div className={game.status ? "game-list-image concluido" : "game-list-image"}>
              <IfaIcon />
            </div>

            <span className="game-list-divider"></span>

            <div className="game-list-content">
              <h4 className="game-list-content-power">{ game.name }</h4>
              <span className="game-list-content-status">
                {this.props.t('gameList.status')}: {game.status ? `${this.props.t('gameList.completed')}` : `${this.props.t('gameList.notCompleted')}`}
              </span>
            </div>

            <span className="game-list-arrow"></span>
          </Link>
         )
    })

    return gameList
  }

  render(){
    return(
      <div>
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t('gameList.title')}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="game-list-main">
          { this.gameList() }
        </main>
      </div>
    );
  }
}
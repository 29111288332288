import React, { Component } from 'react';
import './Modal.scss';

export default class ModalMissoes extends Component {

  async closeModal() {
    let modal = document.querySelector('.missoes-participant-modal')
    modal.style.display = 'none';
  }

  render() {

    const { nextButton, onClickNextButton } = this.props

    return (
      <div className="modal missoes-participant-modal">
        <div className="modal-content">
          <h2 className="mission-title">{this.props.name}</h2>
          <p className="mission-context">
            {this.props.context}
          </p>

          <div className="modal-card">
            <input id="todo" type="checkbox" />

            <label htmlFor="todo">
              <span className="modal-card-title">
                <h3>{this.props.t('p_missions.modal.toDo')}</h3>
                <span></span>
              </span>
            </label>

            <div className="modal-card-text">
              {this.props.todo}
            </div>
          </div>

          <div className="modal-card">
            <input id="result" type="checkbox" />

            <label htmlFor="result">
              <span className="modal-card-title">
                <h3>{this.props.t('p_missions.modal.result')}</h3>
                <span></span>
              </span>
            </label>

            <div className="modal-card-text">
              {this.props.result}
            </div>
          </div>

          <div className="modal-card">
            <input id="tip" type="checkbox" />

            <label htmlFor="tip">
              <span className="modal-card-title">
                <h3>{this.props.t('p_missions.modal.tip')}</h3>
                <span></span>
              </span>
            </label>

            <div className="modal-card-text">
              {this.props.tip}
            </div>
          </div>

          {
            nextButton &&
            <button
              className="modal-close-button"
              onClick={onClickNextButton}
            >
              {nextButton}
            </button>
          }
          <button
            className="modal-close-button"
            onClick={() => this.closeModal()}
          >
            {this.props.t('p_missions.modal.btnBack')}
          </button>
        </div>
      </div>
    )
  }
}
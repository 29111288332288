import React, { Component } from "react";
import InstructorService from "../../../services/instructor.service";
import { connect } from "react-redux";
import { UPDATE_FIELD_AUTH } from "../../../actionTypes";
import { Link } from "react-router-dom";

import "./LoginInstrutor.scss";
import LocalStorage from "../../../services/localStorage.service";

import logoHorizontal from "../../../assets/images/logo/logo-horizontal.png";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ContextAuth } from "../../../context/authContext";
import { navigate } from "../../../services/navigation.service";
const MySwal = withReactContent(Swal);

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  changeLogin: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "login", value }),
  changePassword: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "password", value }),
  onSubmit: async (instructor, props, context, refreshPage) => {
    try {
      let {
        instructor: instructorData,
        expirationDate,
        token,
        success,
      } = await InstructorService.login(instructor);
      if (success && token) {
        LocalStorage.persist("isLicenseWarnClosed", false)

        context.setContext({
          token,
          tokenExp: expirationDate,
          role: "instructor",
          userData: instructorData,
        });
      }
    } catch (err) {
      if (err.response.statusCode !== 401) {
        MySwal.fire({
          confirmButtonColor: "#87BF40",
          confirmButtonText: (
            <span>
              {LocalStorage.retrieve("language") &&
              LocalStorage.retrieve("language") === "pt"
                ? "TENTAR NOVAMENTE"
                : LocalStorage.retrieve("language") === "en"
                ? "TRY AGAIN"
                : "INTÉNTALO DE NUEVO"}
            </span>
          ),
          icon: "error",
          title: (
            <p>
              {LocalStorage.retrieve("language") &&
              LocalStorage.retrieve("language") === "pt"
                ? "Usuário ou senha inválido."
                : LocalStorage.retrieve("language") === "en"
                ? "login or password are wrong."
                : "Acesso o la contraseña son incorrectos."}
            </p>
          ),
        });
      } else {
        MySwal.fire({
          confirmButtonColor: "#87BF40",
          confirmButtonText: <span>OK</span>,
          icon: "error",
          title: (
            <p>
              {LocalStorage.retrieve("language") &&
              LocalStorage.retrieve("language") === "pt"
                ? "Sua licença está expirada! Contate um administrador."
                : LocalStorage.retrieve("language") === "en"
                ? "Your license was expired! contact a website administrator."
                : "¡Su licencia ha caducado! Póngase en contacto con un administrador."}
            </p>
          ),
        });
      }
    }
  },
});

class LoginInstrutor extends Component {
  static contextType = ContextAuth;

  constructor(props) {
    super(props);

    this.changeLogin = (ev) => this.props.changeLogin(ev.target.value);
    this.changePassword = (ev) => this.props.changePassword(ev.target.value);

    this.submitForm = (login, password) => async (ev) => {
      ev.preventDefault();
      await this.props.onSubmit({ login, password }, this.props, this.context);

      const navigator = navigate.bind(this);
      navigator({ link: "/", verifyToken: false });
    };
  }

  render() {
    const login = this.props.login;
    const password = this.props.password;

    return (
      <div className="login-instrutor">
        <header className="login-header">
          <img
            src={logoHorizontal}
            className="login-header-image"
            alt="logo IFA"
          />
          <h1 className="login-header-title">
            {this.props.t("instructorLogin.title")}
          </h1>
        </header>

        <main className="login-instrutor-main">
          <h2 className="login-instrutor-title">
            {this.props.t("instructorLogin.question")}
            <span>{this.props.t("instructorLogin.instructions")}</span>
          </h2>

          <form onSubmit={this.submitForm(login, password)}>
            <label className="login-instrutor-label">
              {this.props.t("instructorLogin.userLabel")}
            </label>
            <input
              className="login-instrutor-input"
              type="text"
              placeholder={this.props.t("instructorLogin.userPlaceholder")}
              value={login}
              onChange={this.changeLogin}
            />

            <label className="login-instrutor-label">
              {this.props.t("instructorLogin.passwordLabel")}
            </label>
            <input
              className="login-instrutor-input"
              type="password"
              placeholder={this.props.t("instructorLogin.passwordPlaceholder")}
              value={password}
              onChange={this.changePassword}
            />

            <a className="login-instrutor-forgot" href="/forgot-password">
              {this.props.t("instructorLogin.forgotLogin")}
            </a>

            <button
              className="login-instrutor-submit button-full"
              type="submit"
              value="entrar"
            >
              {this.props.t("instructorLogin.loginButton")}
            </button>
          </form>

          <Link
            to="/cadastro-instrutor"
            className="login-instrutor-cadastro button-full"
          >
            {this.props.t("instructorLogin.newInstructor")}
          </Link>

          <a href="/" className="login-instrutor-back">
            {this.props.t("instructorLogin.backLink")}
          </a>
        </main>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginInstrutor);

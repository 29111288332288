import "./ApproveMissionBox.scss";

import { withRouter } from 'react-router';
import {useContext} from 'react';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ContextAuth } from '../../../../../../context/authContext';
import Checkbox from "../../../../../Form/Checkbox/Checkbox";
import getTranslation from "../../../../../../utils/getTranslation";
import MissionUploadPage from '../../../../../Participante/MissionUploadPage/MissionUploadPage';

const ApproveMissionBox = (props) => {
  const { participant } = props
  const { data, checked, changeChecked } = participant
  const context = useContext(ContextAuth);
  const MySwal = withReactContent(Swal)

  const approveMissionCheckboxID = `approve-mission-checkbox-${participant._id}`;

  const swalProps = {
    id: participant._id,
    missionId: data.mission._id,
    context,
    isModal: true
  }

  const handleClick = () => {
    MySwal.fire({
      html: <MissionUploadPage {...swalProps} />,
      width: '90vw',
      padding: 0,
      background: '#fff',
      backdrop: `rgba(0, 0, 0, 0.4)`
    }).then()
  }

  return (
      <div className="approve-mission-info item-info">
          <Checkbox
            id={approveMissionCheckboxID}
            label={getTranslation('i_stars.approve_mission_box.label')}
            classes={['approve-mission-checkbox']}
            onChange={changeChecked}
            isChecked={checked}
          />
          <div>
            <button
              onClick={handleClick}
            >
              {getTranslation('i_stars.approve_mission_box.button')}
            </button>
          </div>
      </div>
  )
}
export default withRouter(ApproveMissionBox)
import { useContext, useEffect, useState } from "react";
import { ContextAuth } from "../../../../context/authContext";
import Header from "../../../Header/Header";
import Loading from "../../../Loading/Loading";
import PageTitle from "../../../PageTitle/PageTitle";
import MenuItem from "../../MissionsMenuPage/components/MenuItem";
import getTranslation from "../../../../utils/getTranslation";
import { getPowersAndMissionsDescriptions } from "../utils/getPowersAndMissionsDescriptions";
import missionStatus from "../../../../shared/constants/missionStatusTypes";
import useMissionInformationAndTipForModal from "../../../../hooks/useMissionInformationAndTipForModal";

import "../Missoes.scss"

const PendingMissions = (props) => {
  const [
    userMissionsAndPowersWithDescriptions,
    setUserMissionsAndPowersWithDescriptions,
  ] = useState();
  const [isDataFeching, setIsDataFetching] = useState(true);
  const { 
    setMissionInformationAndTip,
    ModalComponent 
  } = useMissionInformationAndTipForModal()
  const context = useContext(ContextAuth);
  const userMissions = context.state.userData.missions;
  const participantId = context.state.userData._id;

  const userMissionsFiltered = userMissions.filter(
    (mission) => mission.status === missionStatus.incomplete
  );

  useEffect(() => {
    setIsDataFetching(true);

    getPowersAndMissionsDescriptions({ context, userMissionsFiltered }).then((missions) => {
      setUserMissionsAndPowersWithDescriptions(missions);
      setIsDataFetching(false);
    });
  }, []);

  return (
    <Loading fade={isDataFeching}>
      {userMissionsAndPowersWithDescriptions && (
        <div>
          <Header />
          <PageTitle title={getTranslation("p_missionsPending.title")} />
          <main className="list-missao-main">
            <p className="list-missao-text">
            {getTranslation("p_missionsPending.subTitle")}
            </p>
            {userMissionsAndPowersWithDescriptions.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  title={item.missionDescription.name}
                  subtitle={`${getTranslation("p_missions.power")}: ${
                    item.powerDescription.name
                  }`}
                  onClick={() => setMissionInformationAndTip({
                    ...item.missionDescription, 
                    participantId,
                    participantMissionId: item._id
                  })}
                />
              );
            })}
          </main>
          <ModalComponent />
        </div>
      )}
    </Loading>
  );
};

export default PendingMissions;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Poderes.scss';

import Header from '../../../Header/Header';
import Poder from './Poder/Poder';

import ClassService from '../../../../services/class.service';
import PowerService from '../../../../services/power.service';
import ParticipantService from '../../../../services/participant.service';
import LocalStorage from '../../../../services/localStorage.service';
import {ReactComponent as InfoIcon} from '../../../../assets/images/oficinas/info.svg';

import Loading from '../../../Loading/Loading'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export default class Poderes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      code: '',
      participants: [],
      poderes: [],
      qtyMaxPowers: 0,
      qtyMinPowers: 1,
      workshop: '',
      lang: '',
      selectedPowers: [],
      Loading: true
    }
  }

  changePower = async (e) => {
    const id = e.target.id;
    const value = e.target.value;
    let selectedPowers = this.state.selectedPowers;
    let exists = false;
    let localizePower = "";

    let tes = selectedPowers.filter(val => val.value === value)

    selectedPowers.forEach((val, index) => {
      if(localizePower === "" && val.value === value && tes.length >= this.state.qtyMaxPowers) {  
        localizePower = index;
      }
    });

    let newPowersSelecteds = selectedPowers.map(val => {
      if(val.ref === id) {
        val.value = value;
        exists = true;
      }
      return val;
    });

    if(localizePower !== ""){
      this[newPowersSelecteds[localizePower].ref].value = "";
      const participantIndex = this.state.participants.findIndex(p => p._id == newPowersSelecteds[localizePower].ref);
      this.state.participants[participantIndex].poderId = '';
      this.state.participants[participantIndex].poder = '';
      newPowersSelecteds.splice( localizePower, 1 );
    }


    if(!exists) {
      newPowersSelecteds.push({ref: id, value })
    }

    this.setState({
      selectedPowers: [...newPowersSelecteds]
    })

    let poder = ''
    if(value != '')
      poder = this.state.poderes.filter(p => p._id == value)[0].name;
    else
      poder = ''
    let participants = this.state.participants
    const index = participants.findIndex(p => p._id === id);

    participants[index] = {
      ...participants[index],
      poderId: value,
      poder: poder,
      workshop: this.state.workshop,
    }

    this.setState({
      participants: participants
    })

    // Verificar se poderes já foram atribuídos de acordo com a quantidade disponível
    let powers = this.state.poderes.map(power => {
      let counter = 0;
      this.state.participants.forEach(p => {
        if (p.poderId == power._id) counter++
      })
      power.counter = counter;
      return power;
    })

    this.setState({
      poderes: powers
    })    

    //Exec função para salvar no banco automaticamente
    await this.savePoderes(index);
  }

  async componentWillMount() {
    const lang = LocalStorage.retrieve('language');

    let { code, workshop } = this.props.match.params;
    const classCode = await ClassService.classExist(code);
    let participants = await ClassService.participantsByClass(code);

    let powers = await PowerService.getAll();
    powers = powers.map(power => {
      return {
        _id: power._id,
        ...power[lang],
        counter: 0
      }
    })
    
    let currentWorkshop = classCode.workshops.filter(c => c.workshop === workshop)
    let presence = currentWorkshop[0].presenceList
    presence = presence.map(p => {
      participants.forEach(participant => {
        if (participant._id == p._id)
          p.powers = participant.powers
      });
      return p;
    })

    // Filtar alunos que não estão presentes
    let participantsPresence = [];
    let selectedPowers = [];

    for(let participant of presence){
      if(participant.presence){
        participant.workshop = workshop;
        participant.poderId = "";
        participant.poder = "";
        
        // let participantAux = await ParticipantService.getById(participant._id);
        // let pId = participantAux.powers.find(p => p.workshop == workshop);
        let pId = participant.powers.find(p => p.workshop == workshop);
        if(pId){
          let powerIndex = powers.findIndex(power => power._id == pId.powerId)
          if(powerIndex > -1){
            participant.poderId = powers[powerIndex]._id;
            participant.poder = powers[powerIndex].name;
            powers[powerIndex].counter++;

            selectedPowers.push({
              ref: participant._id,
              value: powers[powerIndex]._id
            })
          }
        }

        participantsPresence.push(participant)
      }
    }

    await this.setState({
      _id: classCode._id,
      code: code,
      name: classCode.name,
      participants: participantsPresence,
      poderes: powers,
      workshop, workshop,
      lang: lang,
      Loading: false,
      selectedPowers: selectedPowers,
      qtyMaxPowers: Math.ceil(participantsPresence.length / powers.length),
      qtyMinPowers: participantsPresence.length > powers.length ? 
        Math.floor(participantsPresence.length / powers.length) : 
        participantsPresence.length == powers.length ? 1 : 0
    })
  }

  renderParticipants() {
    let listItem = []
    listItem = this.state.participants.map(item =>
      <li className="participants-list-item" key={item._id}>
        <span className="participants-name">
          {item.name.includes(' ') ? 
          `${item.name.split(' ')[0]} ${item.name.split(' ')[item.name.split(' ').length - 1]}` :
          `${item.name}`}
        </span>
        <div className="participants-select-arrow">
          <select
            className="participants-select"
            name="select"
            id={item._id}
            ref={input => this[item._id] = input }
            onChange={this.changePower.bind(this)}
          >
            <option value="" className="bold">{this.props.t('i_powers.option')}</option>
            {
              this.state.poderes.map(poder => {
                return (
                    <option 
                      key={poder._id} 
                      value={poder._id}
                      selected={poder._id == item.poderId}
                    >
                      {poder.name}
                    </option>
                )
              })
            }
          </select>
        </div>
      </li>
    )
    return listItem
  }

  async savePoderes(i) {
    // let participantsPower = this.state.participants.filter(participant => participant.poderId != "");
    if(this.state.participants[i].poderId != ''){
      let res = await PowerService.assignPower([this.state.participants[i]]);
      // if (res.success) {
      //   // MySwal.fire({
      //   //   confirmButtonColor: '#87BF40',
      //   //   confirmButtonText: <span>OK</span>,
      //   //   icon: 'success',
      //   //   title: <p>{this.props.t('i_powers.successSave')}</p>
      //   // })
      // }
    }
  }

  async finishPoderes() {
    let withoutPower = this.state.participants.filter(participant => participant.poder === '');
    if (withoutPower.length > 0) {
      MySwal.fire({
        confirmButtonColor: '#87BF40',
        confirmButtonText: <span>OK</span>,
        icon: 'error',
        title: <p>{this.props.t('i_powers.errorAlert')}</p>
      })
    } else {
      // Verificar associação minima de poderes
      let powerMinIvalid = this.state.poderes.filter(power => this.state.qtyMinPowers > power.counter);
      if(powerMinIvalid.length > 0){
        let powersInvalid = powerMinIvalid.map(power => power.name);
        MySwal.fire({
          confirmButtonColor: '#87BF40',
          confirmButtonText: <span>OK</span>,
          icon: 'error',
          title: `<p>${powersInvalid.join(' / ')} ${this.props.t('i_powers.powerMinInvalid')}</p>`
        })
      } else {
        let powerMaxInvalid = this.state.poderes.filter(power => power.counter > this.state.qtyMaxPowers);
        if(powerMaxInvalid.length > 0){
          let powersInvalid = powerMaxInvalid.map(power => power.name);
          MySwal.fire({
            confirmButtonColor: '#87BF40',
            confirmButtonText: <span>OK</span>,
            icon: 'error',
            title: `<p>${powersInvalid.join(' / ')} ${this.props.t('i_powers.powerMaxInvalid')}</p>`
          })
        } else {
          let res = await PowerService.assignPower(this.state.participants);
          if (res.success) {
            const steps = await ClassService.completeStep(this.state.code, this.state.workshop, 'powersCompleted')
            if (steps._id) {
              MySwal.fire({
                confirmButtonColor: '#87BF40',
                confirmButtonText: <span>OK</span>,
                icon: 'success',
                title: <p>{this.props.t('i_powers.successAlert')}</p>
              })
            }
            this.props.history.push(`/oficina-instrutor/${this.state.code}`);
          }
        }
      }
    }
  }

  render() {
    return (
      <Loading fade = { this.state.Loading } >
      <div>
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t('i_powers.title')}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="poderes-instrutor-main">
          <h3 className="poderes-instrutor-class-name">
            {this.state.name}
            <span>{this.props.t('i_powers.code')}: {this.state.code}</span>
          </h3>

          <p className="poderes-instrutor-text">{this.props.t('i_powers.intro')}</p>

          <div className="card poderes-instrutor-quantity">
            <p className="title">{this.props.t('i_powers.cardTitle')}</p>

            <div className="poderes-list">
              <Poder
                poderes={this.state.poderes}
                qtyParticipants={this.state.participants.length}
                qtyMaxPowers={this.state.poderes.length}
                lang={this.state.lang}
                t={this.props.t}
              />
            </div>

            <span className="poderes-details">{this.props.t('i_powers.cardInfo')}</span>
          </div>

          <div className="card poderes-instrutor-participants">
            <div className="participants-header">
              <span>{this.props.t('i_powers.participantList')}</span>
              <span>{this.props.t('i_powers.powerList')}</span>
            </div>

            <ul>
              {this.renderParticipants()}
            </ul>
          </div>
          <a href={`/turmas-consult/${this.state.code}`} className="poderes-info-turma button-full">
            {this.props.t('i_FinishedClasses.btnInfo')}
          </a>
          <button
            className="poderes-instrutor-finish button-full"
            onClick={() => this.finishPoderes()}
          >
            {this.props.t('i_powers.btnFinish')}
          </button>
        </main>
      </div>
      </Loading>
    );
  }
}
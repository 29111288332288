import { showAlert } from "../../../../../utils/showAlert";

export const nationalities = {
    'br': 'brasileiro',
    'other': 'outra'
}

export function validateCPFOrPassport(e){
    const cpf = e.target.value;

    if(this.state.nationality == nationalities.other) return

    if (cpf.length != 11 && cpf.length != 0) {
        const message = `${this.props.t("i_registration.registerData.cpfAlert")}`;
        showAlert(
            `${this.props.t("i_CreateClass.btnBack")}`,
            "error",
            message,
            "#",
            this
        );
    }

}
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const requiredFields = ['name', 'type', 'country', 'zipcode']

export async function validationForm() {
  let data = {};

  let stateKeysName = Object.keys(this.state);
  let errorsFound = [];

  stateKeysName.forEach(item => {
    if(!requiredFields.includes(item)) return

    if (this.state[item] == '' || !this.state[item]) {
      errorsFound.push("stringEmpty");
    }
  });

  if(!this.state.zipcodeChecked) {
    this.showAlert(
      `${this.props.t("i_CreateClass.btnBack")}`,
      "error",
      `${this.props.t("i_registration.instructorCepAlert")}`,
      "#"
    );

    return
  }

  switch (errorsFound[0]) {
    case "stringEmpty":
      showFormError(this.props, this.props.t("i_registration.fieldsAlert"))
      break;
    default:
      MySwal.fire({
        allowOutsideClick: false,
        buttonsStyling: false,
        cancelButtonText: `${this.props.t("i_registration.btnBack")}`,
        cancelButtonColor: "#87BF40",
        confirmButtonColor: "#87BF40",
        confirmButtonText: (
          <a href="javascript:void(0)">
            {this.props.t("i_registration.btnNextPage")}
          </a>
        ),
        customClass: {
          confirmButton: "confirm-button-class",
          cancelButton: "cancel-button-class",
        },
        icon: "warning",
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        title: `${this.props.t("i_registration.finishInsitution")}`,
      }).then((result) => {
        if (result.value) {
          data = {
            institution: {
              name: this.state.name,
              type: this.state.type,
              website: this.state.website,
              phone: this.state.phone,
              address: {
                ...this.state,
                state: this.state.estate,
              },
            },
          };
          this.props.getInfo("informacoesAdicionais", data);
        }
      });
      break;
  }
}

const showFormError = (props, title) => {
  MySwal.fire({
    confirmButtonColor: "#87BF40",
    confirmButtonText: (
          <span>{props.t("i_registration.btnBack")}</span>
        ),
    icon: "error",
    title: <p>{title}</p>,
  });
}
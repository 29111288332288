import React, { Component } from 'react';
import './Content.scss';

import { ReactComponent as IconPerson } from '../../../../../assets/images/participante/person.svg';
import { ReactComponent as CheckMark } from '../../../../../assets/images/participante/check-mark.svg';

class Campanha extends Component {

  constructor(props){
    super(props)
    this.state = {
      lastWorkshop: this.props.current
    }
  }

  setStep = step => {
    this.props.handleClick(step);
  }
 
  render(){
    return(
      <div className="campanha-instrutor-timeline">
        <h4>{this.props.t('p_workshop.campaignProgress')}</h4>

        <div className="campanha-instrutor-timeline-progress">
          <IconPerson />
          <div>
            {
              this.props.campaign.map((step, index) =>
                [
                  <span className={`timeline-list-item-p ${this.props.current == index ? 'active' : 
                  this.state.lastWorkshop < index ? 'disabled': ''}`}
                  onClick={() => this.setStep(index)}>
                    { index + 1 }
                  </span>,
                  <span className="timeline-list-item-divider"></span>
                ]
              )
            }
          </div>
          <CheckMark />
        </div>
      </div>
    );
  }
}

export default Campanha;
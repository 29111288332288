import React, { Component } from 'react';
import './ModalMissoes.scss';

class ModalMissoes extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      missionSelected: '',
      missionContent: [
        { id: 'content1', step: `${this.props.t('i_missions.modal.toDo')}`, content: '' },
        { id: 'content2', step: `${this.props.t('i_missions.modal.result')}`, content: '' },
        { id: 'content3', step: `${this.props.t('i_missions.modal.tip')}`, content: '' }
      ]
    }   
  }

  async componentDidUpdate(prevProps) {
    // Se this.props.missions mudar e for a ultima oficina, apresenta o conteúdo da Missão Coletiva.
    if (prevProps.missions !== this.props.missions) {
      if (this.props.missions.length === 1 && this.props.lastWorkshop) {
        this.missionContent(this.props.missions[0]._id);
      }
    }
  }
  
  async closeModal() {
    this.props.storeMission(this.props.index, this.state.missionSelected);

    const missionContent = [
      { id: 'content1', step: `${this.props.t('i_missions.modal.toDo')}`, content: '' },
      { id: 'content2', step: `${this.props.t('i_missions.modal.result')}`, content: '' },
      { id: 'content3', step: `${this.props.t('i_missions.modal.tip')}`, content: '' }
    ]

    await this.setState({
      missionSelected: '',
      missionContent: missionContent
    })

    let modal = document.querySelector('.missoes-instrutor-modal')
    modal.style.display = 'none';
  }

  changeMission = async (e) => {
    const id = e.target.value;
    await this.missionContent(id);
  }

  async missionContent(id) {
    let missionContent = [];
    if (id == '') {
      missionContent = [
        { id: 'content1', step: `${this.props.t('i_missions.modal.toDo')}`, content: '' },
        { id: 'content2', step: `${this.props.t('i_missions.modal.result')}`, content: '' },
        { id: 'content3', step: `${this.props.t('i_missions.modal.tip')}`, content: '' }
      ]
    } else {
      const missionSelected = this.props.missions.filter(m => m._id == id)[0]
      missionContent = [
        { id: 'content1', step: `${this.props.t('i_missions.modal.toDo')}`, content: missionSelected.toDo },
        { id: 'content2', step: `${this.props.t('i_missions.modal.result')}`, content: missionSelected.result },
        { id: 'content3', step: `${this.props.t('i_missions.modal.tip')}`, content: missionSelected.tip }
      ];
    }
    await this.setState({
      missionSelected: id,
      missionContent: missionContent
    })
  }

  renderMissions() {
    let options = [];

    // Desabilitando missão que já foi atribuída anteriormente ao participante
    for (let i = 0; i < this.props.missions.length; i++) {
      if (this.props.missions[i].given) {
        options.push(<option value={this.props.missions[i]._id} key={i} disabled>{this.props.missions[i].name}</option>)
      } else {
        options.push(<option value={this.props.missions[i]._id}key={i}>{this.props.missions[i].name}</option>)
      }
    }
    return options;
  }

  renderMissionContent() {
    let contentList = []

    contentList = this.state.missionContent.map((item, index) => {
      return (
        <div className="modal-card" key={index}>
          <input id={item.id} type="checkbox" />

          <label htmlFor={item.id}>
            <span className="modal-card-title">
              <h3>{item.step}</h3>
              <span></span>
            </span>
          </label>

          <div className="modal-card-text">
            {item.content}
          </div>
        </div>
      )
    })

    return contentList
  }

  render() {
    return (
      <div className="modal missoes-instrutor-modal">
        <div className="modal-content">
          {!this.props.lastWorkshop && 
            <>
              <h2 className="modal-title">{this.props.t('i_missions.modal.title')}</h2>
              <p
                className="modal-participant-name">
                {this.props.t('i_missions.modal.to')}:
                <span> {this.props.participant.name} / {this.props.participant.poder}</span>
              </p>
              <label className="modal-label" htmlFor="missao">{this.props.t('i_missions.modal.mission')}</label>
              <div className="modal-select-arrow">
                <select
                  className="modal-select"
                  name="select"
                  onChange={this.changeMission.bind(this)}
                > 
                  <option value= ''>{this.props.t('i_missions.modal.select')}</option>
                  {this.renderMissions()}
                </select>
              </div>
              <h3 className="modal-subtitle">{this.props.t('i_missions.modal.content')}</h3>
              <p className="modal-info-text">{this.props.t('i_missions.modal.info')}</p>
            </>
          }
          {this.props.lastWorkshop &&
            <>
              <h2 className="modal-title">{this.props.t('i_missions.modal.titleCollectiveMission')}</h2>
              <p 
                className="modal-participant-name">
                <span>{this.props.t('i_missions.modal.forAllParticipants')}</span>
                <h3 className="modal-subtitle">{this.props.t('i_missions.modal.content')}</h3>
              </p>
            </>
          }
        
          {this.renderMissionContent()}

          <span
            className="modal-close-button"
            onClick={() => this.closeModal()}
          >
            {this.props.t('i_missions.modal.btnBack')}
          </span>
        </div>
      </div>
    )
  }
}
export default ModalMissoes
import { useContext } from "react"
import getTranslation from "../../../utils/getTranslation"
import Header from "../../Header/Header"
import PageTitle from "../../PageTitle/PageTitle"
import MenuItem from './components/MenuItem'
import { ContextAuth } from "../../../context/authContext"
import { navigate } from "../../../services/navigation.service"

import "../Missoes/Missoes.scss"

const MissionsMenuPage = (props) => {
    const context = useContext(ContextAuth)

    const transalations = {
        title: getTranslation('p_missions.menu_page.title'),
        subtitle: getTranslation('p_missions.menu_page.intro'),
        completedMissions: {
            title: getTranslation('p_missions.menu_page.completed_missions.title'),
            subtitle: getTranslation('p_missions.menu_page.completed_missions.subtitle'),
        },
        inProgressMissions: {
            title: getTranslation('p_missions.menu_page.in_progress_missions.title'),
            subtitle: getTranslation('p_missions.menu_page.in_progress_missions.subtitle'),
        }
    }
    
    return (
        <div>
            <Header />
            <PageTitle title={transalations.title} />
            <main className="list-missao-main">
                <p className="list-missao-text">
                    {transalations.subtitle}
                </p>
                <MenuItem
                    title={transalations.completedMissions.title}
                    subtitle={transalations.completedMissions.subtitle}
                    onClick={() => navigate.call({ context, props }, { link: '/missoes-concluidas' })}
                />
                <MenuItem
                    title={transalations.inProgressMissions.title}
                    subtitle={transalations.inProgressMissions.subtitle}
                    onClick={() => navigate.call({ context, props }, { link: '/missoes-pendentes' })}
                />
            </main>
        </div>
    )
}

export default MissionsMenuPage
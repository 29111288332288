import React, { Component } from 'react';
import Header from '../../Header/Header';

import './Dashboard.scss';

import LocalStorage from '../../../services/localStorage.service';
import ClassService from '../../../services/class.service';
import { ContextAuth } from '../../../context/authContext';

export default class Dashboard extends Component {
  static contextType = ContextAuth;

  constructor(props) {
    super(props)
    this.state = {
      finishedClasses: '',
      progressClasses: '',
      totalParticipants: ''
    }
  }

  componentWillMount = async () => {
    const instrutor = this.context.state.userData;

    if (instrutor._id) {
      const classes = await ClassService.classesByIntructor(instrutor._id);
      const finishedClasses = await ClassService.classCompletedByInstructor(instrutor._id);

      // Quantidade total de participantes
      let codigos = []
      codigos = classes.map(c => {
        return c.code
      })

      let totalParticipants = 0

      for(var i = 0; i < codigos.length; i++){
        const participants = await ClassService.participantsByClass(codigos[i]);
        totalParticipants += participants.length
      }

      // Turmas
      let finishedIds = []
      let classesStarted = []

      // Turmas concluídas
      finishedIds = finishedClasses.map(f => {
        return f._id
      })

      // Turmas em andamento
      classes.forEach(c => {
        if (!finishedIds.includes(c._id)) {
          classesStarted.push(c)
        }
      })

      await this.setState({
        finishedClasses: finishedClasses.length,
        progressClasses: classesStarted.length,
        totalParticipants: totalParticipants
      })
    }
  }

  render() {
    return (
      <div>
        <Header />

        <div className="page-title-header">
          <h1 className="page-title">{this.props.t('i_dashboard.title')}</h1>
          <span className="page-title-border"></span>
        </div>

        <main className="dashboard-main">
          <div className="card dashboard-card">
            <span className="number">
              { this.state.progressClasses }
            </span>
            {this.props.t('i_dashboard.progressClasses')}
          </div>

          <div className="card dashboard-card">
            <span className="number">
              { this.state.finishedClasses }
            </span>
            {this.props.t('i_dashboard.finishedClasses')}
          </div>

          <div className="card dashboard-card">
            <span className="number">
              { this.state.totalParticipants }
            </span>
            {this.props.t('i_dashboard.totalParticipantes')}
          </div>
        </main>
      </div>
    );
  }
}
import requests from '../agent';

const endpoint = '/classes';

const ClassService = {
    getAll: async () => {
        return await requests.get(`${endpoint}/`);
    },
    classExist: async (code) => {
        return await requests.get(`${endpoint}/${code}`);
    },
    getById: async (id) => {
        return await requests.get(`${endpoint}/id/${id}`);
    },
    getClassesWithoutPowersOrMissions: async (id) => {
        return await requests.get(`${endpoint}/${id}/withoutpowersormissions`);
    },
    getByCode: async (code) => {
        return await requests.get(`${endpoint}/${code}`);
    },
    create: async (obj) => {
        return await requests.post(endpoint, obj);
    },
    update: async (code, classEdit) => {
        return await requests.put(`${endpoint}/${code}`, classEdit)
    },
    deleteByCode: async (code) => {
        return await requests.del(`${endpoint}/${code}`)
    },
    classesByIntructor: async (instructor) => {
        return await requests.get(`/instructors/${instructor}/classes`);
    },
    classCompletedByInstructor: async (instructor) => {
        return await requests.get(`/instructors/${instructor}/classes/completed`);
    },
    classByParticipant: async (participant) => {
        return await requests.get(`/participants/${participant}/class`);
    },
    participantsByClass: async (code) => {
        return await requests.get(`${endpoint}/${code}/participants`);
    },
    fillPresence: async (code, presenceList) => {
        return await requests.post(`${endpoint}/${code}/presence`, presenceList);
    },
    completeClass: async (classComplete) => {
        return await requests.post(`${endpoint}/complete`, classComplete);
    },
    completeStep: async (code, workshop, step) => {
        return await requests.post(`${endpoint}/${code}/stepcomplete`, { workshop, step });
    },
    approveSubmitedMission: async(code, workshopId, submissions) => await requests.post(`${endpoint}/${code}/workshops/${workshopId}/approve-submited-mission`, { submissions }),
    submitPresenceList: {
        create: async (data, classId) => {
          const config = {
            headers: {
              "content-type": "multipart/form-data",
            },
          };
          return await requests.post(
            `${endpoint}/${classId}/submit-presence-list`,
            data,
            config
          );
        },
    },
};

export default ClassService;
import React from "react";
import "moment/locale/pt-br";
import "../CertificadoParticipant.scss";
import logoIfa from "../../../../../assets/images/instrutor/logo-ifa.png";
import assinatura from "../../../../../assets/images/instrutor/assinatura.png";
import { languageMomentPreposition } from "../utils/languageToMomentLocale";

const ParticipantCertification = (props) => {
  const certificationTextByWorkshopType = {
    "workshop": [
      props.t("i_certifiedParticipant.cTextWorkshop01"), 
      props.t("i_certifiedParticipant.cTextWorkshop02"),
      props.t("i_certifiedParticipant.cTextCampaignAndWorkshop04"),
      props.t("i_certifiedParticipant.cTextWorkshop03")
    ],
    "campaign": [
      props.t("i_certifiedParticipant.cTextCampaign"),
      props.t("i_certifiedParticipant.cTextCampaign02"),
      props.t("i_certifiedParticipant.cTextCampaignAndWorkshop04"),
      props.t("i_certifiedParticipant.cTextCampaign03")
    ],
    "campaignLevel1": [
      props.t("i_certifiedParticipant.cTextCampaignLevel1"),
      props.t("i_certifiedParticipant.cTextCampaign02"),
      props.t("i_certifiedParticipant.cTextCampaignAndWorkshop04"),
      props.t("i_certifiedParticipant.cTextCampaign03")
    ],
    "default": [
      props.t("i_certifiedParticipant.cTextCampaignLevel2"),
      props.t("i_certifiedParticipant.cTextCampaign02"),
      props.t("i_certifiedParticipant.cTextCampaignAndWorkshop04"),
      props.t("i_certifiedParticipant.cTextCampaign03")
    ]
  }
  let { type } = props.currentClass;
  type = certificationTextByWorkshopType.hasOwnProperty(type) ? type : "default"

    return (
        <div key={props.item._id}>
          <main>
            <div
              id="certificado"
              className="card certificado-treinamento-card"
            >
              <img src={logoIfa} className="logo-image" alt="Logo IFA" />
              <h4 className="certificado-title">
                {props.t("i_certifiedParticipant.cTitle")}
              </h4>
              <p className="certificado-text">
                <span className="intro">
                  {props.t("i_certifiedParticipant.cCertify")}
                </span>
                <span className="name">{props.item.name}</span>
                  <span className="text">
                    {certificationTextByWorkshopType[type][0]}
                    <b>{certificationTextByWorkshopType[type][1]}</b>
                    {certificationTextByWorkshopType[type][2]}
                    {certificationTextByWorkshopType[type][3]}
                  </span>
                <span className="text">
                  {props.momentDate.format("MMMM") +
                    languageMomentPreposition +
                    props.momentDate.format("YYYY") +
                    "."}
                </span>
              </p>

              <p className="certificado-date">São Paulo, {props.date}.</p>

              <img
                src={assinatura}
                className="assinatura-image"
                alt="Assinatura José Dornelas"
              />

              <p className="certificado-legenda">
                {props.t("i_certifiedParticipant.cSignature")}
              </p>
            </div>
          </main>
        </div>
      );
}

export default ParticipantCertification;
import requests from '../agent';

const endpoint = '/workshops';

const WorkshopService = {
    getAll: async () => {
        return await requests.get(`${endpoint}/active`);
    },
    getById: async (id) => {
        return await requests.get(`${endpoint}/${id}`);
    }
};

export default WorkshopService;


import { navigate } from "../../../../../services/navigation.service";

const goToTraining = function(training) {
    this.concludeTrainingWithNoQuizz(training._id);
    const navigator = navigate.bind(this);

    navigator({
      link: `/conteudo-instrutor/${training._id}`,
    });
}

export default goToTraining
import React, { Component } from 'react';
import './List.scss'

import OficinaImage from '../../../../assets/images/oficinas/oficina-image.png';
import { ReactComponent as IconPerson } from '../../../../assets/images/participante/person.svg';
import { ReactComponent as CheckMark } from '../../../../assets/images/participante/check-mark.svg';

export default class Campanha extends Component {
  render(){
    return(
      <div>
        <a className="list-oficinas-campanha-card" href="/oficina-participante">
          <img className="list-oficinas-campanha-image campanha" src={OficinaImage} alt="" />

          <div className="list-campanha-content">
            <h4 className="content-title">MINHA PRÓPRIA CASA</h4>
            <span className="content-status">Tipo de oficina: Campanha</span>

            <span className="list-campanha-divider"></span>

            <span className="content-status">Progresso da campanha</span>

            <div className="campanha-participante-timeline-progress">
              <IconPerson />
              <div>
                <span className="timeline-list-item active">1</span>
                <span className="timeline-list-item-divider"></span>
                <span className="timeline-list-item">2</span>
                <span className="timeline-list-item-divider"></span>
                <span className="timeline-list-item">3</span>
                <span className="timeline-list-item-divider"></span>
                <span className="timeline-list-item">4</span>
                <span className="timeline-list-item-divider"></span>
                <span className="timeline-list-item">5</span>
              </div>
              <CheckMark />
            </div>

          </div>
        </a>
      </div>
    );
  }
}
import { Component } from "react";
import "./ListaDeImagens.scss";

import CardImagem from "../CardImagem/CardImagem";
import AdicionarImagem from "../AdicionarImagem/AdicionarImagem";
import LocalStorage from "../../../../../services/localStorage.service";
import SendMissionService from "../../../../../services/sendmission.service";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default class ListaDeImagens extends Component {
  constructor(props) {
    super(props);
    this._novasImagens = this._novasImagens.bind(this);
    this.lang = LocalStorage.retrieve("language");
    this.participantsWithMissionsSafe = this.props.participantsWithMissionsSafe;


    this.state = { imagens: this.props.imagens.imagens, participants: this.props.participantsWithMissions, sendButton: false };
  }

  sendMissionModalResult(result) {
    let MySwal = withReactContent(Swal);

    if (result === "sucess") {
      MySwal.fire({
        title: this.props.t("i_ListImages.sendSucessTitle"),
        html: this.props.t("i_ListImages.sendSucessText"),
        icon: "success",
        showCloseButton: true,
        showConfirmButton: false,
        focusConfirm: false,
      });
    } else {
      MySwal.fire({
        title: this.props.t("i_ListImages.sendErrorTitle"),
        html: this.props.t("i_ListImages.sendErrorText"),
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        focusConfirm: false,
      });
    }

  }

  sendButton(condition) {
    this.setState({ sendButton: condition });
  }

  async _handleSendButton() {
    let MySwal = withReactContent(Swal);

    if (this.state.sendButton === true) {
      MySwal.fire({
        html: this.props.t("i_ListImages.sendLoading"),
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          MySwal.showLoading();
        }
      });
      try {
        await this.sendingMissionsImages();
        MySwal.close();

        this.sendMissionModalResult("sucess")
      } catch (error) {
        this.sendMissionModalResult("error")
      }

      this.props.reloadImages();
      this.sendButton(false);

    } else {
      this.sendMissionModalResult("error")
    }
  }

  async sendingMissionsImages() {

    for (var i = 0; i < this.state.imagens.length; i++) {
      const image = this.state.imagens[i];
      if (!image.url) {
        const data = new FormData();

        data.append("file",
          image.file,
          image.file.name,
        )
        data.append('classCode', image.classCode)
        data.append('instructor', image.instructor)
        data.append('workshopName', image.workshop[this.lang].name)
        data.append('workshopId', image.workshop._id)
        data.append('missionName', image.participante.mission[this.lang].name)
        data.append('missionId', image.participante.mission._id)
        data.append('participantName', image.participante.name)
        data.append('participantId', image.participante._id)
        data.append('dateTime', image.dateTime)

        await SendMissionService.create(data);
      }
    }

  }

  async apagarImagem(indice, participantName) {
    function filtrarParticipantePeloNome(p) {
      if (p.participant.name === participantName) return p;
    }

    var participant = this.props.participantsWithMissionsSafe.filter(filtrarParticipantePeloNome);
    let participants = this.state.participants;

    participants.push(participant[0])
    participants = participants.sort((a, b) => (a.participant.name > b.participant.name) ? 1 : -1)

    this.setState({ participants: participants })

    this.sendButton(false);

    this.props.apagarImagem(indice);

    this.props.reloadImages();
  }

  removerParticipanteSelecionado(participantId) {
    function filtrarParticipantePeloId(p) {
      if (p.participant._id !== participantId) return p;
    }

    let participantsFiltered = this.state.participants.filter(filtrarParticipantePeloId);
    this.setState({ participants: participantsFiltered })

    return participantsFiltered;
  }

  _novasImagens(imagens) {
    this.setState({ ...this.state, imagens });
  }

  componentDidMount() {
    this.props.imagens.inscrever(this._novasImagens);
  }

  componentWillUnmount() {
    this.props.imagens.desinscrever(this._novasImagens);
  }

  componentDidUpdate(prevProps) {
    if (this.props.wkIndex !== prevProps.wkIndex || this.props.participantsWithMissions !== prevProps.participantsWithMissions) {
      this.setState({
        participants: this.props.participantsWithMissions,
        imagens: this.props.imagens.imagens,
        sendButton: false
      })
    }

    if (this.state.sendButton === true) {
      document.getElementById("sendButton").style.background = "#87BF40";
    } else {
      document.getElementById("sendButton").style.background = "rgba(195, 195, 195, 0.698)";
    }
  }

  renderImagesText() {
    if (this.state.imagens.length === 0) {
      return (<p>
        {this.props.t("i_ListImages.sendTextImagesLength")}
      </p>)
    }
    if(this.state.imagens.length === 1) {
      return (<p>
        {this.props.t("i_ListImages.sendTextImagesLengthStart")}
      </p>)
    }
    if (this.state.imagens.length > 0 && this.state.imagens.length < 10) {
      return (<p>
        {this.props.t("i_ListImages.sendTextImagesLengthV1")} {"0" + this.state.imagens.length} {this.props.t("i_ListImages.sendTextImagesLengthV2")} {"0" + (10 - this.state.imagens.length)} {this.props.t("i_ListImages.sendTextImagesLengthV3")}.
      </p>)
    }
    if(this.state.imagens.length === 1) {
      return (<p>
        {this.props.t("i_ListImages.sendTextImagesLengthFinal")}
      </p>)
    }
    if (this.state.imagens.length === 10) {
      return (
        <p>{this.props.t("i_ListImages.sendTextImagesLengthConclued")}</p>
      )
    }
  }

  render() {
    return (
      <section className="listaDeImagens_section">
        <div className="listaDeImagens_div">
          <p>
            {this.props.t("i_ListImages.title")}
            <span
              id="sendButton"
              onClick={this._handleSendButton.bind(this)}
              className="listaDeImagens_sendButton"
            >
              <button
                id="sendButton_button"
                ref={(button) => (this.button = button)}
              >
                {this.props.t("i_ListImages.btnSend")}
              </button>
            </span>
          </p>
          <div>
            {this.renderImagesText()}
          </div>
        </div>
        <div className="listaDeImagens_fotos">
          <ul className="listaDeImagens_ul">
            {this.state.imagens.map((imagem, index) => {
              if (!imagem.url) {
                imagem.missionName = imagem.participante.mission[this.lang].name;
                imagem.participantName = imagem.participante.name;
              }
              return (
                <li className="listaDeImagens-item" key={index}>
                  <CardImagem
                    t={this.props.t}
                    workshopsParticipants={this.state.participants}
                    code={this.props.classCode}
                    indice={index}
                    missionName={imagem.missionName}
                    participantName={imagem.participantName}
                    imageDriveId={imagem.imageDriveId}
                    dateTime={imagem.dateTime}
                    url={imagem.url}
                    imagem={imagem.imagem}
                    apagarImagem={this.apagarImagem.bind(this)}
                  />
                </li>
              );
            })}
            {this.state.imagens.length < 10 && this.state.participants.length !== 0 && (
              <li>
                <AdicionarImagem
                  t={this.props.t}
                  wkIndex={this.props.wkIndex}
                  sendButton={this.sendButton.bind(this)}
                  workshopsParticipants={this.state.participants}
                  workshopId={this.props.workshopId}
                  workshops={this.props.workshops}
                  code={this.props.classCode}
                  dateTime={this.props.dateTime}
                  instructor={this.props.instructor}
                  adicionarImagem={this.props.adicionarImagem}
                  removerParticipanteSelecionado={this.removerParticipanteSelecionado.bind(this)}
                />
              </li>
            )}
          </ul>
        </div>
      </section>
    );
  }
}

import { ReactComponent as StarIcon } from '../../../../../../assets/images/instrutor/icon-star.svg';
import { ReactComponent as IfaIcon } from '../../../../../../assets/images/logo/icone-ifa.svg';
import getTranslation from '../../../../../../utils/getTranslation';

const StarsCard = () => {
    return (
        <>
            <p className="estrelas-instrutor-text">{getTranslation('i_stars.intro')}</p>

            <div className="card estrelas-instrutor-legend">
                <h3>{getTranslation('i_stars.subtitle')}</h3>
                <span>
                    <StarIcon className="star half" /> = <IfaIcon className="ifa" /> x5
                </span>
                <span>
                    <StarIcon className="star full" /> = <IfaIcon className="ifa" /> x10
                </span>
            </div>
        </>
    )
}

export default StarsCard
import React, { Component } from "react";
import moment from "moment";
import "./ConfirmacaoDados.scss";

import InstructorService from "../../../../services/instructor.service";
import LocalStorage from "../../../../services/localStorage.service";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ContextAuth } from "../../../../context/authContext";
const MySwal = withReactContent(Swal);

// TODO: Verificar função de recuperar dados de cadastro não concluído
class ConfirmacaoDados extends Component {
  static contextType = ContextAuth;

  constructor(props) {
    super(props);
  }

  componentWillMount = async () => {
    await this.setState({ ...this.props.dados });
  };

  async alertConfirm(e) {
    e.preventDefault();

    try {
      const instructor = this.buildInstructor(this.props.dados);

      LocalStorage.remove("cacheInstructor");

      let {
        instructor: instructorData,
        expirationDate,
        token,
        success,
      } = await InstructorService.create(instructor);

      if (success && token) {
        //clear cache instructor
        LocalStorage.remove("informacoesAcesso");
        LocalStorage.remove("dadosCadastrais");
        LocalStorage.remove("endereco");
        LocalStorage.remove("instituicao");
        LocalStorage.remove("informacaoAdicional");
        LocalStorage.remove("cacheInstructor");

        
        LocalStorage.persist("isLicenseWarnClosed", false)

        /// save instructor data on context
        this.context.setContext({
          token,
          tokenExp: expirationDate,
          role: "instructor",
          userData: instructorData,
        });

        // No momento em que o contexto é atualizado, o instrutor é automaticamente direcionado para a página "/welcome"

        MySwal.fire({
          allowOutsideClick: false,
          buttonsStyling: false,
          confirmButtonColor: "#87BF40",
          confirmButtonText: (<a href="javascript:void(0)">{this.props.t("i_registration.confirm.entryButton")}</a>),
          customClass: {
            confirmButton: "confirm-button-class",
          },
          icon: "success",
          reverseButtons: true,
          title: `${this.props.t("i_registration.confirm.successRegistered")}`,
          width: "32rem",
          text: `${this.props.t("i_registration.confirm.licenseInformations")}`
        })

      }
    } catch (err) {
      console.log(err);
      MySwal.fire({
        confirmButtonColor: "#87BF40",
        confirmButtonText: (
          <button>{this.props.t("i_registration.confirm.btnError")}</button>
        ),
        icon: "error",
        title: <p>{this.props.t("i_registration.confirm.errorMessage")}</p>,
      });
    }

    this.props.getInfo("conslusion", {});
  }

  buildInstructor(dados) {
    let instructor = {
      ...dados.informacoesAcesso,
      ...dados.dadosCadastrais,
      ...dados.informacaoAdicional,
      ...dados.endereco,
      ...dados.instituicao,
    };
    return instructor;
  }

  backOperation = () => {
    LocalStorage.persist("backOperation", "true");
    LocalStorage.persist("backNav", "informacoesAdicionais");
  };

  render() {
    return (
      <div className="cadastro-instrutor-confirmacao">
        <h2 className="cadastro-instrutor-main-title confirmacao-title">
          {this.props.t("i_registration.confirm.subtitle")}
        </h2>

        <div className="card cadastro-instrutor-card">
          <div className="cadastro-confirmacao-header">
            <h3 className="cadastro-confirmacao-title">
              {this.props.t("i_registration.access.subtitle")}
            </h3>
            {/* <button className="cadastro-confirmacao-edit">editar</button> */}
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.access.username")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.informacoesAcesso.username}
            </span>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.access.password")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">*********</span>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.access.email")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.informacoesAcesso.email}
            </span>
          </div>
        </div>

        <div className="card cadastro-instrutor-card">
          <div className="cadastro-confirmacao-header">
            <h3 className="cadastro-confirmacao-title">
              {this.props.t("i_registration.registerData.subtitle")}
            </h3>
            {/* <button className="cadastro-confirmacao-edit">editar</button> */}
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.registerData.fullname")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.dadosCadastrais.name}
            </span>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.registerData.date")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {moment(this.state.dadosCadastrais.born).format("L")}
            </span>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.registerData.nationality")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.dadosCadastrais.nationality == "brasileiro"
                ? this.props.t("i_registration.registerData.brazilian")
                : this.props.t("i_registration.registerData.other")}
            </span>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.registerData.rg")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.dadosCadastrais.rg}
            </span>

            <div className="right-values">
              <label className="cadastro-confirmacao-label">
                {this.state.dadosCadastrais.nationality == "brasileiro"
                  ? this.props.t("i_registration.registerData.cpf")
                  : this.props.t("i_registration.registerData.passport")}
                :{" "}
              </label>
              <span className="cadastro-confirmacao-values">
                {this.state.dadosCadastrais.cpf}
              </span>
            </div>
          </div>
        </div>

        <div className="card cadastro-instrutor-card">
          <div className="cadastro-confirmacao-header">
            <h3 className="cadastro-confirmacao-title">
              {this.props.t("i_registration.address.subtitle")}
            </h3>
            {/* <button className="cadastro-confirmacao-edit">editar</button> */}
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.address.country")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.endereco.address.country}
            </span>

            <div className="right-values">
              <label className="cadastro-confirmacao-label">
                {this.state.endereco.address.country == "Brasil"
                  ? this.props.t("i_registration.address.cep")
                  : this.props.t("i_registration.address.zipcode")}
                :{" "}
              </label>
              <span className="cadastro-confirmacao-values">
                {this.state.endereco.address.zipcode}
              </span>
            </div>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.address.address")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.endereco.address.street}
            </span>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">Nº: </label>
            <span className="cadastro-confirmacao-values">
              {this.state.endereco.address.number}
            </span>

            <div className="right-values">
              <label className="cadastro-confirmacao-label">
                {this.props.t("i_registration.address.complement")}:{" "}
              </label>
              <span className="cadastro-confirmacao-values">
                {this.state.endereco.address.complement
                  ? this.state.endereco.address.complement
                  : "N/A"}
              </span>
            </div>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.address.state")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.endereco.address.state}
            </span>

            <div className="right-values">
              <label className="cadastro-confirmacao-label">
                {this.props.t("i_registration.address.city")}:{" "}
              </label>
              <span className="cadastro-confirmacao-values">
                {this.state.endereco.address.city}
              </span>
            </div>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.address.neighborhood")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.endereco.address.neighborhood}
            </span>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.address.mobile")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.endereco.cellphone}
            </span>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.address.phone")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.endereco.phone}
            </span>
          </div>
        </div>

        <div className="card cadastro-instrutor-card">
          <div className="cadastro-confirmacao-header">
            <h3 className="cadastro-confirmacao-title">
              {this.props.t("i_registration.institution.subtitle")}
            </h3>
            {/* <button className="cadastro-confirmacao-edit">editar</button> */}
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.institution.name")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.instituicao.institution.name}
            </span>
          </div>
          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.institution.type")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.instituicao.institution.type}
            </span>
          </div>
          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.institution.country")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.instituicao.institution.address.country}
            </span>

            <div className="right-values">
              <label className="cadastro-confirmacao-label">
                {this.state.endereco.address.country == "Brasil"
                  ? this.props.t("i_registration.address.cep")
                  : this.props.t("i_registration.address.zipcode")}
                :{" "}
              </label>
              <span className="cadastro-confirmacao-values">
                {this.state.instituicao.institution.address.zipcode}
              </span>
            </div>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.institution.address")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.instituicao.institution.address.street}
            </span>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">Nº: </label>
            <span className="cadastro-confirmacao-values">
              {this.state.instituicao.institution.address.number}
            </span>

            <div className="right-values">
              <label className="cadastro-confirmacao-label">
                {this.props.t("i_registration.institution.complement")}:{" "}
              </label>
              <span className="cadastro-confirmacao-values">
                {this.state.instituicao.institution.address.complement
                  ? this.state.instituicao.institution.address.complement
                  : "N/A"}
              </span>
            </div>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.institution.state")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.instituicao.institution.address.state}
            </span>

            <div className="right-values">
              <label className="cadastro-confirmacao-label">
                {this.props.t("i_registration.institution.city")}:{" "}
              </label>
              <span className="cadastro-confirmacao-values">
                {this.state.instituicao.institution.address.city}
              </span>
            </div>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.institution.neighborhood")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.instituicao.institution.address.neighborhood}
            </span>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.institution.phone")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.instituicao.institution.phone}
            </span>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.institution.website")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.state.instituicao.institution.website}
            </span>
          </div>
        </div>

        <div className="card cadastro-instrutor-card">
          <div className="cadastro-confirmacao-header">
            <h3 className="cadastro-confirmacao-title">
              {this.props.t("i_registration.additional.subtitle")}
            </h3>
            {/* <button className="cadastro-confirmacao-edit">editar</button> */}
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.additional.teacher")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.props.dados.informacaoAdicional.isTeacher == "true"
                ? `${this.props.t("i_registration.additional.yes")}`
                : `${this.props.t("i_registration.additional.no")}`}
            </span>
          </div>

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.additional.courses")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.props.dados.informacaoAdicional.courses}
            </span>
          </div>

          {/* <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">Como soube a respeito do Instituto Fazendo Acontecer - IFA: </label>
            <span className="cadastro-confirmacao-values">E-mail MKT</span>
          </div> */}

          <div className="cadastro-confirmacao-info-line">
            <label className="cadastro-confirmacao-label">
              {this.props.t("i_registration.additional.information")}:{" "}
            </label>
            <span className="cadastro-confirmacao-values">
              {this.props.dados.informacaoAdicional.receiveMessagesIfa == "true"
                ? `${this.props.t("i_registration.additional.yes")}`
                : `${this.props.t("i_registration.additional.no")}`}
            </span>
          </div>
        </div>

        <div className="cadastro-instrutor-buttons">
          <a
            onClick={() => this.backOperation()}
            className="cadastro-instrutor-back"
            href="/cadastro-instrutor"
          >
            {this.props.t("i_registration.btnBack")}
          </a>
          <button
            className="cadastro-instrutor-next"
            onClick={this.alertConfirm.bind(this)}
          >
            {this.props.t("i_registration.btnFinish")}
          </button>
        </div>
      </div>
    );
  }
}

export default ConfirmacaoDados;

const countries = [ 
    { 
      pais : "Afeganistão",
      country : "Afghanistan",
      sigla : "AF"
    },
    { 
      pais : "África do Sul",
      country : "South Africa",
      sigla : "ZA"
    },
    { 
      pais : "Albânia",
      country : "Albania",
      sigla : "AL"
    },
    { 
      pais : "Alemanha",
      country : "Germany",
      sigla : "DE"
    },
    { 
      pais : "Andorra",
      country : "Andorra",
      sigla : "AD"
    },
    { 
      pais : "Angola",
      country : "Angola",
      sigla : "AO"
    },
    { 
      pais : "Anguilla",
      country : "Anguilla",
      sigla : "AI"
    },
    { 
      pais : "Antártida",
      country : "Antarctica",
      sigla : "AQ"
    },
    { 
      pais : "Antígua e Barbuda",
      country : "Antigua & Barbuda",
      sigla : "AG"
    },
    { 
      pais : "Arábia Saudita",
      country : "Saudi Arabia",
      sigla : "SA"
    },
    { 
      pais : "Argélia",
      country : "Algeria",
      sigla : "DZ"
    },
    { 
      pais : "Argentina",
      country : "Argentina",
      sigla : "AR"
    },
    { 
      pais : "Armênia",
      country : "Armenia",
      sigla : "AM"
    },
    { 
      pais : "Aruba",
      country : "Aruba",
      sigla : "AW"
    },
    { 
      pais : "Austrália",
      country : "Australia",
      sigla : "AU"
    },
    { 
      pais : "Áustria",
      country : "Austria",
      sigla : "AT"
    },
    { 
      pais : "Azerbaijão",
      country : "Azerbaijan",
      sigla : "AZ"
    },
    { 
      pais : "Bahamas",
      country : "Bahamas",
      sigla : "BS"
    },
    { 
      pais : "Bahrein",
      country : "Bahrain",
      sigla : "BH"
    },
    { 
      pais : "Bangladesh",
      country : "Bangladesh",
      sigla : "BD"
    },
    { 
      pais : "Barbados",
      country : "Barbados",
      sigla : "BB"
    },
    { 
      pais : "Belarus",
      country : "Belarus",
      sigla : "BY"
    },
    { 
      pais : "Bélgica",
      country : "Belgium",
      sigla : "BE"
    },
    { 
      pais : "Belize",
      country : "Belize",
      sigla : "BZ"
    },
    { 
      pais : "Benin",
      country : "Benin",
      sigla : "BJ"
    },
    { 
      pais : "Bermudas",
      country : "Bermuda",
      sigla : "BM"
    },
    { 
      pais : "Bolívia",
      country : "Bolivia",
      sigla : "BO"
    },
    { 
      pais : "Bósnia-Herzegóvina",
      country : "Bosnia & Herzegovina",
      sigla : "BA"
    },
    { 
      pais : "Botsuana",
      country : "Botswana",
      sigla : "BW"
    },
    { 
      pais : "Brasil",
      country : "Brazil",
      sigla : "BR"
    },
    { 
      pais : "Brunei",
      country : "Brunei",
      sigla : "BN"
    },
    { 
      pais : "Bulgária",
      country : "Bulgaria",
      sigla : "BG"
    },
    { 
      pais : "Burkina Fasso",
      country : "Burkina Faso",
      sigla : "BF"
    },
    { 
      pais : "Burundi",
      country : "Burundi",
      sigla : "BI"
    },
    { 
      pais : "Butão",
      country : "Bhutan",
      sigla : "BT"
    },
    { 
      pais : "Cabo Verde",
      country : "Cape Verde",
      sigla : "CV"
    },
    { 
      pais : "Camarões",
      country : "Cameroon",
      sigla : "CM"
    },
    { 
      pais : "Camboja",
      country : "Cambodia",
      sigla : "KH"
    },
    { 
      pais : "Canadá",
      country : "Canada",
      sigla : "CA"
    },
    { 
      pais : "Canárias",
      country : "Canary Islands",
      sigla : "IC"
    },
    { 
      pais : "Cazaquistão",
      country : "Kazakhstan",
      sigla : "KZ"
    },
    { 
      pais : "Ceuta e Melilla",
      country : "Ceuta & Melilla",
      sigla : "EA"
    },
    { 
      pais : "Chade",
      country : "Chad",
      sigla : "TD"
    },
    { 
      pais : "Chile",
      country : "Chile",
      sigla : "CL"
    },
    { 
      pais : "China",
      country : "China",
      sigla : "CN"
    },
    { 
      pais : "Chipre",
      country : "Cyprus",
      sigla : "CY"
    },
    { 
      pais : "Cingapura",
      country : "Singapore",
      sigla : "SG"
    },
    { 
      pais : "Colômbia",
      country : "Colombia",
      sigla : "CO"
    },
    { 
      pais : "Comores",
      country : "Comoros",
      sigla : "KM"
    },
    { 
      pais : "Congo",
      country : "Congo (Republic)",
      sigla : "CG"
    },
    { 
      pais : "Coréia do Norte",
      country : "North Korea",
      sigla : "KP"
    },
    { 
      pais : "Coréia do Sul",
      country : "South Korea",
      sigla : "KR"
    },
    { 
      pais : "Costa do Marfim",
      country : "Côte d¿Ivoire",
      sigla : "CI"
    },
    { 
      pais : "Costa Rica",
      country : "Costa Rica",
      sigla : "CR"
    },
    { 
      pais : "Croácia",
      country : "Croatia",
      sigla : "HR"
    },
    { 
      pais : "Cuba",
      country : "Cuba",
      sigla : "CU"
    },
    { 
      pais : "Curaçao",
      country : "Curaçao",
      sigla : "CW"
    },
    { 
      pais : "Diego Garcia",
      country : "Diego Garcia",
      sigla : "DG"
    },
    { 
      pais : "Dinamarca",
      country : "Denmark",
      sigla : "DK"
    },
    { 
      pais : "Djibuti",
      country : "Djibouti",
      sigla : "DJ"
    },
    { 
      pais : "Dominica",
      country : "Dominica",
      sigla : "DM"
    },
    { 
      pais : "Egito",
      country : "Egypt",
      sigla : "EG"
    },
    { 
      pais : "El Salvador",
      country : "El Salvador",
      sigla : "SV"
    },
    { 
      pais : "Emirados Árabes Unidos",
      country : "United Arab Emirates",
      sigla : "AE"
    },
    { 
      pais : "Equador",
      country : "Ecuador",
      sigla : "EC"
    },
    { 
      pais : "Eritréia",
      country : "Eritrea",
      sigla : "ER"
    },
    { 
      pais : "Eslováquia",
      country : "Slovakia",
      sigla : "SK"
    },
    { 
      pais : "Eslovênia",
      country : "Slovenia",
      sigla : "SI"
    },
    { 
      pais : "Espanha",
      country : "Spain",
      sigla : "ES"
    },
    { 
      pais : "Estados Unidos",
      country : "United States",
      sigla : "US"
    },
    { 
      pais : "Estônia",
      country : "Estonia",
      sigla : "EE"
    },
    { 
      pais : "Etiópia",
      country : "Ethiopia",
      sigla : "ET"
    },
    { 
      pais : "Fiji",
      country : "Fiji",
      sigla : "FJ"
    },
    { 
      pais : "Filipinas",
      country : "Philippines",
      sigla : "PH"
    },
    { 
      pais : "Finlândia",
      country : "Finland",
      sigla : "FI"
    },
    { 
      pais : "França",
      country : "France",
      sigla : "FR"
    },
    { 
      pais : "Gabão",
      country : "Gabon",
      sigla : "GA"
    },
    { 
      pais : "Gâmbia",
      country : "Gambia",
      sigla : "GM"
    },
    { 
      pais : "Gana",
      country : "Ghana",
      sigla : "GH"
    },
    { 
      pais : "Geórgia",
      country : "Georgia",
      sigla : "GE"
    },
    { 
      pais : "Gibraltar",
      country : "Gibraltar",
      sigla : "GI"
    },
    { 
      pais : "Grã-Bretanha (Reino Unido, UK)",
      country : "United Kingdom",
      sigla : "GB"
    },
    { 
      pais : "Granada",
      country : "Grenada",
      sigla : "GD"
    },
    { 
      pais : "Grécia",
      country : "Greece",
      sigla : "GR"
    },
    { 
      pais : "Groelândia",
      country : "Greenland",
      sigla : "GL"
    },
    { 
      pais : "Guadalupe",
      country : "Guadeloupe",
      sigla : "GP"
    },
    { 
      pais : "Guam (Território dos Estados Unidos)",
      country : "Guam",
      sigla : "GU"
    },
    { 
      pais : "Guatemala",
      country : "Guatemala",
      sigla : "GT"
    },
    { 
      pais : "Guernsey",
      country : "Guernsey",
      sigla : "GG"
    },
    { 
      pais : "Guiana",
      country : "Guyana",
      sigla : "GY"
    },
    { 
      pais : "Guiana Francesa",
      country : "French Guiana",
      sigla : "GF"
    },
    { 
      pais : "Guiné",
      country : "Guinea",
      sigla : "GN"
    },
    { 
      pais : "Guiné Equatorial",
      country : "Equatorial Guinea",
      sigla : "GQ"
    },
    { 
      pais : "Guiné-Bissau",
      country : "Guinea-Bissau",
      sigla : "GW"
    },
    { 
      pais : "Haiti",
      country : "Haiti",
      sigla : "HT"
    },
    { 
      pais : "Holanda",
      country : "Netherlands",
      sigla : "NL"
    },
    { 
      pais : "Honduras",
      country : "Honduras",
      sigla : "HN"
    },
    { 
      pais : "Hong Kong",
      country : "Hong Kong",
      sigla : "HK"
    },
    { 
      pais : "Hungria",
      country : "Hungary",
      sigla : "HU"
    },
    { 
      pais : "Iêmen",
      country : "Yemen",
      sigla : "YE"
    },
    { 
      pais : "Ilha Bouvet",
      country : "Bouvet Island",
      sigla : "BV"
    },
    { 
      pais : "Ilha de Ascensão",
      country : "Ascension Island",
      sigla : "AC"
    },
    { 
      pais : "Ilha de Clipperton",
      country : "Clipperton Island",
      sigla : "CP"
    },
    { 
      pais : "Ilha de Man",
      country : "Isle of Man",
      sigla : "IM"
    },
    { 
      pais : "Ilha Natal",
      country : "Christmas Island",
      sigla : "CX"
    },
    { 
      pais : "Ilha Pitcairn",
      country : "Pitcairn Islands",
      sigla : "PN"
    },
    { 
      pais : "Ilha Reunião",
      country : "Réunion",
      sigla : "RE"
    },
    { 
      pais : "Ilhas Aland",
      country : "Åland Islands",
      sigla : "AX"
    },
    { 
      pais : "Ilhas Cayman",
      country : "Cayman Islands",
      sigla : "KY"
    },
    { 
      pais : "Ilhas Cocos",
      country : "Cocos (Keeling) Islands",
      sigla : "CC"
    },
    { 
      pais : "Ilhas Cook",
      country : "Cook Islands",
      sigla : "CK"
    },
    { 
      pais : "Ilhas Faroes",
      country : "Faroe Islands",
      sigla : "FO"
    },
    { 
      pais : "Ilhas Geórgia do Sul e Sandwich do Sul",
      country : "South Georgia & South Sandwich Islands",
      sigla : "GS"
    },
    { 
      pais : "Ilhas Heard e McDonald (Território da Austrália)",
      country : "Heard & McDonald Islands",
      sigla : "HM"
    },
    { 
      pais : "Ilhas Malvinas",
      country : "Falkland Islands (Islas Malvinas)",
      sigla : "FK"
    },
    { 
      pais : "Ilhas Marianas do Norte",
      country : "Northern Mariana Islands",
      sigla : "MP"
    },
    { 
      pais : "Ilhas Marshall",
      country : "Marshall Islands",
      sigla : "MH"
    },
    { 
      pais : "Ilhas Menores dos Estados Unidos",
      country : "U.S. Outlying Islands",
      sigla : "UM"
    },
    { 
      pais : "Ilhas Norfolk",
      country : "Norfolk Island",
      sigla : "NF"
    },
    { 
      pais : "Ilhas Salomão",
      country : "Solomon Islands",
      sigla : "SB"
    },
    { 
      pais : "Ilhas Seychelles",
      country : "Seychelles",
      sigla : "SC"
    },
    { 
      pais : "Ilhas Tokelau",
      country : "Tokelau",
      sigla : "TK"
    },
    { 
      pais : "Ilhas Turks e Caicos",
      country : "Turks & Caicos Islands",
      sigla : "TC"
    },
    { 
      pais : "Ilhas Virgens (Estados Unidos)",
      country : "U.S. Virgin Islands",
      sigla : "VI"
    },
    { 
      pais : "Ilhas Virgens (Inglaterra)",
      country : "British Virgin Islands",
      sigla : "VG"
    },
    { 
      pais : "Índia",
      country : "India",
      sigla : "IN"
    },
    { 
      pais : "Indonésia",
      country : "Indonesia",
      sigla : "ID"
    },
    { 
      pais : "Irã",
      country : "Iran",
      sigla : "IR"
    },
    { 
      pais : "Iraque",
      country : "Iraq",
      sigla : "IQ"
    },
    { 
      pais : "Irlanda",
      country : "Ireland",
      sigla : "IE"
    },
    { 
      pais : "Islândia",
      country : "Iceland",
      sigla : "IS"
    },
    { 
      pais : "Israel",
      country : "Israel",
      sigla : "IL"
    },
    { 
      pais : "Itália",
      country : "Italy",
      sigla : "IT"
    },
    { 
      pais : "Jamaica",
      country : "Jamaica",
      sigla : "JM"
    },
    { 
      pais : "Japão",
      country : "Japan",
      sigla : "JP"
    },
    { 
      pais : "Jersey",
      country : "Jersey",
      sigla : "JE"
    },
    { 
      pais : "Jordânia",
      country : "Jordan",
      sigla : "JO"
    },
    { 
      pais : "Kiribati",
      country : "Kiribati",
      sigla : "KI"
    },
    { 
      pais : "Kosovo",
      country : "Kosovo",
      sigla : "XK"
    },
    { 
      pais : "Kuait",
      country : "Kuwait",
      sigla : "KW"
    },
    { 
      pais : "Laos",
      country : "Laos",
      sigla : "LA"
    },
    { 
      pais : "Lesoto",
      country : "Lesotho",
      sigla : "LS"
    },
    { 
      pais : "Letônia",
      country : "Latvia",
      sigla : "LV"
    },
    { 
      pais : "Líbano",
      country : "Lebanon",
      sigla : "LB"
    },
    { 
      pais : "Libéria",
      country : "Liberia",
      sigla : "LR"
    },
    { 
      pais : "Líbia",
      country : "Libya",
      sigla : "LY"
    },
    { 
      pais : "Liechtenstein",
      country : "Liechtenstein",
      sigla : "LI"
    },
    { 
      pais : "Lituânia",
      country : "Lithuania",
      sigla : "LT"
    },
    { 
      pais : "Luxemburgo",
      country : "Luxembourg",
      sigla : "LU"
    },
    { 
      pais : "Macau",
      country : "Macau",
      sigla : "MO"
    },
    { 
      pais : "Macedônia (República Yugoslava)",
      country : "Macedonia (FYROM)",
      sigla : "MK"
    },
    { 
      pais : "Madagascar",
      country : "Madagascar",
      sigla : "MG"
    },
    { 
      pais : "Malásia",
      country : "Malaysia",
      sigla : "MY"
    },
    { 
      pais : "Malaui",
      country : "Malawi",
      sigla : "MW"
    },
    { 
      pais : "Maldivas",
      country : "Maldives",
      sigla : "MV"
    },
    { 
      pais : "Mali",
      country : "Mali",
      sigla : "ML"
    },
    { 
      pais : "Malta",
      country : "Malta",
      sigla : "MT"
    },
    { 
      pais : "Marrocos",
      country : "Morocco",
      sigla : "MA"
    },
    { 
      pais : "Martinica",
      country : "Martinique",
      sigla : "MQ"
    },
    { 
      pais : "Maurício",
      country : "Mauritius",
      sigla : "MU"
    },
    { 
      pais : "Mauritânia",
      country : "Mauritania",
      sigla : "MR"
    },
    { 
      pais : "Mayotte",
      country : "Mayotte",
      sigla : "YT"
    },
    { 
      pais : "México",
      country : "Mexico",
      sigla : "MX"
    },
    { 
      pais : "Micronésia",
      country : "Micronesia",
      sigla : "FM"
    },
    { 
      pais : "Moçambique",
      country : "Mozambique",
      sigla : "MZ"
    },
    { 
      pais : "Moldova",
      country : "Moldova",
      sigla : "MD"
    },
    { 
      pais : "Mônaco",
      country : "Monaco",
      sigla : "MC"
    },
    { 
      pais : "Mongólia",
      country : "Mongolia",
      sigla : "MN"
    },
    { 
      pais : "Montenegro",
      country : "Montenegro",
      sigla : "ME"
    },
    { 
      pais : "Montserrat",
      country : "Montserrat",
      sigla : "MS"
    },
    { 
      pais : "Myanma",
      country : "Myanmar (Burma)",
      sigla : "MM"
    },
    { 
      pais : "Namíbia",
      country : "Namibia",
      sigla : "NA"
    },
    { 
      pais : "Nauru",
      country : "Nauru",
      sigla : "NR"
    },
    { 
      pais : "Nepal",
      country : "Nepal",
      sigla : "NP"
    },
    { 
      pais : "Nicarágua",
      country : "Nicaragua",
      sigla : "NI"
    },
    { 
      pais : "Níger",
      country : "Niger",
      sigla : "NE"
    },
    { 
      pais : "Nigéria",
      country : "Nigeria",
      sigla : "NG"
    },
    { 
      pais : "Niue",
      country : "Niue",
      sigla : "NU"
    },
    { 
      pais : "Noruega",
      country : "Norway",
      sigla : "NO"
    },
    { 
      pais : "Nova Caledônia",
      country : "New Caledonia",
      sigla : "NC"
    },
    { 
      pais : "Nova Zelândia",
      country : "New Zealand",
      sigla : "NZ"
    },
    { 
      pais : "Omã",
      country : "Oman",
      sigla : "OM"
    },
    { 
      pais : "Países Baixos Caribenhos",
      country : "Caribbean Netherlands",
      sigla : "BQ"
    },
    { 
      pais : "Palau",
      country : "Palau",
      sigla : "PW"
    },
    { 
      pais : "Palestina",
      country : "Palestine",
      sigla : "PS"
    },
    { 
      pais : "Panamá",
      country : "Panama",
      sigla : "PA"
    },
    { 
      pais : "Papua-Nova Guiné",
      country : "Papua New Guinea",
      sigla : "PG"
    },
    { 
      pais : "Paquistão",
      country : "Pakistan",
      sigla : "PK"
    },
    { 
      pais : "Paraguai",
      country : "Paraguay",
      sigla : "PY"
    },
    { 
      pais : "Peru",
      country : "Peru",
      sigla : "PE"
    },
    { 
      pais : "Polinésia Francesa",
      country : "French Polynesia",
      sigla : "PF"
    },
    { 
      pais : "Polônia",
      country : "Poland",
      sigla : "PL"
    },
    { 
      pais : "Porto Rico",
      country : "Puerto Rico",
      sigla : "PR"
    },
    { 
      pais : "Portugal",
      country : "Portugal",
      sigla : "PT"
    },
    { 
      pais : "Qatar",
      country : "Qatar",
      sigla : "QA"
    },
    { 
      pais : "Quênia",
      country : "Kenya",
      sigla : "KE"
    },
    { 
      pais : "Quirguistão",
      country : "Kyrgyzstan",
      sigla : "KG"
    },
    { 
      pais : "República Centro-Africana",
      country : "Central African Republic",
      sigla : "CF"
    },
    { 
      pais : "República Democrática do Congo",
      country : "Congo (DRC)",
      sigla : "CD"
    },
    { 
      pais : "República Dominicana",
      country : "Dominican Republic",
      sigla : "DO"
    },
    { 
      pais : "República Tcheca",
      country : "Czech Republic",
      sigla : "CZ"
    },
    { 
      pais : "Romênia",
      country : "Romania",
      sigla : "RO"
    },
    { 
      pais : "Ruanda",
      country : "Rwanda",
      sigla : "RW"
    },
    { 
      pais : "Rússia (antiga URSS) - Federação Russa",
      country : "Russia",
      sigla : "RU"
    },
    { 
      pais : "Saara Ocidental",
      country : "Western Sahara",
      sigla : "EH"
    },
    { 
      pais : "Saint-Pierre e Miquelon",
      country : "St. Pierre & Miquelon",
      sigla : "PM"
    },
    { 
      pais : "Samoa Americana",
      country : "American Samoa",
      sigla : "AS"
    },
    { 
      pais : "Samoa Ocidental",
      country : "Samoa",
      sigla : "WS"
    },
    { 
      pais : "San Marino",
      country : "San Marino",
      sigla : "SM"
    },
    { 
      pais : "Santa Helena",
      country : "St. Helena",
      sigla : "SH"
    },
    { 
      pais : "Santa Lúcia",
      country : "St. Lucia",
      sigla : "LC"
    },
    { 
      pais : "São Bartolomeu",
      country : "St. Barthélemy",
      sigla : "BL"
    },
    { 
      pais : "São Cristóvão e Névis",
      country : "St. Kitts & Nevis",
      sigla : "KN"
    },
    { 
      pais : "São Martim",
      country : "St. Martin",
      sigla : "MF"
    },
    { 
      pais : "São Martinho",
      country : "Sint Maarten",
      sigla : "SX"
    },
    { 
      pais : "São Tomé e Príncipe",
      country : "São Tomé & Príncipe",
      sigla : "ST"
    },
    { 
      pais : "São Vicente e Granadinas",
      country : "St. Vincent & Grenadines",
      sigla : "VC"
    },
    { 
      pais : "Senegal",
      country : "Senegal",
      sigla : "SN"
    },
    { 
      pais : "Serra Leoa",
      country : "Sierra Leone",
      sigla : "SL"
    },
    { 
      pais : "Sérvia",
      country : "Serbia",
      sigla : "RS"
    },
    { 
      pais : "Síria",
      country : "Syria",
      sigla : "SY"
    },
    { 
      pais : "Somália",
      country : "Somalia",
      sigla : "SO"
    },
    { 
      pais : "Sri Lanka",
      country : "Sri Lanka",
      sigla : "LK"
    },
    { 
      pais : "Suazilândia",
      country : "Swaziland",
      sigla : "SZ"
    },
    { 
      pais : "Sudão",
      country : "Sudan",
      sigla : "SD"
    },
    { 
      pais : "Sudão do Sul",
      country : "South Sudan",
      sigla : "SS"
    },
    { 
      pais : "Suécia",
      country : "Sweden",
      sigla : "SE"
    },
    { 
      pais : "Suíça",
      country : "Switzerland",
      sigla : "CH"
    },
    { 
      pais : "Suriname",
      country : "Suriname",
      sigla : "SR"
    },
    { 
      pais : "Svalbard",
      country : "Svalbard & Jan Mayen",
      sigla : "SJ"
    },
    { 
      pais : "Tadjiquistão",
      country : "Tajikistan",
      sigla : "TJ"
    },
    { 
      pais : "Tailândia",
      country : "Thailand",
      sigla : "TH"
    },
    { 
      pais : "Taiwan",
      country : "Taiwan",
      sigla : "TW"
    },
    { 
      pais : "Tanzânia",
      country : "Tanzania",
      sigla : "TZ"
    },
    { 
      pais : "Território Britânico do Oceano índico",
      country : "British Indian Ocean Territory",
      sigla : "IO"
    },
    { 
      pais : "Territórios do Sul da França",
      country : "French Southern Territories",
      sigla : "TF"
    },
    { 
      pais : "Timor-Leste",
      country : "Timor-Leste",
      sigla : "TL"
    },
    { 
      pais : "Togo",
      country : "Togo",
      sigla : "TG"
    },
    { 
      pais : "Tonga",
      country : "Tonga",
      sigla : "TO"
    },
    { 
      pais : "Trinidad e Tobago",
      country : "Trinidad & Tobago",
      sigla : "TT"
    },
    { 
      pais : "Tristão da Cunha",
      country : "Tristan da Cunha",
      sigla : "TA"
    },
    { 
      pais : "Tunísia",
      country : "Tunisia",
      sigla : "TN"
    },
    { 
      pais : "Turcomenistão",
      country : "Turkmenistan",
      sigla : "TM"
    },
    { 
      pais : "Turquia",
      country : "Turkey",
      sigla : "TR"
    },
    { 
      pais : "Tuvalu",
      country : "Tuvalu",
      sigla : "TV"
    },
    { 
      pais : "Ucrânia",
      country : "Ukraine",
      sigla : "UA"
    },
    { 
      pais : "Uganda",
      country : "Uganda",
      sigla : "UG"
    },
    { 
      pais : "Uruguai",
      country : "Uruguay",
      sigla : "UY"
    },
    { 
      pais : "Uzbequistão",
      country : "Uzbekistan",
      sigla : "UZ"
    },
    { 
      pais : "Vanuatu",
      country : "Vanuatu",
      sigla : "VU"
    },
    { 
      pais : "Vaticano",
      country : "Vatican City",
      sigla : "VA"
    },
    { 
      pais : "Venezuela",
      country : "Venezuela",
      sigla : "VE"
    },
    { 
      pais : "Vietnã",
      country : "Vietnam",
      sigla : "VN"
    },
    { 
      pais : "Wallis e Futuna",
      country : "Wallis & Futuna",
      sigla : "WF"
    },
    { 
      pais : "Zâmbia",
      country : "Zambia",
      sigla : "ZM"
    },
    { 
      pais : "Zimbábue",
      country : "Zimbabwe",
      sigla : "ZW"
    }
  ]

export default countries;
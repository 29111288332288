import { navigate } from "../../../../../services/navigation.service";

const FinishButton = (props) => {

    const isTrainingCompleted = props.state.instructor.trainingCompletedAt && props.state.instructor.trainingCompleted.length
    let className = "list-treinamento-finalizar";
    let text = props.t("i_training.btnFinish");
    let event = () => {};

    if (isTrainingCompleted) {
      className += " finalizado";
      text = props.t("i_training.btnHome");
      event = () => {
        const navigator = navigate.bind(props.component);

        navigator({ link: "/home-instrutor" });
      };
    } else if (
        props.state.trainingCompleted.length >= props.state.trainings.length
    ) {
      className += " finalizado";
      event = () => props.saveTrainingCompletedDate();
    }

    return (
      <button className={className} onClick={event}>
        {text}
      </button>
    );
}

export default FinishButton
import requests from '../agent';

const endpoint = '/powers';

const PowerService = {
    getAll: async() => {
        return await requests.get(`${endpoint}/active`);
    },
    assignPower: async (participants) => {
        return await requests.post(`${endpoint}/assign`, participants);
    }
};

export default PowerService;


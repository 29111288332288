import CepService from "../../../../../services/cep.service";

const ERROR_STYLE = "is-invalid";

export async function onChangeInputCEP (e) {
    const cep = e.target.value.replace(/\-/g, "")
    this.setState({ 
        zipcode: cep,
        zipcodeChecked: false,
    })

    if(!isBrazilian(this.state.country)) {
        this.setState({
            zipcodeChecked: true,
            isVerifyLocalizationLoading: false 
        })

        return
    } 

    if(cep.length < 8) return

    const { 
        logradouro,
        bairro,
        localidade,
        uf,
        erro
    } = await CepService.getLocalization(cep);

    if(!erro) {
        this.setState({
            street: logradouro,
            neighborhood: bairro,
            city: localidade,
            estate: uf,
            zipcodeChecked: true,
        });

        this.street?.classList.remove(ERROR_STYLE);
        this.neighborhood?.classList.remove(ERROR_STYLE);
        this.city?.classList.remove(ERROR_STYLE);
        this.estate?.classList.remove(ERROR_STYLE);
    } else {

        this.street?.classList.add(ERROR_STYLE);
        this.neighborhood?.classList.add(ERROR_STYLE);
        this.city?.classList.add(ERROR_STYLE);
        this.estate?.classList.add(ERROR_STYLE);

        this.showAlert(
            `${this.props.t("i_CreateClass.btnBack")}`,
            "error",
            `${this.props.t("i_registration.instructorCepAlert")}`,
            "#"
        );
    }

    this.setState({ isVerifyLocalizationLoading: false })
}

function isBrazilian(country) {
    return country == 'Brazil' || country == 'Brasil' || country == ''
}
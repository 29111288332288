import React, { Component } from "react";

import countries from "../../../../shared/helpers/countries";
import LocalStorage from "../../../../services/localStorage.service";

import { add } from "date-fns";
import { showAlert } from "../../../../utils/showAlert";
import InstitutionNameAndType from "./components/InstitutionNameAndType";
import { validationForm } from "./utils/validateForm";
import { ContextAuth } from '../../../../context/authContext';
import { onChangeInputCEP } from "../EnderecoContato/utils/validations";

const ERROR_STYLE = "is-invalid";

class DadosInstituicao extends Component {
  static contextType = ContextAuth;

  constructor() {
    super();
    this.state = {
      name: "",
      type: "",
      website: "",
      phone: "",
      street: "",
      number: "",
      license: "",
      licenseActive: true,
      licenseExpireIn: "",
      complement: "",
      neighborhood: "",
      city: "",
      estate: "",
      zipcode: "",
      zipcodeChecked: false,
      country: "",
      lang: LocalStorage.retrieve("language"),
      isVerifyLocalizationLoading: false,
    };
  }

  licenseExpireIn = () => {
    return add(new Date(), { years: 1 });
  };

  componentWillMount = async () => {
    if (this.props.dataExist) {
      let dataAux = this.props.dataAux;
      await this.setState({
        name: dataAux.institution ? dataAux.institution.name : "",
        website: dataAux.institution ? dataAux.institution.website : "",
        phone: dataAux.institution ? dataAux.institution.phone : "",
        license: '',
        licenseExpireIn: this.licenseExpireIn(),
        licenseActive: true,
        zipcode: dataAux.institution ? dataAux.institution.address.zipcode : "",
        street: dataAux.institution ? dataAux.institution.address.street : "",
        number: dataAux.institution ? dataAux.institution.address.number : "",
        complement: dataAux.institution
          ? dataAux.institution.address.complement
          : "",
        estate: dataAux.institution ? dataAux.institution.address.estate : "",
        city: dataAux.institution ? dataAux.institution.address.city : "",
        neighborhood: dataAux.institution
          ? dataAux.institution.address.neighborhood
          : "",
      });

      if (this.state.lang === "pt") {
        this.setState({
          country: dataAux.address ? dataAux.address.country : "Brasil",
        });
      }
    }
  };

  async changeValue(e) {
    let name = e.target.name;
    let value = e.target.value;

    if (this[name]) this[name].classList.remove(ERROR_STYLE);

    if (e.target.name === "country") {
      this.setState(
        {
          country: "",
          zipcode: "",
          zipcodeChecked: false,
          street: "",
          number: "",
          complement: "",
          estate: "",
          city: "",
          neighborhood: "",
        },
        () => {
          this.setState({ [name]: value });
        }
      );
      return;
    }

    this.setState({ [name]: value });
  }


  validCity(e) {
    const cityInstructor = e.target.value;

    if (
      cityInstructor.match(/\d+/g) ||
      cityInstructor.match(/[!@#$%^~&*()_+\-=\[\]{};':"\\|,.<>\/?]/g)
    ) {
      this.showAlert(
        `${this.props.t("i_CreateClass.btnBack")}`,
        "error",
        `${this.props.t("i_registration.instructorCityAlert")}`,
        "#"
      );
      this.setState({ city: "" });
    }
  }

  validState(e) {
    const stateInstructor = e.target.value;

    if (
      stateInstructor.match(/\d+/g) ||
      stateInstructor.match(/[!@#$%^~&*()_+\-=\[\]{};':"\\|,.<>\/?]/g)
    ) {
      this.showAlert(
        `${this.props.t("i_CreateClass.btnBack")}`,
        "error",
        `${this.props.t("i_registration.instructorStateAlert")}`,
        "#"
      );
      this.setState({ estate: "" });
    }
  }

  handleClick() {
    validationForm.bind(this)()
  }

  showAlert(text, icon, title, url) {
    showAlert(text, icon, title, url, this);
  }

  backOperation = () => {
    LocalStorage.persist("backOperation", "true");
    LocalStorage.persist("backNav", "enderecoContato");
  };


  render() {
    return (
      <div>
        <h2 className="cadastro-instrutor-main-title">
          {this.props.t("i_registration.institution.subtitle")}
        </h2>

        <div className="card cadastro-instrutor-progress">
          {this.props.iconPerson}
          <div className="cadastro-instrutor-progresso-list">
            <span className="progresso-list-item">1</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">2</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">3</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item active">4</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">5</span>
          </div>
          {this.props.iconCheck}
        </div>
        <InstitutionNameAndType 
          t={this.props.t} 
          state={this.state}
          setState={(key, value) => this.setState({ [key]: value })}
        />

        <div className="card cadastro-instrutor-card">
          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.props.t("i_registration.institution.country")}
              <span>*</span>
            </label>

            <div className="cadastro-instrutor-select-arrow">
              <select
                className="cadastro-instrutor-select"
                name="country"
                onChange={this.changeValue.bind(this)}
                value={this.state.country}
                ref={(country) => (this.country = country)}
              >
                <option disabled value="">
                  {this.props.t("i_registration.institution.countrySelect")}
                </option>
                {this.state.lang === "pt"
                  ? countries.map((country, index) => (
                      <option key={index} value={country.pais}>
                        {country.pais}
                      </option>
                    ))
                  : countries
                      .sort((a, b) =>
                        a.country < b.country
                          ? -1
                          : a.country > b.country
                          ? 1
                          : 0
                      )
                      .map((country, index) => (
                        <option key={index} value={country.country}>
                          {country.country}
                        </option>
                      ))}
              </select>
            </div>
          </div>
        </div>

        <div className="card cadastro-instrutor-card">
          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.state.country === "Brasil" ||
              this.state.country === "Brazil"
                ? `${this.props.t("i_registration.institution.cep")}`
                : `${this.props.t("i_registration.institution.zipcode")}`}
              <span>*</span>
            </label>
            <input
              className="cadastro-instrutor-input cep"
              placeholder={
                this.state.country === "Brasil" ||
                this.state.country === "Brazil"
                  ? `${this.props.t(
                      "i_registration.institution.cepPlaceholder"
                    )}`
                  : `${this.props.t(
                      "i_registration.institution.zipcodePlaceholder"
                    )}`
              }
              type="text"
              name="zipcode"
              ref={(zipcode) => {
                this.zipcode = zipcode;
              }}
              value={this.state.zipcode}
              onChange={e => onChangeInputCEP.call(this, e)}
            />
          </div>
        </div>

        {this.state.zipcodeChecked ||
        (this.state.country !== "Brasil" && this.state.country !== "Brazil") ? (
          <div className="card cadastro-instrutor-card">
            <div className="form-group">
              <label className="cadastro-instrutor-label">
                {this.props.t("i_registration.institution.address")}
                <span>*</span>
              </label>
              <input
                className="cadastro-instrutor-input"
                placeholder={this.props.t(
                  "i_registration.institution.addressPlaceholder"
                )}
                type="text"
                value={this.state.street}
                name="street"
                ref={(street) => {
                  this.street = street;
                }}
                onChange={this.changeValue.bind(this)}
              />
            </div>

            <div className="form-group">
              <label className="cadastro-instrutor-label">
                {this.props.t("i_registration.institution.number")}
                <span>*</span>
              </label>
              <input
                className="cadastro-instrutor-input numero"
                placeholder={this.props.t(
                  "i_registration.institution.numberPlaceholder"
                )}
                name="number"
                ref={(number) => {
                  this.number = number;
                }}
                type="number"
                value={this.state.number}
                onChange={this.changeValue.bind(this)}
              />
            </div>

            <div className="form-group">
              <label className="cadastro-instrutor-label">
                {this.props.t("i_registration.institution.complement")}
              </label>
              <input
                className="cadastro-instrutor-input"
                placeholder={this.props.t(
                  "i_registration.institution.complementPlaceholder"
                )}
                type="text"
                name="complement"
                value={this.state.complement}
                onChange={this.changeValue.bind(this)}
              />
            </div>

            <div className="form-group">
              <label className="cadastro-instrutor-label">
                {this.props.t("i_registration.institution.state")}
                <span>*</span>
              </label>
              {this.state.country === "Brasil" ||
              this.state.country === "Brazil" ? (
                <input
                  className="cadastro-instrutor-input"
                  placeholder={this.props.t(
                    "i_registration.institution.statePlaceholder"
                  )}
                  type="text"
                  name="estate"
                  ref={(estate) => {
                    this.estate = estate;
                  }}
                  value={this.state.estate}
                  onBlur={this.validState.bind(this)}
                  onChange={this.changeValue.bind(this)}
                  disabled
                />
              ) : (
                <input
                  className="cadastro-instrutor-input"
                  placeholder={this.props.t(
                    "i_registration.institution.statePlaceholder"
                  )}
                  type="text"
                  name="estate"
                  ref={(estate) => {
                    this.estate = estate;
                  }}
                  value={this.state.estate}
                  onBlur={this.validState.bind(this)}
                  onChange={this.changeValue.bind(this)}
                />
              )}
            </div>

            <div className="form-group">
              <label className="cadastro-instrutor-label">
                {this.props.t("i_registration.institution.city")}
                <span>*</span>
              </label>
              <input
                className="cadastro-instrutor-input"
                placeholder={this.props.t(
                  "i_registration.institution.cityPlaceholder"
                )}
                type="text"
                onBlur={this.validCity.bind(this)}
                value={this.state.city}
                onChange={this.changeValue.bind(this)}
                name="city"
                ref={(city) => {
                  this.city = city;
                }}
              />
            </div>

            <div className="form-group">
              <label className="cadastro-instrutor-label">
                {this.props.t("i_registration.institution.neighborhood")}
                <span>*</span>
              </label>
              <input
                className="cadastro-instrutor-input"
                placeholder={this.props.t(
                  "i_registration.institution.neighborhoodPlaceholder"
                )}
                type="text"
                name="neighborhood"
                ref={(neighborhood) => {
                  this.neighborhood = neighborhood;
                }}
                value={this.state.neighborhood}
                onChange={this.changeValue.bind(this)}
              />
            </div>
          </div>
        ) : null}

        <div className="card cadastro-instrutor-card">
          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.props.t("i_registration.institution.website")}
            </label>
            <input
              className="cadastro-instrutor-input"
              placeholder={this.props.t(
                "i_registration.institution.websitePlaceholder"
              )}
              name="website"
              type="text"
              value={this.state.website}
              onChange={this.changeValue.bind(this)}
            />
          </div>

          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.props.t("i_registration.institution.phone")}
            </label>
            <input
              className="cadastro-instrutor-input"
              placeholder={this.props.t(
                "i_registration.institution.phonePlaceholder"
              )}
              name="phone"
              type="text"
              value={this.state.phone}
              onChange={this.changeValue.bind(this)}
            />
          </div>
        </div>

        <div className="cadastro-instrutor-buttons">
          <a
            onClick={() => this.backOperation()}
            className="cadastro-instrutor-back"
            href="/cadastro-instrutor"
          >
            {this.props.t("i_registration.btnBack")}
          </a>
          <button
            className="cadastro-instrutor-next"
            onClick={this.handleClick.bind(this)}
          >
            {this.props.t("i_registration.btnNext")}
          </button>
        </div>
      </div>
    );
  }
}

export default DadosInstituicao;

import React, { Component } from "react";
import "./DadosCadastrais.scss";

import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LocalStorage from "../../../../services/localStorage.service";
import InstructorService from "../../../../services/instructor.service";
import { showAlert } from "../../../../utils/showAlert";
import {ContextAuth} from '../../../../context/authContext';
import { nationalities, validateCPFOrPassport } from "./utils/validations";
const MySwal = withReactContent(Swal);

const ERROR_STYLE = "is-invalid";

class DadosCadastrais extends Component {
  static contextType = ContextAuth;
  constructor() {
    super();
    this.state = {
      name: "",
      born: "",
      nationality: nationalities.br,
      rg: "",
      cpf: "",
    };
  }

  componentWillMount = async () => {
    if (this.props.dataExist) {
      let dataAux = this.props.dataAux;
      await this.setState({
        name: dataAux.name || this.state.name,
        born: dataAux.born || this.state.born,
        nationality: dataAux.nationality || this.state.nationality,
        rg: dataAux.rg || this.state.rg,
        cpf: dataAux.cpf || this.state.cpf,
      });
    }
  };

  changeValue(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (this[name]) {
      this[name].classList.remove(ERROR_STYLE);
    }
    this.setState({ [name]: value });
  }

  validationError(title) {
    MySwal.fire({
      confirmButtonColor: "#87BF40",
      confirmButtonText: (
        <span>{this.props.t("i_registration.registerData.btnTypeAgain")}</span>
      ),
      icon: "error",
      title: <p>{title}</p>,
    });
  }

  validationSuccess = () => {
    this.setState({
      name: this.state.name.trim(),
    });
    MySwal.fire({
      allowOutsideClick: false,
      buttonsStyling: false,
      cancelButtonText: `${this.props.t("i_registration.btnBack")}`,
      cancelButtonColor: "#87BF40",
      confirmButtonColor: "#87BF40",
      confirmButtonText: (
        <a href="javascript:void(0)">
          {this.props.t("i_registration.btnNextPage")}
        </a>
      ),
      customClass: {
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
      },
      icon: "warning",
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      title: `${this.props.t("i_registration.finishRegisterData")}`,
    }).then((result) => {
      if (result.value) this.props.getInfo("enderecoContato", this.state);
    });
  };

  async validationForm() {
    for (const key of Object.keys(this.state)) {
      if (this[key]) {
        if (
          (!this.state[key] || this.state[key] === "") &&
          !this[key].getAttribute("data-exclude")
        ) {
          this[key].classList.add(ERROR_STYLE);
          throw { code: "i_registration.fieldsAlert" };
        }
      }
    }

    if (
      this.state.nationality === nationalities.br &&
      this.state.cpf &&
      this.state.cpf.length
    ) {
      if (!this.cpfValidator(this.state.cpf)) {
        this.cpf.classList.add(ERROR_STYLE);
        throw { code: "i_registration.registerData.cpfAlert" };
      }

      if (await InstructorService.existsWithCpf(this.state.cpf)) {
        throw { code: "i_registration.registerData.cpfAlreadyRegistered" };
      }
    }
  }

  handleClick() {
    this.validationForm()
      .then(this.validationSuccess)
      .catch((err) => this.validationError(this.props.t(err.code)));
  }

  validName(e, input) {
    const nameInstructor = e.target.value;

    if (
      nameInstructor.match(/\d+/g) ||
      nameInstructor.match(/[!@#$%^~&*()_+\-=\[\]{};':"\\|,.<>\/?]/g)
    ) {
      this.setState({ [input]: "" });
      this.showAlert(
        `${this.props.t("i_CreateClass.btnBack")}`,
        "error",
        `${this.props.t("i_registration.instructorNameAlert")}`,
        "#"
      );
    }
  }

  validBorn = async (e) => {
    const born = new Date(`${e.target.value}T00:00`);
    const max = new Date(
      `${moment().subtract(18, "years").format("YYYY-MM-DD")}T00:00`
    );
    if (born > max) {
      this.born.classList.add(ERROR_STYLE);
      MySwal.fire({
        confirmButtonColor: "#87BF40",
        confirmButtonText: (
          <span>
            {this.props.t("i_registration.registerData.btnTypeAgain")}
          </span>
        ),
        icon: "error",
        title: <p>{this.props.t("i_registration.registerData.dateAlert")}</p>,
      });
      await this.setState({ born: "" });
    }
  };

  showAlert(text, icon, title, url) {
    showAlert(text, icon, title, url, this);
  }

  backOperation = () => {
    LocalStorage.persist("backOperation", "true");
    LocalStorage.persist("backNav", "informacoesAcesso");
  };

  render() {
    return (
      <div>
        <h2 className="cadastro-instrutor-main-title">
          {this.props.t("i_registration.registerData.subtitle")}
        </h2>

        <div className="card cadastro-instrutor-progress">
          {this.props.iconPerson}
          <div className="cadastro-instrutor-progresso-list">
            <span className="progresso-list-item">1</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item active">2</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">3</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">4</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">5</span>
          </div>
          {this.props.iconCheck}
        </div>

        <div className="card cadastro-instrutor-card">
          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.props.t("i_registration.registerData.fullname")}
              <span>*</span>
            </label>
            <span className="cadastro-instrutor-alert"></span>
            <input
              className="cadastro-instrutor-input"
              placeholder={this.props.t(
                "i_registration.registerData.namePlaceholder"
              )}
              type="text"
              required
              name="name"
              ref={(name) => {
                this.name = name;
              }}
              value={this.state.name}
              onBlur={(e) => this.validName(e, "name")}
              onChange={this.changeValue.bind(this)}
            />
          </div>
        </div>

        <div className="card cadastro-instrutor-card">
          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.props.t("i_registration.registerData.date")}
              <span>*</span>
            </label>
            <span className="cadastro-instrutor-alert">
              {this.props.t("i_registration.registerData.dateInfo")}
            </span>
            <input
              className="cadastro-instrutor-input"
              name="born"
              ref={(born) => {
                this.born = born;
              }}
              max={moment().subtract(18, "years").format("YYYY-MM-DD")}
              onBlur={this.validBorn.bind(this)}
              onChange={this.changeValue.bind(this)}
              value={this.state.born}
              type="date"
            />
          </div>
        </div>

        <div className="card cadastro-instrutor-card">
          <label className="cadastro-instrutor-label">
            {this.props.t("i_registration.registerData.nationality")}
            <span>*</span>
          </label>

          <div className="cadastro-instrutor-input-radio">
            <input
              id={nationalities.br}
              name="nationality"
              type="radio"
              checked={this.state.nationality == nationalities.br}
              value={nationalities.br}
              onChange={this.changeValue.bind(this)}
            />
            <label htmlFor={nationalities.br}>
              {this.props.t("i_registration.registerData.brazilian")}
            </label>

            <input
              id={nationalities.other}
              name="nationality"
              value={nationalities.other}
              type="radio"
              checked={this.state.nationality == nationalities.other}
              onChange={this.changeValue.bind(this)}
            />
            <label htmlFor={nationalities.other}>
              {this.props.t("i_registration.registerData.other")}
            </label>
          </div>
        </div>

        <div className="card cadastro-instrutor-card">
          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.state.nationality === nationalities.br
                ? `${this.props.t("i_registration.registerData.cpf")}`
                : `${this.props.t("i_registration.registerData.passport")}`}
            </label>
            <input
              className="cadastro-instrutor-input"
              placeholder={
                this.state.nationality === nationalities.br
                  ? `${this.props.t(
                      "i_registration.registerData.cpfPlaceholder"
                    )}`
                  : `${this.props.t(
                      "i_registration.registerData.passportPlaceholder"
                    )}`
              }
              ref={(cpf) => {
                this.cpf = cpf;
              }}
              type="text"
              data-exclude
              name="cpf"
              maxLength={11}
              value={this.state.cpf}
              onBlur={(e) => validateCPFOrPassport.call(this, e)}
              onChange={this.changeValue.bind(this)}
            />
          </div>
          {this.state.nationality === nationalities.br ? (
            <div className="form-group">
              <label className="cadastro-instrutor-label">
                {this.props.t("i_registration.registerData.rg")}
              </label>
              <input
                className="cadastro-instrutor-input"
                placeholder={this.props.t(
                  "i_registration.registerData.rgPlaceholder"
                )}
                type="text"
                data-exclude
                name="rg"
                value={this.state.rg}
                ref={(rg) => {
                  this.rg = rg;
                }}
                onChange={this.changeValue.bind(this)}
              />
            </div>
          ) : null}
        </div>

        <div className="cadastro-instrutor-buttons">
          <a
            onClick={() => this.backOperation()}
            className="cadastro-instrutor-back"
            href="/cadastro-instrutor"
          >
            {this.props.t("i_registration.btnBack")}
          </a>
          <button
            className="cadastro-instrutor-next"
            onClick={this.handleClick.bind(this)}
          >
            {this.props.t("i_registration.btnNext")}
          </button>
        </div>
      </div>
    );
  }

  cpfValidator(cpf) {
    let soma = 0,
      rest;
    if (cpf.indexOf(".") !== -1) {
      cpf = cpf.replace(/\./g, "");
      cpf = cpf.replace(/\-/g, "");
    }

    if (
      cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999"
    )
      return false;

    for (let i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    rest = (soma * 10) % 11;
    if (rest == 10 || rest == 11) rest = 0;
    if (rest != parseInt(cpf.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    rest = (soma * 10) % 11;
    if (rest == 10 || rest == 11) rest = 0;
    if (rest != parseInt(cpf.substring(10, 11))) return false;

    return true;
  }
}

export default DadosCadastrais;

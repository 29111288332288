/**
 * @typedef {Object} Props
 * @property {string} id - The unique identifier for the component
 * @property {string} label - The label text
 * @property {Array} classes - An array of additional classes to add to the checkbox
 * @property {boolean} hasTextInput - If the checkboxs need a text input, this property should be true
 * @property {Function} onClick - Function to trigger when click. Receive the input text value as parameter
 * @property {Function} onChange - Function to trigger when change.  Receives event as parameter
 * @property {boolean} isChecked - Boolean that indicates if the checkbox is checked or not
 * @property {string} textValue - Value for text input
 * @property {Function} onChangeText - Function to trigger when change text input. Receives event as parameter.
 * @property {boolean} isInputDisabled - Boolean that indicates if the text input is disabled or not
 */

/**
 * Checkbox component.
 * @param {Props} props - The component props.
 * @returns {JSX.Element} - The component's rendered elements.
 */
function Checkbox(props) {

    return (
        <div className="form-group-checkbox">
            <input 
                id={props.id} 
                type="checkbox" 
                onClick={props.onClick}
                checked={props.isChecked}
                onChange={props.onChange}
                className={props.classes?.join(' ')}
            />
            <label htmlFor={props.id}>
                {props.label}
            </label>
            {props.hasTextInput && 
                <input 
                    type="text"
                    value={props.textValue}
                    onChange={(e) => props.onChangeText(e)}
                    disabled={props.isInputDisabled}
                />
            }
        </div>
    )
}

export default Checkbox;
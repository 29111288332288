import { add, isAfter } from "date-fns";
import SecurityService from "./security.service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import getTranslation from "../utils/getTranslation";

export class FilterService {
  _component;

  constructor(component) {
    this._component = component;
  }

  async runAllFilters() {
    await this.verifyIfInstructorLicenseIsExpired();
    this.verifyIfTokenIsExpired();
  }

  async verifyIfInstructorLicenseIsExpired() {
    const role = this._component?.context?.state?.role;

    if (role === "instructor") {
      await SecurityService.isInstructorLicenseExpired(
        this._component?.context
      );
    }
  }

  verifyIfTokenIsExpired() {
    const MySwal = withReactContent(Swal);

    const actualDate = new Date();
    const expiredDate = new Date(this._component?.context?.state?.tokenExp);
    // const expiredDateTest = new Date("2023-02-23T14:58:00.048Z");

    add(actualDate, { hours: 3 });
    const isDateAfter = isAfter(actualDate, expiredDate);

    if (isDateAfter) {
      this._component?.context.clearContext();
      //const title = 

      MySwal.fire({
        allowOutsideClick: false,
        confirmButtonColor: "#87BF40",
        confirmButtonText: `OK`,
        title: getTranslation("login.errors.expiredSession")
      }).then(function () {
        if (window.location.pathname !== "/") {
          window.location = "/"
        }
      });
    }
  }

  verifyIfTokenExists() {
    const MySwal = withReactContent(Swal);

    if (!this._component?.context?.state?.token) {
      MySwal.fire({
        allowOutsideClick: false,
        confirmButtonColor: "#87BF40",
        confirmButtonText: `OK`,
        title: getTranslation("login.errors.expiredSession")
      }).then(function () {
        window.location = "/";
      });
      return false;
    } else {
      return true;
    }
  }
}

import { AiFillWarning } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import getTranslation from "../../../../utils/getTranslation";
import "./LicenseWarn.scss";
import { useContext, useEffect, useState } from "react";
import LocalStorage from "../../../../services/localStorage.service";
import { ContextAuth } from "../../../../context/authContext";
import { differenceInMilliseconds } from "date-fns";

const LicenseWarn = () => {
  const authContext = useContext(ContextAuth);

  const [isLicenseWarnClosed, setIsLicenseWarnClosed] = useState();
  const [licenseExpireFormatedDate, setLicenseExpireFormatedDate] = useState();
  const [licenseWarn, setLicenseWarn] = useState(false);

  useEffect(() => {
    const licenseWarnStatus = LocalStorage.retrieve("isLicenseWarnClosed");

    setIsLicenseWarnClosed(licenseWarnStatus);

    const instrutor = authContext.state.userData;

    if (!isLicenseWarnClosed) {
      const instructorLicenseExpireIn = new Date(
        instrutor.institution.licenseExpireIn
      );

      const monthInMilliseconds = 2592000000; // 30 * 24 * 60 * 60 * 1000

      if (
        differenceInMilliseconds(instructorLicenseExpireIn, new Date()) <
          monthInMilliseconds &&
        !licenseWarnStatus
      ) {
        const day = instructorLicenseExpireIn.getDate();
        const month = instructorLicenseExpireIn.getMonth() + 1; // os meses são indexados de 0 a 11
        const year = instructorLicenseExpireIn.getFullYear();

        const formatedDate = `${day}/${month}/${year}`;

        setLicenseExpireFormatedDate(formatedDate);
        setLicenseWarn(true)
      }
    }
  }, []);

  const closeLicenseWarn = async () => {
    setLicenseWarn(false);
    setIsLicenseWarnClosed(true);
    LocalStorage.persist("isLicenseWarnClosed", true);
  };

  return (
    <>
      {licenseWarn && !isLicenseWarnClosed && (
        <div className="home-instrutor-license-warn">
          <span className="home-instrutor-license-warn-content">
            <span className="home-instrutor-license-warn-icon">
              <span className="home-instrutor-license-warn-react-icon">
                <AiFillWarning />
              </span>
              {getTranslation("instructorHome.licenseWarn.warn")}
            </span>
            {getTranslation("instructorHome.licenseWarn.willExpire")}{" "}
            <span className="home-instrutor-license-warn-date">
              {licenseExpireFormatedDate}.
            </span>
            {getTranslation("instructorHome.licenseWarn.contactAnAdm")}
          </span>
          <button
            className="license-warn-close-button"
            onClick={closeLicenseWarn}
          >
            <CgClose />
          </button>
        </div>
      )}
    </>
  );
};

export default LicenseWarn;

import React, { Component, useContext } from 'react';
import { Link } from 'react-router-dom';
import "./EnvioMissoes.scss";
import { useEffect, useState } from "react";

import Header from "../../../Header/Header";
import Loading from "../../../Loading/Loading";

import ListaDeImagens from './ListaDeImagens/ListaDeImagens';

import LocalStorage from "../../../../services/localStorage.service"
import ClassService from "../../../../services/class.service";
import MissionService from "../../../../services/mission.service";
import PowerService from "../../../../services/power.service";
import WorkshopService from "../../../../services/workshop.service";

import ArrayDeImagens from "../EnvioMissoes/Imagem/Imagem"
import SendMissionService from "../../../../services/sendmission.service";

import { useParams } from "react-router";
import { ContextAuth } from '../../../../context/authContext';

export default function EnvioMissoes({ t }) {
    const authContext = useContext(ContextAuth)

    let lang = LocalStorage.retrieve("language");
    lang = lang ? lang : 'en';

    const code = useParams().code;
    const [classCode, setClass] = useState();

    const [participants, setParticipants] = useState([])
    const [workshops, setWorkshops] = useState([])
    const [powers, setPowers] = useState([])
    const [missions, setMissions] = useState([])

    const [modal, setModal] = useState(<></>);

    const [wkIndex, setWkIndex] = useState(0);
    const [dummy, setDummy] = useState(false);

    const [imagens, setImages] = useState(new ArrayDeImagens());
    const [sendmission, setSendMission] = useState([]);

    useEffect(() => {
        ClassService.getByCode(code).then(res => setClass(res))
        ClassService.participantsByClass(code).then(res => setParticipants(res))
        WorkshopService.getAll().then(res => setWorkshops(res))
        PowerService.getAll().then(res => setPowers(res))
        MissionService.getAll(authContext).then(res => setMissions(res))
        SendMissionService.getByCode(code).then(res => setSendMission(res))
    }, [dummy]);

    useEffect(() => {
        let modalElement = document.querySelector(".modal");

        if (modalElement)
            modalElement.style.display = "block";
    }, [modal])

    const actualWorkshop = () => classCode.workshops[wkIndex];

    function loaded() {
        return classCode
            && participants.length > 0
            && workshops.length > 0
            && powers.length > 0
            && missions.length > 0
    }

    function reload() {
        setDummy(!dummy);
    }

    function getPopulatedPresenceList(index) {
        const workshop = classCode.workshops[index];
        return workshop.presenceList.map(p => {
            const participant = participants.find(part => part._id == p._id);
            let power, mission, star;

            let powerAux = participant.powers.find(p => p.workshop === workshop.workshop);
            if (powerAux)
                power = powers.find(p => p._id === powerAux.powerId);

            let missionAux = participant.missions.find(m => m.workshop === workshop.workshop);
            if (missionAux)
                mission = missions.find(m => m._id === missionAux.missionId);

            let starAux = participant.stars.find(s => s.workshop === workshop.workshop);
            if (starAux)
                star = starAux.type;

            return {
                presence: p.presence,
                participant: {
                    _id: participant._id,
                    name: participant.name,
                    power,
                    mission,
                    star
                }
            }
        })
    }

    function renderWorkshopsNavigation() {
        let items = [];
        for (let i = 1; i <= classCode.workshops.length; i++) {
            items.push(
                <li
                    className={"oficina-carousel-menu-item" + (wkIndex === i - 1 ? " active" : "")}
                    onClick={() => setWkIndex(i - 1)}
                    key={i}
                >
                    {i}
                </li>
            );
        }
        return items;
    };

    function removerParticipanteSelecionado(participantId, participants) {
      function filtrarParticipantePeloId(p) {
        if (p.participant._id !== participantId) return p;
      }
      if(participants != null) {
        var participantsFiltered = participants.filter(filtrarParticipantePeloId);
      } else {
        participantsFiltered = this.state.participants.filter(filtrarParticipantePeloId);
        this.setState({participants : participantsFiltered})
      }
  
      return participantsFiltered;
    }

    function reloadImages() {
        SendMissionService.getByCode(code).then(res => setSendMission(res))
    }

    function getImages() {
        function filtrarPorAlunosPresentes(p) {
            if (p.presence === true) return p
          }

        var allParticipants = getPopulatedPresenceList(wkIndex);
        var participantsWithMissionsSafe = allParticipants.filter(filtrarPorAlunosPresentes);
        var participantsWithMissions = participantsWithMissionsSafe;

        try {
            imagens.limparImagens();

            if(sendmission) {
              const workshopId = classCode.workshops[wkIndex].workshop;
              const workshop = sendmission.workshops.find(workshop => workshop.workshopId === workshopId);

              if(!workshop) {
                return {participantsWithMissions: participantsWithMissions,
                        participantsWithMissionsSafe: participantsWithMissionsSafe};
              }

              for(var i = 0; i < workshop.images.length; i++) {
                imagens.adicionarImagemDoAlunoQueJaFoiEnviada(workshop.images[i].missionName,
                    workshop.images[i].missionId, 
                    workshop.images[i].url, 
                    workshop.images[i].participantName,     
                    workshop.images[i].participantId, 
                    workshop.images[i].imageDriveId) 
                participantsWithMissions = removerParticipanteSelecionado(workshop.images[i].participantId, participantsWithMissions)
              }

            return {participantsWithMissions: participantsWithMissions,
                    participantsWithMissionsSafe: participantsWithMissionsSafe};
            } else {
                return {participantsWithMissions: participantsWithMissions,
                        participantsWithMissionsSafe: participantsWithMissionsSafe};
            }
          } catch(error) {
            console.log(error);
          }
    }

    function renderImages() {
        const { participantsWithMissions, participantsWithMissionsSafe } = getImages();
        return (
          <ListaDeImagens
            t={t}
            reloadImages={reloadImages.bind(this)}
            participantsWithMissions={participantsWithMissions}
            participantsWithMissionsSafe={participantsWithMissionsSafe}
            wkIndex={wkIndex}
            classCode={classCode.code}
            instructor={classCode.instructor}
            dateTime={classCode.workshops[wkIndex].dateTime}
            workshopId={classCode.workshops[wkIndex].workshop}
            workshops={workshops}
            workshopsParticipants={getPopulatedPresenceList(wkIndex)}
            adicionarImagem={imagens.adicionarImagemDoAluno.bind(imagens)}
            apagarImagem={imagens.apagarImagemDoAluno.bind(imagens)}
            imagens={imagens}
          ></ListaDeImagens>
        );
    }

    return (
        <Loading fade={!loaded()}>
            <Header />

            <div className="page-title-header">
                <h1 className="page-title">{t("i_ConsultClass.title")}</h1>
                <span className="page-title-border" />
            </div>

            { loaded() ? <>
                <main className="sendMission-main">
                    <h3 className="turma-class-name">
                        {classCode.name}
                        <span>
                            {t("i_Class.code")}: {code}
                        </span>
                    </h3>

                    <ul className="card oficina-carousel-menu">
                        {renderWorkshopsNavigation()} 
                    </ul>

                    <div className="item card listaDeImagens-turma-oficina-content ">
                        <span className="oficina-title">
                            {workshops.find(w => w._id === actualWorkshop().workshop).name}
                        </span>
                    </div>

                    <section>
                        {renderImages(wkIndex)}
                    </section>

                    {modal}
                </main>
            </> : null }
        </Loading>
    );
}

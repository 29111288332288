import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    page: {
      orientation: "landscape",
    },
    logo: {
      paddingHorizontal: 30,
      width: 351,
      marginTop: 60,
      marginHorizontal: "auto",
    },
    title: {
      color: "#008080",
      fontSize: 28,
      textAlign: "center",
      marginTop: 30,
      fontFamily: "tahoma-bold",
    },
    intro: {
      fontSize: 24,
      textAlign: "center",
      marginTop: 20,
      marginBottom: 20,
      fontFamily: "Arial",
    },
    name: {
      color: "black",
      display: "block",
      textAlign: "center",
      marginBottom: 20,
      fontSize: 32,
      letterSpacing: 0.5,
      fontFamily: "arial-bold",
      textTransform: "uppercase",
    },
    text: {
      fontSize: 18,
      textAlign: "center",
      marginRight: 100,
      marginLeft: 100,
      letterSpacing: 1,
      lineHeight: 1.5,
      fontFamily: "arial-italic",
    },
    textBold: {
      fontSize: 18,
      textAlign: "center",
      marginRight: 100,
      marginLeft: 100,
      letterSpacing: 1,
      lineHeight: 1.5,
      fontFamily: "arial-bold-italic",
    },
    date: {
      textAlign: "center",
      fontSize: 18,
      marginBottom: 20,
      marginTop: 20,
      fontFamily: "Arial",
    },
    assinatura: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: 100,
    },
    signature: {
      textAlign: "center",
      fontSize: 14,
      fontWeight: 700,
      marginTop: 10,
      fontFamily: "tahoma-bold",
    },
    button: {
      color: "orange",
    },
  });
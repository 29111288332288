import Compressor from "compressorjs";
import getTranslation from "../../../../../../../utils/getTranslation";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ClassService from "../../../../../../../services/class.service";
import { useHistory } from "react-router";

const SendPresenceListButton = ({ images, classId, workshopId }) => {
  const history = useHistory();

  const compressImage = (image) => {
    return new Promise((resolve, reject) => {
      new Compressor(image.file, {
        quality: 0.6,
        success: (compressedResult) => {
          resolve(compressedResult);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  const handleSendImages = async () => {
    let MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: "warning",
      text: getTranslation("participantMissionUpload.sendButton.sendWarn"),
      allowEscapeKey: true,
      showCloseButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "#87BF40",
      confirmButtonText: getTranslation(
        "participantMissionUpload.sendButton.sendWarnConfirm"
      ),
      cancelButtonText: getTranslation(
        "participantMissionUpload.sendButton.sendWarnCancel"
      ),
    }).then(async (res) => {
      if (res.isConfirmed) {
        MySwal.fire({
          html: getTranslation("i_ListImages.sendLoading"),
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            MySwal.showLoading();
          },
        });

        const formData = new FormData();

        formData.append("classId", classId);
        formData.append("workshopId", workshopId);

        await Promise.all(
          images.map(async (image) => {
            const result = await compressImage(image);
            formData.append("files", result);
          })
        );

        try {
          await ClassService.submitPresenceList.create(
            formData,
            classId
          );

          MySwal.fire({
            title: getTranslation("i_ListImages.sendSucessTitle"),
            html: getTranslation("i_SendPresenceListImages.sendSuccessText"),
            icon: "success",
            showCloseButton: true,
            showConfirmButton: false,
            focusConfirm: false,
          }).finally(() => history.push("/turmas-list"));
        } catch (error) {
          console.log(error)
          MySwal.fire({
            title: getTranslation("i_ListImages.sendErrorTitle"),
            html: getTranslation("i_ListImages.sendErrorText"),
            icon: "error",
            showCloseButton: true,
            showConfirmButton: false,
            focusConfirm: false,
          });
        }
      }
    });
  };

  return (
    <button
      className="listaDeImagens_sendButton"
      disabled={images.length === 0}
      onClick={() => {
        handleSendImages();
      }}
      style={{
        background:
          images.length > 0 ? "#87BF40" : "rgba(195, 195, 195, 0.698)",
      }}
    >
      {getTranslation("i_ListImages.btnSend")}
    </button>
  );
};

export default SendPresenceListButton;

import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ContextAuth } from '../../../../context/authContext';
import ClassService from '../../../../services/class.service';
import LocalStorage from '../../../../services/localStorage.service';
import ParticipantService from '../../../../services/participant.service';
import PowerService from '../../../../services/power.service';
import WorkshopService from '../../../../services/workshop.service';
import Header from '../../../Header/Header';
import Loading from '../../../Loading/Loading';
import PageTitle from '../../../PageTitle/PageTitle';
import ParticipantElement from './components/ParticipantElement/ParticipantElement';

import StarsCard from './components/StarsCard/StarsCard';
import './Estrelas.scss';
import EstrelasModal from './Modal/EstrelasModal';

const MySwal = withReactContent(Swal);

class Estrelas extends Component {
  static contextType = ContextAuth;

  constructor() {
    super();
    this.state = {
      participants: [],
      participantIndex: '',
      participantCurrent: '',
      participantApprovedMissionCheckboxState: [],
      Loading: true
    };
  }

  async componentWillMount() {
    let lang = LocalStorage.retrieve('language');
    lang = lang ? lang : 'en';

    let { code, workshop } = this.props.match.params;
    const { last: lastWorkshop } = await WorkshopService.getById(workshop);
    const classCode = await ClassService.classExist(code);
    let participants = await ClassService.participantsByClass(code);

    let powers = await PowerService.getAll();

    powers = powers.map(power => {
      return {
        _id: power._id,
        ...power[lang]
      };
    });

    let currentWorkshop = classCode.workshops.find(c => c.workshop === workshop);

    const participantsPresence = currentWorkshop.presenceList
      .filter(participant => participant.presence)
      .map(participant => {
        const participantFromDb = participants.find(p => p._id == participant._id);
        const existentStarFromCurrentWorkshop = participantFromDb.stars && participantFromDb.stars.find(
          s => s.workshop == workshop);
        const poderId = participantFromDb.powers.find(p => p.workshop == workshop).powerId;

        Object.assign(participant, {
          star: lastWorkshop === true ? '1' : existentStarFromCurrentWorkshop ? existentStarFromCurrentWorkshop.type : '',
          workshop,
          poderId,
          poder: powers.find(p => p._id == poderId).name,
          powers: participantFromDb.powers,
          stars: participantFromDb.stars,
          mission: participantFromDb.missions.find(m => m.workshop === workshop),
          missionApproved: false
        });

        return participant;
      });

    const participantApprovedMissionCheckboxState = participantsPresence.map(participant => {
      return {
        _id: participant._id,
        data: participant,
        checked: false,
        changeChecked: () => this.changeParticipantApprovedMissionCheckboxState(participant._id)
      };
    });

    this.setState({
      _id: classCode._id,
      code: code,
      name: classCode.name,
      participants: participantsPresence,
      participantApprovedMissionCheckboxState,
      workshop: workshop,
      Loading: false,
    });
  }

  changeParticipantApprovedMissionCheckboxState(id) {
    const participantIndex = this.state.participantApprovedMissionCheckboxState.findIndex(
      p => p._id == id);

    const selectedParticipant = this.state.participants[participantIndex];
    selectedParticipant.missionApproved = !selectedParticipant.missionApproved;

    const newState = [...this.state.participantApprovedMissionCheckboxState];
    newState[participantIndex].checked = !newState[participantIndex].checked;

    this.setState({
      participantApprovedMissionCheckboxState: newState
    });
  }

  storeStar = async (index, star) => {

    let participants = this.state.participants;
    participants[index] = {
      ...participants[index],
      star: star,
      workshop: this.state.workshop
    };

    this.setState({ participants: participants }, async () => await this.saveStars(index));
  };

  saveStars = async (i) => {
    if (this.state.participants[i].star !== '') {
      let res = await ParticipantService.assignStars([this.state.participants[i]]);
    }
  };

  async finishStars() {
    {
      /**
       * Filters the participants array to take only the participants who have had missions
       * approved by the instructor. Then, it maps the filtered array to an array of objects
       * containing their id and their mission id. This array is then sent to the backend to
       * change the missions statuses to 'completed'.
       *
       * @param {Array} participants - Array of participant objects.
       *
       * @returns {Array} - Array containing filtered and mapped submissions.
       */
      const submissions = this.state.participants.filter(
        participant => participant.missionApproved === true).map(
        participant => ( { participant: participant._id, mission: participant.mission._id } ));

      const res = await ClassService.approveSubmitedMission(this.state.code, this.state.workshop,
        submissions);

      if (!res.success) {
        this.showPopup(this.props.t('i_stars.approve_mission_box.errorMessageFromRequest'),
          'error');
      }
    }

    /**
     * Checks if there's a participant with an empty star value. If there's such a participant,
     * the value of its star's reassigned to '0' before finishing the workshop.
     *
     * @param {string} participant - An element of the participants' array.
     *
     * @returns {participant[]} - An array of participants that may have been updated.
     * */
    const updatedStarsParticipants = this.state.participants.map(participant => {
      if (participant.star === "") {
        participant.star = '0';
      }
      return participant;
    });

    {
      const res = await ParticipantService.assignStars(updatedStarsParticipants);
      if (res.success) {
        const steps = await ClassService.completeStep(this.state.code, this.state.workshop,
          'starsCompleted');
        if (steps._id) {
          this.showPopup(this.props.t('i_stars.successAlert'), 'success');
        }
        this.props.history.push(`/oficina-instrutor/${this.state.code}`);
      }
    }
  }

  showPopup(title, icon) {
    MySwal.fire({
      allowOutsideClick: false,
      confirmButtonColor: "#87BF40",
      confirmButtonText: <span>OK</span>,
      icon,
      title: <p>{title}</p>
    });
  }

  openModal = (id) => {
    const participantIndex = this.state.participants.findIndex(p => p._id == id);

    this.setState({
      participantIndex: participantIndex,
      participantCurrent: this.state.participants[participantIndex]
    }, () => {
      let $modal = document.querySelector('.estrelas-instrutor-modal');
      $modal.style.display = 'block';
    });
  };

  render() {
    return (
      <Loading fade={this.state.Loading}>
        <div>
          <Header/>
          <PageTitle title={this.props.t('i_stars.title')}/>

          <main className="estrelas-instrutor-main">
            <h3 className="estrelas-instrutor-class-name">
              {this.state.name}
              <span>{this.props.t('i_stars.code')}: {this.state.code}</span>
            </h3>

            <StarsCard/>

            <div className="card">
              <div className="estrelas-instrutor-list-header">
                <div className="title">{this.props.t('i_stars.listHeader')}</div>
                <div className="title">{this.props.t('i_stars.star')}</div>
              </div>

              <ul>
                {this.state.participantApprovedMissionCheckboxState.map((participant, index) =>
                  <ParticipantElement
                    participant={participant}
                    star={this.state.participants[index].star}
                    openModal={this.openModal}
                    key={participant._id}
                  />
                )}
              </ul>

              <EstrelasModal
                t={this.props.t}
                to={`${this.state.participantCurrent.name} / ${this.state.participantCurrent.poder}`}
                star={this.state.participantCurrent.star}
                onChooseStar={(star) => this.storeStar(this.state.participantIndex, star)}
              />

            </div>
            <button
              className="estrelas-instrutor-button button-full"
              onClick={() => this.finishStars()}
            >
              {this.props.t('i_stars.btnSave')}
            </button>
          </main>
        </div>
      </Loading>
    );
  }
}

export default Estrelas;
import requests from '../agent';

const endpoint = '/sendmission';

const SendMissionService = {
    create: async (obj) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }       
        };
        return await requests.post(endpoint, obj, config);
    },
    getByCode: async(code) => {
        return await requests.get(`${endpoint}/${code}`);
    },
    deleteImageByDriveId: async(code, imageDriveId) => {
        return await requests.put(`${endpoint}/${code}/${imageDriveId}`);
    },

    participant: {
        create: async (data, participantId) => {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            return await requests.post(
              `/participants/${participantId}/submit-mission`,
              data,
              config
            );
        }
    }
};

export default SendMissionService;
import { FilterService } from "./filter.service";

const navigate = function ({ 
  link, 
  verifyToken = true,
  goBack = false,
  reload = false
}) {

  new Promise(async (resolve, reject) => {
    if (!verifyToken) {
      resolve();
      return;
    }


    const filterService = new FilterService(this);
    // Não permite navegação dentro da aplicação caso não exista o token. Essa verificação é importante porque os componentes de Home tentam disparar requisições antes mesmo de o context e o localstorage atualizarem depois do login.
    // Se o usuário vem de uma página de registro, então permite a navegação sem o token
    if (!filterService.verifyIfTokenExists()) reject("token not found");

    await filterService
      .runAllFilters()
      .then(() => resolve())
      .catch((err) => reject(err));
  })
    .then(() => {
      switch (true) {
        case reload:
            window.location.reload();
            break;
        case goBack:
            this.props.history.goBack();
            break;
        default:
            this.props.history.push(link);
            break;
      } 
    })
    .catch((err) => console.log(err));
};

export { navigate };

import React, { Component } from 'react';
import './List.scss'
import OficinaImage from '../../../../assets/images/oficinas/oficina-image.png';

export default class Oficina extends Component {
  constructor(props){
    super(props)
    this.state = {
      oficinas: [
        {
          name: 'MINHA PRÓPRIA CASA',
          type: 'Única',
          link: '/oficina-participante'
        }
      ]
    }
  }

  listOficinas(){
    let listItems = []

    for(var i = 0; i < this.state.oficinas.length; i++){
      listItems.push(
        <a className="list-oficinas-campanha-card" href={ this.state.oficinas[i].link }>
          <img className="list-oficinas-campanha-image oficina" src={OficinaImage} alt={ this.state.oficinas[i].name } />

          <span className="list-oficinas-divider"></span>

          <div className="list-oficinas-content">
            <h4 className="content-title">{ this.state.oficinas[i].name }</h4>
            <span className="content-status">Tipo de oficina: { this.state.oficinas[i].type }</span>
          </div>

          <span className="list-oficinas-arrow"></span>
        </a>
      );
    }
    return listItems
  }

  render() {
    return (
      <div>
        { this.listOficinas() }
      </div>
    );
  }
}
import requests from '../agent';

const endpoint = '/training'

const trainingService = {
    getAll: async () => {
        return await requests.get(`${endpoint}/active`)
    },
    getById : async (id) => {
        return await requests.get(`${endpoint}/${id}`)
    },
    updateConclusionById : async (id) => {
        return await requests.put(`${endpoint}/no-quizz-training/${id}`)
    }
}

export default trainingService
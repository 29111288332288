import React, { Component } from "react";
import InputMask from "react-input-mask";


import countries from "../../../../shared/helpers/countries";
import LocalStorage from "../../../../services/localStorage.service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { showAlert } from "../../../../utils/showAlert";
import { ContextAuth } from "../../../../context/authContext";
import { onChangeInputCEP } from "./utils/validations";
const MySwal = withReactContent(Swal);

const ERROR_STYLE = "is-invalid";

class EnderecoContato extends Component {
  static contextType = ContextAuth;
  constructor() {
    super();
    this.state = {
      country: "",
      zipcode: "",
      zipcodeChecked: false,
      street: "",
      number: "",
      complement: "",
      estate: "",
      city: "",
      neighborhood: "",
      cellphone: "",
      cellphoneValidaton: "",
      phone: "",
      lang: LocalStorage.retrieve("language"),
      isVerifyLocalizationLoading: false,
    };
  }

  componentWillMount = async () => {
    if (this.props.dataExist) {
      let dataAux = this.props.dataAux;
      await this.setState({
        zipcode: dataAux.address ? dataAux.address.zipcode : "",
        street: dataAux.address ? dataAux.address.street : "",
        number: dataAux.address ? dataAux.address.number : "",
        complement: dataAux.address ? dataAux.address.complement : "",
        estate: dataAux.address ? dataAux.address.estate : "",
        city: dataAux.address ? dataAux.address.city : "",
        neighborhood: dataAux.address ? dataAux.address.neighborhood : "",
        cellphone: dataAux.cellphone,
        phone: dataAux.phone,
      });

      if (this.state.lang === "pt") {
        this.setState({
          country: dataAux.address ? dataAux.address.country : "Brasil",
        });
      }
    }
  };

  async changeValue(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (this[name]) {
      this[name].classList.remove(ERROR_STYLE);
    }

    if (e.target.name === "country") {
      this.setState(
        {
          country: "",
          zipcode: "",
          zipcodeChecked: false,
          street: "",
          number: "",
          complement: "",
          estate: "",
          city: "",
          neighborhood: "",
        },
        () => {
          this.setState({ [name]: value });
        }
      );
      return;
    }

    if (name === "cellphone") this.setState({ cellphoneValidaton: "" });

    this.setState({ [name]: value });
  }

  validCell = async (e) => {
    const getPhone = e.target.value;
    if (getPhone === "") this.setState({ cellphoneValidaton: "is-invalid" });

    let phone = getPhone.replace(/\s/g, "");
    if (this.state.country !== "") {
      if (this.state.country == "Brasil" || this.state.country === "Brazil") {
        if (!phone.match(/\(\d{2,}\)\d{4,}\-\d{4}/g)) {
          this.showAlert(
            `${this.props.t("i_CreateClass.btnBack")}`,
            "error",
            `${this.props.t("i_registration.instructorCellPhoneAlert")}`,
            "#"
          );
          await this.setState({ cellphone: "" });
          this.setState({ cellphoneValidaton: "is-invalid" });
        } else {
          let result = phone.replace(/[()-]/g, "");
          await this.setState({ cellphone: result });
        }
      } else {
        if (
          phone.match(/([A-Z])?([a-z])/g) ||
          phone.match(/[!@#$%^~&*()_\=\[\]{};':"\\|,.<>\/?]/g)
        ) {
          this.showAlert(
            `${this.props.t("i_CreateClass.btnBack")}`,
            "error",
            `${this.props.t("i_registration.instructorCellPhoneAlert")}`,
            "#"
          );
          await this.setState({ cellphone: "" });
          this.setState({ cellphoneValidaton: "is-invalid" });
        } else {
          let result = phone.replace(/[()-]/g, "");
          await this.setState({ cellphone: result });
        }
      }
    }
  };

  validCity(e) {
    const cityInstructor = e.target.value;

    if (
      cityInstructor.match(/\d+/g) ||
      cityInstructor.match(/[!@#$%^~&*()_+\-=\[\]{};':"\\|,.<>\/?]/g)
    ) {
      this.showAlert(
        `${this.props.t("i_CreateClass.btnBack")}`,
        "error",
        `${this.props.t("i_registration.instructorCityAlert")}`,
        "#"
      );
      this.setState({ city: "" });
    }
  }

  validState(e) {
    const stateInstructor = e.target.value;

    if (
      stateInstructor.match(/\d+/g) ||
      stateInstructor.match(/[!@#$%^~&*()_+\-=\[\]{};':"\\|,.<>\/?]/g)
    ) {
      this.showAlert(
        `${this.props.t("i_CreateClass.btnBack")}`,
        "error",
        `${this.props.t("i_registration.instructorStateAlert")}`,
        "#"
      );
      this.setState({ estate: "" });
    }
  }

  async validationForm(State) {
    let dadas = {};
    let stateKeysName = Object.keys(State);
    let errorsFound = [];

    if(!this.state.zipcodeChecked) {
      this.showAlert(
        `${this.props.t("i_CreateClass.btnBack")}`,
        "error",
        `${this.props.t("i_registration.instructorCepAlert")}`,
        "#"
      );

      return
    }

    if (stateKeysName.length) {
      stateKeysName.forEach((item, index) => {
        if (this[item]) {
          if (
            (this.state[item] === "" || this.state[item] === undefined) &&
            !this[item].getAttribute("data-exclude")
          ) {
            this[item].classList.add(ERROR_STYLE);
            errorsFound.push("stringEmpty");
          }
        }
      });
    }

    switch (errorsFound[0]) {
      case "stringEmpty":
        MySwal.fire({
          confirmButtonColor: "#87BF40",
          confirmButtonText: <span>OK</span>,
          icon: "error",
          title: <p>{this.props.t("i_registration.fieldsAlert")}</p>,
        });
        break;
      default:
        MySwal.fire({
          allowOutsideClick: false,
          buttonsStyling: false,
          cancelButtonText: `${this.props.t("i_registration.btnBack")}`,
          cancelButtonColor: "#87BF40",
          confirmButtonColor: "#87BF40",
          confirmButtonText: (
            <a href="javascript:void(0)">
              {this.props.t("i_registration.btnNextPage")}
            </a>
          ),
          customClass: {
            confirmButton: "confirm-button-class",
            cancelButton: "cancel-button-class",
          },
          icon: "warning",
          reverseButtons: true,
          showCloseButton: true,
          showCancelButton: true,
          title: `${this.props.t("i_registration.finishAddress")}`,
        }).then((result) => {
          if (result.value) {
            dadas = {
              phone: this.state.phone,
              cellphone: this.state.cellphone,
              address: {
                ...this.state,
                state: this.state.estate,
              },
            };
            this.props.getInfo("dadosInstituicao", dadas);
          }
        });
        break;
    }

  }

  handleClick() {
    this.validationForm(this.state);
  }

  showAlert(text, icon, title, url) {
    showAlert(text, icon, title, url, this);
  }

  backOperation = () => {
    LocalStorage.persist("backOperation", "true");
    LocalStorage.persist("backNav", "dadosCadastrais");
  };

  render() {
    return (
      <div>
        <h2 className="cadastro-instrutor-main-title">
          {this.props.t("i_registration.address.subtitle")}
        </h2>

        <div className="card cadastro-instrutor-progress">
          {this.props.iconPerson}
          <div className="cadastro-instrutor-progresso-list">
            <span className="progresso-list-item">1</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">2</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item active">3</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">4</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">5</span>
          </div>
          {this.props.iconCheck}
        </div>

        <div className="card cadastro-instrutor-card">
          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.props.t("i_registration.address.country")}
              <span>*</span>
            </label>

            <div className="cadastro-instrutor-select-arrow">
              <select
                className="cadastro-instrutor-select"
                name="country"
                onChange={this.changeValue.bind(this)}
                ref={(country) => {
                  this.country = country;
                }}
                value={this.state.country}
              >
                <option disabled value="">
                  {this.props.t("i_registration.address.countrySelect")}
                </option>
                {this.state.lang === "pt"
                  ? countries.map((country, index) => (
                      <option key={index} value={country.pais}>
                        {country.pais}
                      </option>
                    ))
                  : countries
                      .sort((a, b) =>
                        a.country < b.country
                          ? -1
                          : a.country > b.country
                          ? 1
                          : 0
                      )
                      .map((country, index) => (
                        <option key={index} value={country.country}>
                          {country.country}
                        </option>
                      ))}
              </select>
            </div>
          </div>
        </div>

        <div className="card cadastro-instrutor-card">
          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.state.country === "Brasil" ||
              this.state.country === "Brazil"
                ? `${this.props.t("i_registration.address.cep")}`
                : `${this.props.t("i_registration.address.zipcode")}`}
              <span>*</span>
            </label>
            <input
              className="cadastro-instrutor-input cep"
              placeholder={
                this.state.country === "Brasil" ||
                this.state.country === "Brazil"
                  ? `${this.props.t("i_registration.address.cepPlaceholder")}`
                  : `${this.props.t(
                      "i_registration.address.zipcodePlaceholder"
                    )}`
              }
              type="text"
              name="zipcode"
              ref={(zipcode) => {
                this.zipcode = zipcode;
              }}
              value={this.state.zipcode}
              onChange={e => onChangeInputCEP.call(this, e)}
            />
          </div>
        </div>

        {this.state.zipcodeChecked ||
        (this.state.country !== "Brasil" && this.state.country !== "Brazil") ? (
          <div className="card cadastro-instrutor-card">
            <div className="form-group">
              <label className="cadastro-instrutor-label">
                {this.props.t("i_registration.address.address")}
                <span>*</span>
              </label>
              <input
                className="cadastro-instrutor-input endereco"
                placeholder={this.props.t(
                  "i_registration.address.addressPlaceholder"
                )}
                name="street"
                ref={(street) => {
                  this.street = street;
                }}
                type="text"
                value={this.state.street}
                onChange={this.changeValue.bind(this)}
              />
            </div>

            <div className="form-group">
              <label className="cadastro-instrutor-label">
                {this.props.t("i_registration.address.number")}
                <span>*</span>
              </label>
              <input
                className="cadastro-instrutor-input numero"
                placeholder={this.props.t(
                  "i_registration.address.numberPlaceholder"
                )}
                type="number"
                name="number"
                value={this.state.number}
                ref={(number) => {
                  this.number = number;
                }}
                onChange={this.changeValue.bind(this)}
              />
            </div>

            <div className="form-group">
              <label className="cadastro-instrutor-label">
                {this.props.t("i_registration.address.complement")}
              </label>
              <input
                className="cadastro-instrutor-input"
                placeholder={this.props.t(
                  "i_registration.address.complementPlaceholder"
                )}
                type="text"
                value={this.state.complement}
                ref={(complement) => {
                  this.complement = complement;
                }}
                data-exclude
                name="complement"
                onChange={this.changeValue.bind(this)}
              />
            </div>

            <div className="form-group">
              <label className="cadastro-instrutor-label">
                {this.props.t("i_registration.address.state")}
                <span>*</span>
              </label>
              {this.state.country === "Brasil" ||
              this.state.country === "Brazil" ? (
                <input
                  className="cadastro-instrutor-input"
                  placeholder={this.props.t(
                    "i_registration.address.statePlaceholder"
                  )}
                  type="text"
                  name="estate"
                  ref={(estate) => {
                    this.estate = estate;
                  }}
                  value={this.state.estate}
                  onBlur={this.validState.bind(this)}
                  onChange={this.changeValue.bind(this)}
                />
              ) : (
                <input
                  className="cadastro-instrutor-input"
                  placeholder={this.props.t(
                    "i_registration.address.statePlaceholder"
                  )}
                  type="text"
                  name="estate"
                  ref={(estate) => {
                    this.estate = estate;
                  }}
                  value={this.state.estate}
                  onBlur={this.validState.bind(this)}
                  onChange={this.changeValue.bind(this)}
                />
              )}
            </div>

            <div className="form-group">
              <label className="cadastro-instrutor-label">
                {this.props.t("i_registration.address.city")}
                <span>*</span>
              </label>
              <input
                className="cadastro-instrutor-input"
                placeholder={this.props.t(
                  "i_registration.address.cityPlaceholder"
                )}
                type="text"
                ref={(city) => {
                  this.city = city;
                }}
                onBlur={this.validCity.bind(this)}
                value={this.state.city}
                name="city"
                onChange={this.changeValue.bind(this)}
              />
            </div>

            <div className="form-group">
              <label className="cadastro-instrutor-label">
                {this.props.t("i_registration.address.neighborhood")}
                <span>*</span>
              </label>
              <input
                className="cadastro-instrutor-input"
                placeholder={this.props.t(
                  "i_registration.address.neighborhoodPlaceholder"
                )}
                type="text"
                name="neighborhood"
                ref={(neighborhood) => {
                  this.neighborhood = neighborhood;
                }}
                value={this.state.neighborhood}
                onChange={this.changeValue.bind(this)}
              />
            </div>
          </div>
        ) : null}

        <div className="card cadastro-instrutor-card">
          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.props.t("i_registration.address.mobile")}
              <span>*</span>
            </label>
            {this.state.country === "Brasil" ||
            this.state.country === "Brazil" ||
            this.state.country === "" ? (
              <InputMask
                mask="(99)99999-9999"
                placeholder={this.props.t(
                  "i_registration.address.mobilePlaceholder"
                )}
                maskChar={null}
                className={
                  this.state.cellphoneValidaton + " cadastro-instrutor-input"
                }
                onBlur={this.validCell.bind(this)}
                name="cellphone"
                inputRef={(cellphone) => {
                  this.cellphone = cellphone;
                }}
                onChange={this.changeValue.bind(this)}
                value={this.state.cellphone}
              />
            ) : (
              <input
                className="cadastro-instrutor-input"
                placeholder={this.props.t(
                  "i_registration.institution.phonePlaceholder"
                )}
                name="cellphone"
                type="text"
                ref={(cellphone) => {
                  this.cellphone = cellphone;
                }}
                onBlur={this.validCell.bind(this)}
                onChange={this.changeValue.bind(this)}
                value={this.state.cellphone}
              />
            )}
          </div>

          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.props.t("i_registration.address.phone")}
            </label>
            <input
              className="cadastro-instrutor-input"
              name="phone"
              placeholder={this.props.t(
                "i_registration.address.phonePlaceholder"
              )}
              type="text"
              value={this.state.phone}
              onChange={this.changeValue.bind(this)}
            />
          </div>
        </div>

        <div className="cadastro-instrutor-buttons">
          <a
            onClick={() => this.backOperation()}
            className="cadastro-instrutor-back"
            href="/cadastro-instrutor"
          >
            {this.props.t("i_registration.btnBack")}
          </a>
          <button
            className="cadastro-instrutor-next"
            onClick={this.handleClick.bind(this)}
            disabled={this.state.isVerifyLocalizationLoading}
          >
            {this.props.t("i_registration.btnNext")}
          </button>
        </div>
      </div>
    );
  }
}

export default EnderecoContato;

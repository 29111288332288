import React, { Component } from "react";

import "./InformacoesAdicionais.scss";
import LocalStorage from "../../../../services/localStorage.service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

class InformacoesAdicionais extends Component {
  constructor() {
    super();
    this.state = {
      isTeacher: "",
      courses: "",
      receiveMessagesIfa: "",
      contactWithIfa: "",
      declaration: false
    };
  }

  componentWillMount = async() => {
    if(this.props.dataExist){
      let dataAux = this.props.dataAux;
      await this.setState({
        isTeacher: dataAux.isTeacher || '',
        courses: dataAux.courses || '',
        receiveMessagesIfa: dataAux.receiveMessagesIfa || '',
        contactWithIfa: dataAux.contactWithIfa || '',
        declaration: dataAux.declaration || false
      })
    }
    console.log(this.state.receiveMessagesIfa)
  }

  changeTeacher(e) {
    this.setState({ isTeacher: e.target.value });
  }
  changeCourses(e) {
    this.setState({ courses: e.target.value });
  }
  changeNewsletter(e) {
    this.setState({ receiveMessagesIfa: e});
  }
  changeDeclaration(e) {
    this.setState({ declaration: !this.state.declaration });
  }

  handleClick() {
    if (!this.state.declaration) {
      MySwal.fire({
        confirmButtonColor: "#87BF40",
        confirmButtonText: (
          <span>{this.props.t("i_registration.btnBack")}</span>
        ),
        icon: "error",
        title: <p>{this.props.t("i_registration.declarationAlert")}</p>
      });
    } else if (this.state.isTeacher == "" || this.state.receiveMessagesIfa == "") {
      MySwal.fire({
        confirmButtonColor: "#87BF40",
        confirmButtonText: (
          <span>{this.props.t("i_registration.btnBack")}</span>
        ),
        icon: "error",
        title: <p>{this.props.t("i_registration.fieldsAlert")}</p>
      });
    } else {
      MySwal.fire({
        allowOutsideClick: false,
        buttonsStyling: false,
        cancelButtonText: `${this.props.t("i_registration.btnBack")}`,
        cancelButtonColor: "#87BF40",
        confirmButtonColor: "#87BF40",
        confirmButtonText: (
          <a href="javascript:void(0)">
            {this.props.t("i_registration.btnNextPage")}
          </a>
        ),
        customClass: {
          confirmButton: "confirm-button-class",
          cancelButton: "cancel-button-class"
        },
        icon: "warning",
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        title: `${this.props.t("i_registration.finishAditional")}`
      }).then(result => {
        if (result.value) this.props.getInfo("confirmacaoDados", this.state);
      });
    }
  }

  backOperation = () => {
    LocalStorage.persist('backOperation', 'true');
    LocalStorage.persist('backNav', 'dadosInstituicao');
  }

  render() {
    return (
      <div>
        <h2 className="cadastro-instrutor-main-title">
          {this.props.t("i_registration.additional.subtitle")}
        </h2>

        <div className="card cadastro-instrutor-progress">
          {this.props.iconPerson}
          <div className="cadastro-instrutor-progresso-list">
            <span className="progresso-list-item">1</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">2</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">3</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item">4</span>
            <span className="progresso-list-item-divider"></span>
            <span className="progresso-list-item active">5</span>
          </div>
          {this.props.iconCheck}
        </div>

        <div className="card cadastro-instrutor-card">
          <label className="cadastro-instrutor-label">
            {this.props.t("i_registration.additional.teacher")}
            <span>*</span>
          </label>
          <span className="cadastro-instrutor-alert">
            {this.props.t("i_registration.additional.teacherInfo")}
          </span>

          <div className="cadastro-instrutor-input-radio">
            <input
              id="sim-professor"
              name="professor-educador"
              type="radio"
              value="true"
              checked={this.state.isTeacher == 'true'}
              onChange={this.changeTeacher.bind(this)}
            />
            <label htmlFor="sim-professor">
              {this.props.t("i_registration.additional.yes")}
            </label>

            <input
              id="nao-educador"
              name="professor-educador"
              type="radio"
              value="false"
              checked={this.state.isTeacher == 'false'}
              onChange={this.changeTeacher.bind(this)}
            />
            <label htmlFor="nao-educador">
              {this.props.t("i_registration.additional.no")}
            </label>
          </div>
        </div>

        <div className="card cadastro-instrutor-card">
          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.props.t("i_registration.additional.courses")}
            </label>
            <span className="cadastro-instrutor-alert">
              {this.props.t("i_registration.additional.coursesInfo")}
            </span>
            <input
              className="cadastro-instrutor-input"
              placeholder={this.props.t(
                "i_registration.additional.coursesPlaceholder"
              )}
              type="text"
              value={this.state.courses}
              onChange={this.changeCourses.bind(this)}
            />
          </div>
        </div>

        <div className="card cadastro-instrutor-card">
          <div className="form-group">
            <label className="cadastro-instrutor-label">
              {this.props.t("i_registration.additional.declaration")}
              <span>*</span>
            </label>

            <p className="cadastro-instrutor-adicionais-text">
              {this.props.t("i_registration.additional.declarationText")}
            </p>

            <div className="cadastro-instrutor-input-radio declaracao-responsabilidade">
              <input
                className="informacoes-adicionais-radio"
                id="aceito"
                name="declaracao"
                type="checkbox"
                checked={this.state.declaration}
                onChange={this.changeDeclaration.bind(this)}
              />
              <label htmlFor="aceito">
                {this.props.t("i_registration.additional.btnDeclaration")}
              </label>
            </div>
          </div>
        </div>

        <div className="card cadastro-instrutor-card">
          <div className="form-group">
            <p className="cadastro-instrutor-label">
              {this.props.t("i_registration.additional.knowing")}
            </p>
            <div className="form-group-checkbox">
              <input id="emkt" type="checkbox" />
              <label htmlFor="emkt">
                {this.props.t("i_registration.additional.emailMkt")}
              </label>
            </div>

            <div className="form-group-checkbox">
              <input id="pesquisa" type="checkbox" />
              <label htmlFor="pesquisa">
                {this.props.t("i_registration.additional.search")}
              </label>
            </div>

            <div className="form-group-checkbox">
              <input id="material" type="checkbox" />
              <label htmlFor="material">
                {this.props.t("i_registration.additional.material")}
              </label>
            </div>

            <div className="form-group-checkbox">
              <input id="indicacao" type="checkbox" />
              <label htmlFor="indicacao">
                {this.props.t("i_registration.additional.recommendation")}:
              </label>
              <input type="text" />
            </div>

            <div className="form-group-checkbox">
              <input id="outros" type="checkbox" />
              <label htmlFor="outros">
                {this.props.t("i_registration.additional.others")}:
              </label>
              <input type="text" />
            </div>
          </div>
        </div>

        <div className="card cadastro-instrutor-card">
          <label className="cadastro-instrutor-label">
            {this.props.t("i_registration.additional.information")}
            <span>*</span>
          </label>

          <div className="cadastro-instrutor-input-radio">
            <input
              id="sim-info"
              name="ifa-info"
              type="radio"
              value={this.state.receiveMessagesIfa}
              checked={this.state.receiveMessagesIfa === 'true'}
              onChange={this.changeNewsletter.bind(this, 'true')}
            />
            <label htmlFor="sim-info">
              {this.props.t("i_registration.additional.yes")}
            </label>

            <input
              id="nao-info"
              name="ifa-info"
              type="radio"
              value={this.state.receiveMessagesIfa}
              checked={this.state.receiveMessagesIfa === 'false'}
              onChange={this.changeNewsletter.bind(this, 'false')}
            />
            <label htmlFor="nao-info">
              {this.props.t("i_registration.additional.no")}
            </label>
          </div>
        </div>

        <div className="cadastro-instrutor-buttons">
         <a 
            onClick={() => this.backOperation()}
            className="cadastro-instrutor-back"
            href="/cadastro-instrutor"
          >
            {this.props.t("i_registration.btnBack")}
          </a>
          <button
            className="cadastro-instrutor-next"
            onClick={this.handleClick.bind(this)}
            //disabled={!this.state.declaration}
          >
            {this.props.t("i_registration.btnNext")}
          </button>
        </div>
      </div>
    );
  }
}

export default InformacoesAdicionais;
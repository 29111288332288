import './MenuItem.scss'

const MenuItem = ({ title, subtitle, onClick, finished = undefined }) => {
    return (
        <div
            className={`list-missao-trilha ${finished}`}
            onClick={onClick}
        >
            <div className="list-missao-trilha-content">
                <h4 className="list-missao-trilha-content-title">{title}</h4>
                <span className="list-missao-trilha-content-subtitle">{subtitle}</span>
            </div>
            <span className="list-missao-trilha-arrow"></span>
        </div>
    )
}

export default MenuItem
import React, { Component } from "react";

import LocalStorage from "../../../services/localStorage.service";
import ParticipantService from "../../../services/participant.service";
import PowerService from "../../../services/power.service";
import DescriptionModal from "../../Instrutor/Turma/Oficina/DescriptionModal/DescriptionModal";

import "./Poderes.scss";
import Header from "../../Header/Header";
import poder01 from "../../../assets/images/poderes/poder-01.jpg";

import Loading from "../../Loading/Loading";
import { ContextAuth } from "../../../context/authContext";

export default class Poderes extends Component {
  static contextType = ContextAuth;
  _count = 1;

  constructor(props) {
    super(props);
    this.state = {
      allPowers: [],
      participantPowers: [],
      conteudo: { oficinaName: "", description: "" },
      lang: "",
      LoadingPowers: true
    };
    this._count = 1;
  }

  componentDidMount = async () => {
    const lang = LocalStorage.retrieve("language");
    const logged = this.context.state.userData;
    let participant = await ParticipantService.getById(logged._id);
    let powers = await PowerService.getAll();
    powers = powers.map(power => {
      return {
        _id: power._id,
        ...power[lang]
      };
    });

    let participantPowers = [];
    participant.powers.forEach(power => {
      participantPowers.push(power.powerId);
    });
    await this.setState({
      allPowers: powers,
      participantPowers: participantPowers,
      lang: lang
    });
  };

  openDescriptionModal = async power => {
    const conteudo = {
      oficinaName: power.name,
      description: power.description
    };
    await this.setState({
      conteudo: conteudo
    });

    let modal = document.querySelector(".oficina-instrutor-modal");
    modal.style.display = "block";
  };

  render() {
    return (
      <Loading fade={this.state.LoadingPowers}>
        <div>
          <Header />

          <div className="page-title-header">
            <h1 className="page-title">{this.props.t("p_powers.title")}</h1>
            <span className="page-title-border"></span>
          </div>

          <main className="poderes-main">
            {this.state.allPowers.map(power => {
              return (
                <>
                  <img
                  key = {power._id}
                  onLoad={() => {
                      if (this._count >= this.state.allPowers.length) {
                        this.setState({ LoadingPowers: false });
                      }
                      this._count++;

                    }}
                    src={`https://ifa-app.s3.us-east-2.amazonaws.com/${power._id}/capa_${this.state.lang}.png`}
                    className={`poderes-image ${
                      this.state.participantPowers.includes(power._id)
                        ? ""
                        : "disabled"
                    }`}
                    alt={"Poder " + power.name}
                    // onClick={this.state.participantPowers.includes(power._id) ? () => this.openDescriptionModal() : ''}
                    onClick={() => this.openDescriptionModal(power)}
                  />

                  <DescriptionModal
                    conteudo={this.state.conteudo}
                    t={this.props.t}
                  />
                </>
              );
            })}
          </main>
        </div>
      </Loading>
    );
  }
}

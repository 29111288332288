import missionStatus from "../../../../../shared/constants/missionStatusTypes";
import getTranslation from "../../../../../utils/getTranslation";
import "./MissionStatusFlag.scss";

const MissionStatusFlag = ({ currentUserMissionStatus }) => {
  let flagText;
  let backgroundColor;

  // eslint-disable-next-line default-case
  switch (currentUserMissionStatus) {
    case missionStatus.inProgress:
      backgroundColor = "#F69522";
      flagText = getTranslation("participantMissionUpload.statusFlag.pendingApproval");
      break;
    case missionStatus.incomplete:
      backgroundColor = "#ed5a5a";
      flagText = getTranslation("participantMissionUpload.statusFlag.pendingDelivery");
      break;
    case missionStatus.completed:
      backgroundColor = "#87BF40";
      flagText = getTranslation("participantMissionUpload.statusFlag.approved");
      break;
  }

  return (
    <div className="missionInstructorStatusFlag_div">
      <p
        className="flag-text"
        style={{
          backgroundColor,
        }}
      >
        {flagText}
      </p>
    </div>
  );
};

export default MissionStatusFlag;
